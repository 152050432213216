import { ExtensionService } from './extension.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.css']
})
export class ExtensionComponent implements OnInit {

  public menus:any
  isAdmin:string;

  constructor() { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    if(this.isAdmin == 'true'){
      this.menus = [
        {"link":"/extension/extensionlist", "name":"Extension","perm":"cadmin_h_permission_extension"},
        
      ]
    }else {
      this.menus = [
        {"link":"/extension/addextension", "name":"Add Extension","perm":"cadmin_h_permission_extension"},
        
      ]
    }
  }
}
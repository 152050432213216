import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.css']
})
export class DialerComponent implements OnInit {
  
  public menus:any
  isAdmin:string;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    
      this.menus = [
        {"link":"/dialer/dialerList", "name":"Create Dialer Project","perm":"cadmin_u_dialerList"},
         {"link":"/dialer/dialerexcel", "name":"Upload Customers","perm":"cadmin_v_dialerexcel"},
         {"link":"/dialer/allUploadExcel", "name":"All Upload Excel","perm":"cadmin_v_dialerexcel"},
      ]
    
  }

}

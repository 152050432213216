import { Component, OnInit } from "@angular/core";
import { LoginService } from "src/app/login.service";
import { UsermanagementService } from "../usermanagement.service";

@Component({
  selector: "app-renewal-history",
  templateUrl: "./renewal-history.component.html",
  styleUrls: ["./renewal-history.component.css"],
})
export class RenewalHistoryComponent implements OnInit {
  public renewalHistoryData: any;

  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService
  ) {}

  ngOnInit() {
    this.usermanagementservice.getRenewalHistory().subscribe((data: any) => {
      this.renewalHistoryData = data;
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from "./../../global";

@Injectable({
    providedIn: "root",
})

export class CallCountReportService{

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ) { }
    
    API_URL = this.sharedService.getGlobalVar()
    header = new HttpHeaders().set("Content-Type", "application/json")

    getCallCountReport(data: any) {
        return this.http.post(this.API_URL + "callCountReport/", data, {headers: this.header})
    }

    getCallCountDetail(data: any, pageNumber: number) {
        return this.http.post(this.API_URL + `callCountReport/?page=${pageNumber}`, data, { headers: this.header })
    }
}
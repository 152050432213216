import { Component, OnInit, EventEmitter } from '@angular/core';
import { NotificationService } from './notification.service';

@Component({
  selector: '[app-notification]',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  message:string = '';
  title:string = '';
  ClassName:string = 'is-info';

  constructor(
    private notifyservice: NotificationService,
  ) { }

  ngOnInit() {
    this.notifyservice.notificationEmmiter.subscribe(
      (response:any) => {
        this.message = response.message;
        this.title = response.title;
        this.ClassName = response.type;
      }
    )
  }

  closeNotif() {
    this.message = ''
  }
  

}

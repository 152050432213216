import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LoginService } from 'src/app/login.service';
import { sendatatoexternalapilog } from './sendatatoexternalapilog-report.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sendatatoexternalapilog-report',
  templateUrl: './sendatatoexternalapilog-report.component.html',
  styleUrls: ['./sendatatoexternalapilog-report.component.css'],
  providers: [DatePipe]

})
export class SendatatoexternalapilogReportComponent implements OnInit {
  myDate = new Date();
  fromdate: string = '';
  todate: string = '';

  today = new Date(this.myDate.setDate(this.myDate.getDate()));

  today_informat = this.datePipe.transform(this.today, 'yyyy-MM-dd');
  userData: any;
  searchText: string = '';
  user: any;
  report: any = new Array();
  total_page: any;
  current_page: number = 1;

  last: number;

  isModalActive = false;
  modalTitle: string;
  modalContent: string;

  constructor(
    private loginservice: LoginService,
    private datePipe: DatePipe,
    private sendatatoexternalapilog: sendatatoexternalapilog

  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.FetchReportData(1);

  }

  openModal(type: string, content: string): void {
    this.isModalActive = true;
    this.modalTitle = this.getModalTitle(type);
    this.modalContent = typeof content === 'object' ? JSON.stringify(content, null, 2) : content;

  }

  getModalTitle(type: string): string {
    switch (type) {
      case 'callType':
        return 'Calltype Details';
      case 'data':
        return 'Data Details';
      case 'response':
        return 'Response Details';
      case 'callId':
        return 'CallId Details';
      default:
        return 'Details';
      
    }
  }


  closeModal(): void {
    this.isModalActive = false;
  }

  FetchReportData(pageNo) {
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd')
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd');
    var body = {
      "userid": this.userData.id, "from": from, "to": to, "search": this.searchText,"user":this.user
    }
    this.sendatatoexternalapilog.getreport(body, pageNo).subscribe(
      (res: any) => {
        this.report = res["results"]
        this.total_page = res["total_pages"]
        this.current_page = res["current_page"]
        this.last = res["total_pages"]
      }
    )
  }

  customerEvent(selectedUser: string) {
    this.user = selectedUser;
  }

  fetchNumber(i) {
    if (i == 'prev') {
      if (this.current_page > 1) {
        this.FetchReportData(this.current_page - 1);
      }

    }

    else if (i == 'next') {
      if (this.current_page < this.last) {
        this.FetchReportData(this.current_page + 1);

      }

    }

    else if (i == 'first') {
      this.FetchReportData(1);

    }

    else if (i == 'last') {
      this.FetchReportData(this.last);

    }

    else {

      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.FetchReportData(i);

      }
      else {
        Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
      }

    }


  }

  searchBox() {
    this.FetchReportData(1)
  }


}

import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../login.service";
import Swal from "sweetalert2";
import { SweetAlertResult } from 'sweetalert2';
import { AddPushNotificationService } from "./add-pushnotification.service";


import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";

@Component({
  selector: "app-add-pushnotification",
  templateUrl: "./add-pushnotification.component.html",
  styleUrls: ["./add-pushnotification.component.css"],
})
export class AddPushnotificationComponent implements OnInit {
  notificationForm: FormGroup;
  notifications: any[] = [];

  Notification: any;
  notificationList: any;
  keyword :string='use_cases';
  notificaionUser: any;
  users: any[] = [];
  notificationUserid: number[] = [];
  public userData:any;

  userProfile:any;
  NotificationIni: any;
  notificationobj:any;
  notiobject:any;
  selectedNotificationId: number | null = null;
  display:any;
  selectedNotification:any;

  notificationdata:any[]=[];






  constructor(
    private addpushnotificationservice: AddPushNotificationService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private fb: FormBuilder,
    private loginService:LoginService,
    private loginservice: LoginService,
    private cdr: ChangeDetectorRef,
    private changeDetectorRef: ChangeDetectorRef




  ) {
    this.notificationForm = this.fb.group({
      Notification: ['']
    });
  }

  ngOnInit() {


    this.userProfile = JSON.parse(localStorage.getItem("userdata"))  



    this.notificationUserid = [];
    this.userData = this.loginservice.getUserdata(true);
    this.loadnotificationlist()

  }


onChangeSearchNotification(val) {
     this.addpushnotificationservice.searchNotification(this.userProfile.id).subscribe(
      (data: any) => {
        this.notificationList = data;
        console.log("oo", this.notificationList)
      }
     )
  }


  loadnotificationlist(): void {
    this.addpushnotificationservice.getnotificationtablelist(this.userProfile.id).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          this.notificationdata = response.data;
          console.log("111",this.notificationdata)
        } else {
          console.error('Failed to load notifications', response);
        }
      },
      (error) => {
        console.error('Error loading notifications', error);
      }
    );
  }

  onFocusedNotification(e) {
    this.addpushnotificationservice.getNotificationList(this.userProfile.id).subscribe(
        (response: any) => {
            if (response.status === "success" && Array.isArray(response.data)) {
                this.notifications = response.data.map(item => ({
                    id: item.id,
                    display: item.display,
                    device_type : item.device_type
                })); // Store the full objects with display
                console.log("notifications", this.notifications);

                this.notificationList = response.data.map(item => item.display);
                console.log("notificationList", this.notificationList);
            } else {
                console.error("Invalid response format", response);
            }
        },
        (error) => {
            console.error("Error fetching notifications", error);
        }
    );
}

selectNotification(display: string) {
  const selectedNotification = this.notifications.find(notification => notification.display === display);
  if (selectedNotification) {
      this.selectedNotification = selectedNotification; // Assign the entire notification object
      console.log("Selected Notification Object:", selectedNotification);
  } else {
      console.error("No matching notification found for display:", display);
  }
}

  
// addNotification() {
//   console.log("===inii")
//   if (this.selectedNotification) {
//       const payload = {
//           userid: this.userProfile.id,
//           notifications: [this.selectedNotification], // Assuming you need to pass a list of notifications
//       };

//       this.addpushnotificationservice.addNotification(payload).subscribe(
//           (response: any) => {
//               if (response.status === "success") {
//                   console.log("Notification added successfully", response);

//                   this.notificationdata.push({
//                     id: response.notification.id, // Ensure you get the correct ID from the response

//                     display: this.selectedNotification.display
//                   });
        
//                   // Optionally, reset the selected notification
//                   this.selectedNotification = null;
//                   this.notificationForm.reset();
//                   this.router.navigate(['/add-pushnotification']);
//                 } else {
//                   console.error("Failed to add notification", response);
//               }
//           },
//           (error) => {
//               console.error("Error adding notification", error);
//           }
//       );
//   } else {
//       console.error("No notification selected");
//   }
// }
addNotification() {
  console.log("===inii")
  if (this.selectedNotification) {
    const payload = {
      userid: this.userProfile.id,
      notifications: [this.selectedNotification], // Assuming you need to pass a list of notifications
    };

    this.addpushnotificationservice.addNotification(payload).subscribe(
      (response: any) => {
        if (response.status === "success") {
          console.log("Notification added successfully", response);

          this.fetchNotifications();

          // Update the notification data
          this.notificationdata.push({
            id: response.notification.id, // Ensure you get the correct ID from the response
            display: this.selectedNotification.display
          });



          // Optionally, reset the selected notification
          this.selectedNotification = null;
          this.notificationForm.reset();

          // Show success message using SweetAlert2
          Swal.fire({
            title: 'Success!',
            text: 'Notification added successfully.',
            confirmButtonText: 'OK'
          }).then(() => {
            // Optionally navigate to another page
            // this.router.navigate(['/add-pushnotification']);
          });

          // Trigger change detection to update the table view
          this.changeDetectorRef.detectChanges();
        } else {
          console.error("Failed to add notification", response);
          // Show error message using SweetAlert2
          Swal.fire({
            title: 'Error!',
            text: 'Failed to add notification.',
            confirmButtonText: 'OK'
          });
        }
      },
      (error) => {
        console.error("Error adding notification", error);
        // Show error message using SweetAlert2
        Swal.fire({
          title: 'Error!',
          text: 'Error adding notification.',
          confirmButtonText: 'OK'
        });
      }
    );
  } else {
    console.error("No notification selected");
    // Show error message using SweetAlert2
    Swal.fire({
      title: 'Error!',
      text: 'No notification selected.',
      confirmButtonText: 'OK'
    });
  }
}

fetchNotifications() {
  this.addpushnotificationservice.getUserNotifications(this.userProfile.id).subscribe(
    (response: any) => {
      if (response.status === "success") {
        this.notificationdata = response.data;
        this.changeDetectorRef.detectChanges();
      } else {
        console.error("Failed to fetch notifications", response);
      }
    },
    (error) => {
      console.error("Error fetching notifications", error);
    }
  );
}




// deleteNotification(notificationId: number): void {
//   this.addpushnotificationservice.deleteNotification(this.userProfile.id, notificationId).subscribe(
//     (response: any) => {
//       if (response && response.status === 'success') {
//         console.log('Notification deleted successfully', response);
//         this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
//         this.loadnotificationlist()

//       } else {
//         console.error('Failed to delete notification', response);
//       }
//     },
//     (error) => {
//       console.error('Error deleting notification', error);
//     }
//   );
// }



deleteNotification(notificationId: number): void {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to delete this notification?',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) { // Check if the user clicked the confirm button
      this.addpushnotificationservice.deleteNotification(this.userProfile.id, notificationId).subscribe(
        (response: any) => {
          if (response && response.status === 'success') {
            console.log('Notification deleted successfully', response);
            // Update notificationdata to remove the deleted notification
            this.notificationdata = this.notificationdata.filter(notification => notification.id !== notificationId);
            Swal.fire('Deleted!', 'Your notification has been deleted.', 'success');
          } else {
            console.error('Failed to delete the notification', response);
            Swal.fire('Error!', 'Failed to delete the notification.', 'error');
          }
        },
        (error) => {
          console.error('Error deleting notification', error);
          Swal.fire('Error!', 'Error deleting the notification.', 'error');
        }
      );
    }
  });
}



// deleteNotification(notificationId: number): void {
//   this.addpushnotificationservice.deleteNotification(this.userProfile.id, notificationId).subscribe(
//     (response: any) => {
//       if (response && response.status === 'success') {
//         console.log('Notification deleted successfully', response);
//         this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
//         this.loadnotificationlist();

//         // Show success message using SweetAlert2
//         Swal.fire({
//           title: 'Notification deleted successfully',
//           timer: 3000, // Auto close timer (ms)
//           showConfirmButton: false
//         });

//       } else {
//         console.error('Failed to delete notification', response);
        
//         // Show error message using SweetAlert2
//         Swal.fire({
//           title: 'Failed to delete notification',
//           text: 'An error occurred while deleting the notification.',
//           confirmButtonText: 'Close'
//         });
//       }
//     },

//   );
// }

  
}








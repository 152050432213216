import { Injectable, EventEmitter } from '@angular/core';
// import { API_URL, User } from '../global';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { Router } from "@angular/router";
import swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/global';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

   @Injectable({
               providedIn: 'root',
                })

  export class BroadcastService {
        private permissions: any;
        voicedata = new EventEmitter<any>();
     
	    constructor(
            private http: HttpClient,
            private router: Router,
            public sharedService:SharedService,

            ) { } 

        API_URL = this.sharedService.getGlobalVar()

        getVoicebroadcastList(id){
            const headers=new HttpHeaders
            headers.append('Content-Type','application/json')
            return this.http.get(this.API_URL +'usermanagement/voicebroadcast-list/'+id+'/', {headers: headers})
        }

         getVoiceBroadcast(id: number) {
                const headers = new HttpHeaders();
                return this.http.get(this.API_URL +'usermanagement/voicebroadcastDetails/'+id+'/', {headers: headers})
            }

         deleteVoicebroadcast(id) {
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'application/json');
                return this.http.delete(this.API_URL+'usermanagement/voicebroadcastDetails/'+id+'/', {headers: headers})
            }  

        updateVoicebroadcast(id, body) {
                const headers = new HttpHeaders();
                headers.append('Method', 'multipart/form-data');
                return this.http.put(this.API_URL+'usermanagement/voicebroadcastDetails/'+id+'/', body, {headers: headers})

                .map(data=> {
                    console.log("response  data",data)
                    this.voicedata.emit(data);
                   
                })
                .catch(this.handleError);
            
            }
        broadcastformSend(data: any) {
           
                const headers = new HttpHeaders();
                console.log("our data",data)
                headers.append('Method', 'multipart/form-data');
                return this.http.post(this.API_URL + 'usermanagement/voicebroadcast-create/', data, {
                    headers: headers
                })
            
            .map(data=> {
                console.log("response  data",data)
                this.voicedata.emit(data);
               
            })
            .catch(this.handleError);
            // .catch((error: any) => console.log("cccccccccccc",error))
              }

private handleError(error: any) {
		return Observable.throw(error.json());
	}

    getTimeField(timeConditionSetId:number) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(this.API_URL+'usermanagement/MultipleScheduleList/'+timeConditionSetId+'/', {
          headers: headers
        });
      }





      voicebroadcastflush(data: any) {
           
        const headers = new HttpHeaders();
        console.log("our data",data)
        headers.append('Method', 'multipart/form-data');
        return this.http.post(this.API_URL + 'usermanagement/voicebroadcastflush-create/', data, {
            headers: headers
        })
    
    .map(data=> {
        console.log("response  data",data)
        this.voicedata.emit(data);
       
    })
    .catch(this.handleError);
      }

    getbroadcastDIDList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'usermanagement/broadcastDIDList/'+userProfileId+'/', {headers: headers})
    }

    public downloadsamplesheet(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelSheet(excelBuffer, excelFileName);
      }
    private saveAsExcelSheet(buffer: any, fileName: string): void {
         const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
         FileSaver.saveAs(data, fileName + new  Date().getTime() + EXCEL_EXTENSION);
      }

}


   

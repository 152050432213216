import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ExtensionService } from './../extension.service';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';


@Component({
  selector: 'app-extension-list',
  templateUrl: './extension-list.component.html',
  styleUrls: ['./extension-list.component.css']
})
export class ExtensionLIstComponent implements OnInit {
  userData:any;
  extensionListData:any;
  isAdmin:string = '';
  permissions: any;
  permis: boolean;

  constructor( 
    private loginservice: LoginService,
    private extensionService:ExtensionService,
    private router: Router,
    private notifyservice: NotificationService
    ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.isAdmin = localStorage.getItem('isAdmin')
    this.permissions = this.userData.permissions
      for (let element of this.permissions) {
        console.log(element.codename)
        if(element.codename == "cadmin_h_permission_extension"){
          this.permis = true;
        }
      }
    if(this.userData.group == 'superadmin'){
      this.extensionService.getExtensionList(0).subscribe(
        (data:any) => {
          this.extensionListData = data
          console.log('data',this.extensionListData);
        }

      )
    }else{
      this.extensionService.getExtensionList(this.userData.id).subscribe(
        (data:any)=>{
          this.extensionListData=data;
          console.log('data',this.extensionListData);
        }
      )
    }
  }

  deleteExtension(id,did) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The Extension Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.extensionService.deleteExtensionData(id,did).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'Extension delete successfully', 'is-success')

            this.extensionService.getExtensionList(this.userData.id).subscribe(
              (data:any)=>{
                this.extensionListData=data;
                console.log('data',this.extensionListData);
              }
            )

          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Extension deleting has been cancelled', 'is-danger')
      }
    })
  }
}

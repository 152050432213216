import { Component, OnInit } from '@angular/core';

@Component({
	selector: '[app-users]',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
	public menus:any

	constructor() { }

	ngOnInit() {
		this.menus = [
           	{"link":"/users/user","name":"Add User"},
			{"link":"/users/usergroup", "name":"Add Group"}

		]
	}

}

import { Component, OnInit } from '@angular/core';
import { BroadcastService } from "../voicebroadcast.service";
import swal from 'sweetalert2';
import { LoginService } from '../../login.service';
@Component({
  selector: 'app-voicebroadcast-list',
  templateUrl: './voicebroadcast-list.component.html',
  styleUrls: ['./voicebroadcast-list.component.css']
})
export class VoicebroadcastListComponent implements OnInit {
  public voicebroadcastListData:any;
  userData: any;
  constructor(private broadcastservice: BroadcastService,
      private loginservice: LoginService,
    ) { }




  ngOnInit() { 
    this.userData = this.loginservice.getUserdata(true);
    this.broadcastservice.getVoicebroadcastList(this.userData.id).subscribe(
       (data:any)=>{
        this.voicebroadcastListData=data;
        console.log('data',this.voicebroadcastListData);
      }
    ) 
  }
  
   deleteVoicebroadcast(id) {
      swal.fire({
        title: 'Are you sure ?',
        text: 'The voicebroadcast will be  Deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.broadcastservice.deleteVoicebroadcast(id).subscribe(
          (res:any) => {
            console.log('data', res)
            swal.fire(
              'Deleted',
              'Time Voice Broadcast Deleted Successfully',
              'success'
            )
            this.broadcastservice.getVoicebroadcastList(this.userData.id).subscribe(
              (data:any) =>{
                this.voicebroadcastListData = data
              }
            )
          }
        )
      }else{
        swal.fire(
          'Cancelled',
          'Time Voice Broadcast Deletion has been Cancelled',
          'error'
        )
        
      }
    })
  }
  

}



  

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { SharedService } from './../../global';

@Injectable({
  providedIn: 'root'
})

export class ExtensionService {

  constructor(
    private http:HttpClient ,
    public sharedService:SharedService,
    ) { }


  API_URL = this.sharedService.getGlobalVar()

  getExtensionList(userProfileId:number){
    console.log("============API_URL==========",this.API_URL)
    const headers=new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.get(this.API_URL+'extensionList/'+userProfileId+'/', {headers: headers});
  }

  getExtensionData(id:number) {
    const headers=new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.get(this.API_URL+'extensionDetails/'+id+'/', {headers: headers});
  }

  addExtensionData(extensionData) {
    const headers=new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.post(this.API_URL+'NewextensionCreate/', extensionData, {headers: headers});
  }

  updateExtensionData(id:number, body) {
    const headers=new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.put(this.API_URL+'extensionDetails/'+id+'/', body, {headers: headers});
  }

  deleteExtensionData(id:number,did:number) {
    const body = {"id":id,"did":did}
    console.log(body)
    const headers=new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.post(this.API_URL+'extensionMemberAllDelete/',body,{headers: headers})
  }

  searchExtension(val:string, userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'searchExtensionList/'+userProfileId+'/?search='+val, { headers: headers});
  }

  searchInitialExtension(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'searchExtensionList/'+userProfileId+'/', { headers: headers});
  }

  addExtensionMemberData(extensionMemberData) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.post(this.API_URL+'extensionMemberList/0/', extensionMemberData, {headers: headers})
  }

  getExtensionMemberList(extensionId) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.get(this.API_URL+'extensionMemberList/'+extensionId+'/', {headers: headers})
  }

  getExtensionMemberDetails(id:number){
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.get(this.API_URL+'extensionMemberDetails/'+id+'/', {headers: headers})
  }

  updateExtensionMemberDetails(id:number, body){
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.put(this.API_URL+'extensionMemberDetails/'+id+'/', body, {headers: headers})
  }

  DeleteExtensionMember(id:number,did:number){
    console.log("oiiiii")
    const body = {"id":id,"did":did}
    console.log(body);
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.post(this.API_URL+'extensionMemberDelete/',body,{headers: headers})
  }


  getExtensionDetails(DIDNumberId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.get(this.API_URL+'extensionFetch/?DIDNumberId='+ DIDNumberId, {headers: headers})
  }


  getExtenionMemberDetailsNew(extensionId, searchtext, callstatus,current_page,action,project){
    const headers = new HttpHeaders();
    const body = {"extensionId":extensionId,"search":searchtext,"callStatus":callstatus, "action":action, "project":project}
    console.log("FETCH DETAILS",body)
    headers.append('Content-Type','application/json');
    return this.http.post(this.API_URL+'extensionMemberListSearch/?page='+current_page,body ,{headers: headers});
  }


  uploadExtension(extensionFileToUpload,id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('datas', extensionFileToUpload);
    formData.append('extensionId', id)
    return this.http.post(this.API_URL+'uploadExtension/', formData, {headers: headers})
  }

  getExtensionDetailsId(extensionId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.get(this.API_URL+'extensionFetchData/?extensionId='+ extensionId, {headers: headers})
  }

  addVirtualSipData(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json')
    return this.http.post(this.API_URL+'addVirtualSipData/',body,{headers: headers})
  }

  getVirtualSipList(userProfileId){
    const body = {"id":userProfileId, "scope": "bon_web"}
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.post(this.API_URL+'getVirtualSipList/',body, {headers: headers});
  }

  deleteSip(id) {
    const body = {"id":id}
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'deleteSip/',body, {headers: headers})
}
}

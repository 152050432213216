
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from "./../../global";

@Injectable({
  providedIn: 'root',
})

export class ZohoIntegrationService{   

    constructor( private http: HttpClient, public sharedService: SharedService){}

    API_URL = this.sharedService.getGlobalVar();

    getCRMConfig(user_id:string){
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.get(this.API_URL + "getCRMConfig/" + user_id + "/",{headers: headers})
    }

    addZohoAgent(data:any){
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "router/zohoCRM/", data, {headers: headers})
    }

    getZohoAgentList(qParams:any[]){
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        let params = new HttpParams()

        qParams.forEach(param =>{
            params.append(param.key, param.value)
        })
        return this.http.get(this.API_URL + "router/zohoCRM/", {headers: headers, params: params})
    }

    deleteZohoAgent(user_id:string){
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.delete(this.API_URL + "router/zohoCRM/" + user_id + "/",{headers: headers})
    }

    switchClickToDial(data:any){
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "switchClickToDial/", data, {headers: headers})

    }

    addCrmConfig(data: any){
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "addZohoCRMConfig/", data, {headers: headers})
    }

    switchPhoneBridge(data: any) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "switchPhoneBridge/", data, {headers: headers})
    }

}
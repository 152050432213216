import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from 'src/global'; 

@Injectable({
  providedIn: 'root'
})
export class FollowUpService {

  constructor(
    private http: HttpClient,
		public sharedService: SharedService
  ) { }

  API_URL = this.sharedService.getGlobalVar()

  AddFollowUp(body: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.post(this.API_URL+'usermanagement/customer_follow_up/', body, {headers:headers})
  }

  getFollowUp(customer_id: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.get(this.API_URL+'usermanagement/customer_follow_up/?customer_id='+customer_id, {headers:headers})
  }

  EditFollowUp(follow_up_id:any, body:any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.put(this.API_URL+'usermanagement/customer_follow_up/'+follow_up_id+'/', body, {headers:headers})
  }

  DeleteFollowUp(follow_up_id:any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.request('delete',this.API_URL+'usermanagement/customer_follow_up/'+follow_up_id+'/', {headers:headers})
  }

  LoadFollowUpReport(user_id: any, user_type='', user_name='',user_number=''){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
    let params = new HttpParams()
    .set('user_id', user_id)
    .set('user_type', user_type)
    .set('user_name', user_name)
    .set('user_number', user_number);
  
    return this.http.get(this.API_URL+'usermanagement/follow_up_report/', { headers: headers, params: params })
  }

  FilterFollowUpReport(filterBody:any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.post(this.API_URL+'usermanagement/follow_up_report/', filterBody, {headers:headers})
  }
}

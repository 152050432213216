import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { ReportService } from '../inbound-report/report.service'
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { API_URL, VOICE_URL } from 'src/global';
import { Pipe, PipeTransform } from '@angular/core';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { ExcelService } from 'src/app/inbound-report/excel.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { DatePipe } from '@angular/common';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { ReportSettingsService } from '../report-settings/report-settings.service';


@Component({
  selector: 'app-dialler-report',
  templateUrl: './dialler-report.component.html',
  styleUrls: ['./dialler-report.component.css'],
  providers: [DatePipe]
})


export class DialerReportComponent implements OnInit {
  DialerReportData: any = new Array();
  wordarray: any = new Array();
  date1: string;
  keyword = 'name';
  userData: any;
  agent: any;
  agentlist: any;
  project: any;
  projectlist: any;
  projectInitial: string = '';
  agentInitial: string = '';
  feedback: any;
  feedbacklist: any;
  feedbackInitial: string = '';
  private hostUrl: string = API_URL;
  dataz: any;
  did: any;
  // objs: number[];
  objs: number[] = [];
  DialerReportDataz: { "que1": string; }[];
  dataOne: any;
  dataAnswer: any;
  questions: string[] = [];
  excelQuestions: string[] = [];
  excelQuest: string[] = [];
  dataRes: string = '';
  agentName: any;
  AgentProList: string[] = [];
  permis = false;
  permissions: any;
  myDate = new Date();
  today = new Date();
  tdy = new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, 'yyyy-MM-dd');
  usrId: any;
  fromdate: string;
  todate: string;
  current_page: number;
  total_page: number[];
  last: number;
  count: number;
  goPage: number;
  goPage1: number;
  head: string[][];
  newarray: any = new Array();
  objz: any = new Array();
  finalData: any = new Array();
  finalArray: any = new Array();
  countSlNo: number;
  question: any = new Array();
  finalQstns: any = new Array();
  finalQstnData: any = new Array();
  pdfData: any;
  feedbacks: any;
  val: any;
  voice_url = VOICE_URL;
  uploadFiles: any;
  questions_array: any = new Array();
  search_value_text: string;
  isShownprogressbar = false;
  progress: number = 0;
  getQuestionAnswer: any
  questions1: string[] = [];
  userType: any;


  // Param for address book
  addresspopup: boolean = false;
  contact: any;
  contact_initial: any;

  // Settingsforcomment
  commentopen: boolean = false;
  comment: any
  call_id: any;

  tagList: any = new Array()
  tag_filter: any = ""
  showTagDiv: boolean = false

  constructor(
    private reportservice: ReportService,
    private loginservice: LoginService,
    private excelService: ExcelService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private reportsettingservice: ReportSettingsService,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.contact_initial = { 'number': '', 'name': '', 'email': '', 'id': '', 'user': this.userData.id }
    this.contact = this.contact_initial

    this.permissions = this.userData.permissions
    this.userType = localStorage.getItem('userType')
    for (let element of this.permissions) {
      if (element.codename == "cadmin_q_dialerReport") {
        this.permis = true;
      }
    }

    this.fromdate = '';
    this.todate = ''
    this.current_page = 1;
    this.feedbacks = ''
    this.val = 'Init'
    this.project = this.projectlist
    this.search_value_text = ''
    this.getCallTags()
    this.FetchDialerReportData();
  }


  selectagent(item) {
    this.agent = item.id
    this.agentName = item.name;
  }


  onChangeSearchagent(val: string) {
    this.reportservice.searchagentList(val, this.userData.id).subscribe(
      (data: any) => {
        this.agentlist = data

      }
    )
  }

  onFocusedagent(e) {
    this.reportservice.getagentList(this.userData.id).subscribe(
      (data: any) => {
        this.agentlist = data
      }
    )
  }

  onClearagent() {
    this.agent = 0
  }


  selectproject(item) {
    this.agent == undefined ? this.project = item.id : this.project = item.valid;
  }

  getProjectList() {
    this.reportservice.getprojectList(this.userData.id, "", "All").subscribe(
      (data: any) => {
        this.projectlist = data
      }
    )
  }

  onChangeSearchproject(val: string) {
    this.reportservice.getprojectList(this.userData.id, "", "All").subscribe(
      (data: any) => {
        this.projectlist = data
      }
    )
  }

  onClearproject() {
    this.project = 0
    this.DialerReportData.setValue('');
    this.feedbacklist = '';
  }


  selectfeedback(item) {
    this.feedbacks = item.name
  }

  onChangeSearchfeedback(val: string) {
    this.reportservice.searchfeedbackList(val, this.project).subscribe(
      (data: any) => {
        this.feedbacklist = data
      }
    )
  }

  onClickfeedback(e) {
    if (this.project == 0) {
      Swal.fire('Oops..!', 'Select Project', 'warning');

    }
    else {
      this.reportservice.getfeedbackList(this.project).subscribe(
        (data: any) => {
          this.questions_array = data.map(a => a.name);
          data.unshift({ id: 10, name: "SELECT ALL FOR REPORTS" })
          this.feedbacklist = data
        }
      )
    }
  }

  onClearfeedback() {
    this.feedbacks = ''
    this.feedbacklist = ''
  }


  getcallrecord(url, date) {
    this.reportservice.getcallrecord(url, date).subscribe(
      (res: any) => {
        var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
        var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd');
        this.reportservice.getDialerReport(this.userData.id, from, to, this.agent, this.project, this.feedbacks, this.current_page, this.val, '', this.search_value_text, this.userType, this.tag_filter).subscribe(
          (res: any) => {
            this.DialerReportData = res["results"]
            this.usrId = this.userData.id
            if ('questionsFeedback' in res["results"][0]) {
              var quest = res["results"][0]['questionsFeedback'][0]
              this.questions = [];
              for (let element in quest) {
                this.questions.push(element)
                this.dataRes = "a";
              }
            }
            else {
              this.questions = [];
              this.dataRes = "b";
            }
            this.notifyService.sendNotification('Downloaded', 'Download Voice successfully', 'is-success')

          }
        )
      }
    )
  }

  FetchDialerReportData() {
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd');
    this.reportservice.getDialerReport(this.userData.id, from, to, this.agent, this.project, this.feedbacks, this.current_page, this.val, '', this.search_value_text, this.userType, this.tag_filter).subscribe(
      (res: any) => {
        this.DialerReportData = res["results"]
        this.total_page = res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
        this.usrId = this.userData.id
        this.feedback = this.feedbacks

        let questionSet = new Set();
        for (let result of res.results) {
          for (let feedback of result.questionsFeedbacks) {
            questionSet.add(feedback.question);
          }
        }
        this.questions1 = Array.from(questionSet) as string[];
      }
    )
  }

  getQuestionAnswer1(result, question) {
    for (let feedback of result.questionsFeedbacks) {
      if (feedback.question === question) {
        return feedback.answer;
      }
    }
    return '';
  }

  SearchdialerReport() {
    this.val = "Search"
    this.current_page = 1
    this.FetchDialerReportData();
  }

  exportAsXLSX(): void {
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd');
    this.reportservice.getDialerReport(this.userData.id, from, to, this.agent, this.project, this.feedbacks, this.current_page, this.val, "yes", this.search_value_text, this.userType, this.tag_filter).subscribe(
      (res: any) => {
        this.DialerReportData = res["results"]
        for (let element of this.DialerReportData) {
          var objz = {
            'DID': element['did'], 'Agent': element['agent'],
            'Agent Name': element['agentName'],
            'Date': element['callTime'], 'Extension': element['extension'], 'Customer': element['customer_number'], 'Country code': element['country_code'], 'Customer Name': element['customer_name'],
            'Call Duration': element['legb'], 'Status': element['status'], 'Comment': element['comments'],
            'callcomment': element['callcomment'], 'Tag': element['tag'], 
            'Project': element['projects'], 'Category': element['category'],
            'sub Category': element['subcategory'], 'Description': element['description']
          }
          if (this.feedbacks != '' && this.feedbacks != 'SELECT ALL FOR REPORTS') {
            element['questionsFeedbacks'][0]['answer']
            objz[this.feedbacks] = element['questionsFeedbacks'][0]['answer']

          }
          else {
            for (let question of this.questions_array) {
              for (let feedbac of element['questionsFeedbacks']) {
                if (Object.values(feedbac).indexOf(question) > -1) {
                  objz[question] = feedbac['answer']
                }

              }
            }
          }


          this.wordarray.push(objz)
        }
        var text;

        if (this.fromdate, this.todate) {
          text = "Dailer Report from: " + this.fromdate + " To: " + this.todate + ""
        }
        else {
          text = "Dailer Report of " + this.today_informat
        }
        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];
      })
  }



  downloads() {
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd');
    this.reportservice.getDialerReport(this.userData.id, from, to, this.agent, this.project, this.feedbacks, this.current_page, "Search", "yes", this.search_value_text, this.userType, this.tag_filter).subscribe(
      (res: any) => {
        this.pdfData = res["results"]
        this.countSlNo = 1
        this.head = [['SlNo', 'DID', 'Agent', 'Agent Name', 'Date', 'Extension', 'Customer', 'Country code', 'Customer Name', 'Call Duration', 'Status', 'comment', 'callcomment', 'Tag', 'Project', 'Category', 'sub Category', 'Description']]

        if (this.feedbacks != '' && this.feedbacks != 'SELECT ALL FOR REPORTS') {
          this.head[0].splice(8, 0, this.feedbacks);
        }
        else {
          this.head[0].push.apply(this.head[0], this.questions_array);
        }

        for (let element of this.pdfData) {

          this.newarray.push(this.countSlNo, element['did'], element['agent'],
            element['agentName'], element['callTime'], element['extension'], element['customer_number'], element['country_code'], element['customer_name'],
            element['legb'], element['status'], element['comments'], element['callcomment'], element['tag'], 
            element['projects'], element['category'],
            element['subcategory'], element['description'])
          if (this.feedbacks != '' && this.feedbacks != 'SELECT ALL FOR REPORTS') {
            if (element['questionsFeedbacks'][0]['answer']) {
              this.newarray.splice(8, 0, element['questionsFeedbacks'][0]['answer']);
            }
            else {
              this.newarray.splice(8, 0, "");

            }
          }

          else {
            var count = 1
            var b = element['questionsFeedbacks']
            for (let question of this.questions_array) {
              for (let feedbac of b) {
                if (Object.values(feedbac).indexOf(question) > -1) {
                  this.newarray.splice(12 + count, 0, feedbac.answer);
                  b.splice(0, question);
                  break
                }
              }
              count = count + 1
            }
          }

          this.finalQstns.push(this.head[0])
          this.finalArray.push(this.newarray)

          this.countSlNo = this.countSlNo + 1
          this.finalData = []
          this.newarray = []
        }

        this.finalQstnData.push(this.finalQstns[0])
        var pdf = new jsPDF();

        pdf.text('Dialer Report', 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);

        (pdf as any).autoTable({
          head: this.finalQstnData,
          body: this.finalArray,
          theme: 'grid',
          styles: { fontSize: 4 },
          tableWidth: 'auto',

          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        })

        // Open PDF document in new tab
        pdf.output('dataurlnewwindow')
        pdf.save('Dialer.pdf');
        this.head = []
        this.newarray = []
        this.finalQstnData = []
        this.finalArray = []
        this.finalQstns = []
      })
  }


  fetchNumber(i) {

    if (i == 'prev') {
      if (this.current_page > 1) {
        this.current_page = this.current_page - 1
        this.FetchDialerReportData();
      }
    }

    else if (i == 'next') {
      if (this.current_page < this.last) {
        this.current_page = this.current_page + 1
        this.FetchDialerReportData();
      }
    }

    else if (i == 'first') {
      this.current_page = 1
      this.FetchDialerReportData();
    }

    else if (i == 'last') {
      this.current_page = this.last
      this.FetchDialerReportData();
    }

    else {
      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.current_page = i
        this.FetchDialerReportData();

      }
      else {
        Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
      }
    }
  }

  onNavigate() {
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd');

    this.reportservice.getDialerReport(this.userData.id, from, to, this.agent, this.project, this.feedbacks, this.current_page, this.val, "voice_download_yes", this.search_value_text, this.userType, this.tag_filter).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          this.isShownprogressbar = true;
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.DownloadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          var a = document.createElement('a');
          var blob = new Blob([event.body], { 'type': "application/zip" });
          a.href = URL.createObjectURL(blob);
          if (from && to) {
            a.download = from + '_' + to + ".zip";
          }
          else {
            a.download = "INBOUND_" + this.today_informat + ".zip";
          }
          a.click();
          setTimeout(() => {
            this.progress = 0;
            this.isShownprogressbar = false;
          }, 1500);
      }
    })
  }

  LoadPage() {
    this.FetchDialerReportData();
  }


  closeContact() {
    this.addresspopup = false
    this.contact = this.contact_initial
  }


  openContact(mobile, name, id) {
    this.addresspopup = true
    this.contact.number = mobile
    this.contact.name = name

    if (id) {
      this.reportservice.getcontact(id).subscribe(
        (res: any) => {
          if (res.id) {
            this.contact = res
          }
          else {
            this.notifyService.sendNotification('', 'Error in Getting conatct', 'is-danger')
          }
        });
    }
  }

  CloseComment() {
    this.commentopen = false
    this.call_id = ""
    this.comment = ""
  }

  updateReportPage() {
    this.FetchDialerReportData()
  }

  addCallRecordTag(call_record_id: any, index: any) {
    this.reportsettingservice.getReportTagChild(call_record_id).subscribe((res: any) => {
      const updatedTags = res.tag.split(',').map(tag => tag.trim());
      this.DialerReportData[index].tag = updatedTags.join(', ');
    })
  }

  deleteCallRecordTag(call_record_id: any, tag: any, index: any) {
    const body = {
      tag: tag
    }

    this.reportsettingservice.deleteReportTagChild(call_record_id, body).subscribe((res: any) => {
      this.DialerReportData[index].tag = res.tags.tag
    })
  }

  getCallTags() {
    let user_id: any
    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res: any) => {
      this.tagList = res.report_tags
    })
  }

}






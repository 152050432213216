import { ExtensionService } from './../extension.service';
import { Component, OnInit } from '@angular/core';
import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/inbound-report/report.service';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';

@Component({
  selector: '[app-addextension]',
  templateUrl: './addextension.component.html',
  styleUrls: ['./addextension.component.css']
})

export class AddextensionComponent implements OnInit {
  id:number = 0;
  userData:any;
  extensionId:number = 0
  didList:any;
  DIDNumber:number = 0;
  DIDNumberInitial:string = '';
  keyword = "name";
  musicOnHold:string = '';
  invalidExtension: string = '';
  userInfo:number = 0;
  extensionFile: any;
  uploadView:boolean = true;
  extensionNumber:string = '';
  description:string = '';
  destination:string = '';
  extensionFileToUpload: File;
  extensionData: {};
  uploadData: any;
  extensionMemberData: any;
  musicOnHoldLabel: string = 'Music on hold';
  invalidExtensionLabel: string = 'Invalid extension'
  musicOnHoldInitial:any;
  invalidExtensionInitial:any;
  voicelist:any = new Array();
  isAdmin:string = 'false'
  UserProfileId:number = 0;
  customerInitial:any;
  customerList:any = new Array();
  isEdit:boolean = true; 
  channelId: string='';
  dialRecentAgent: string='0';
  permissions: any;
  permis: boolean;

  // ========EXTENSION MEMBERS LIST SEARCH===============

  extensionmembersData:any = new Array();
  current_page: number=1;
  total_page:any;
  last: number;
  fromdate: string='';
  todate: string='';
  goPage: number;
  searchText: string='';
  callStatus:string = '';
  action:string='';
  project:string='';
  projectlist:any;
  projectInitial:string = '';
	ChannelIdArray = new Array();

  constructor(
    private extensionService:ExtensionService,
    private inboundRouteService:InboundRouteService,
    private loginService:LoginService,
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private voicerecodrservice: VoiceRecordService,
    private activeroute: ActivatedRoute,
    private router: Router,
    private usermanagementService: UsermanagementService,
    ) { }


  ngOnInit() {


    this.usermanagementService.getChannelIds().subscribe(
			(data:any) =>{
			  console.log("channel_idData",data);
			  this.ChannelIdArray = data
			}
		   )






    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
      for (let element of this.permissions) {
        console.log(element.codename)
        if(element.codename == "cadmin_h_permission_addextension"){
          this.permis = true;
        }
      }
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id 
    }
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    if(this.id != 0) {
      this.isEdit = false;
      this.extensionId = this.id
      this.extensionService.getExtensionDetailsId(this.extensionId).subscribe(
        (data:any)=>{
          if(data.status == '1'){
            console.log('data', data.data);
            this.DIDNumber = data.data.didNumber_id
            this.extensionId = data.data.id
            this.channelId = data.data.channelId
            this.setExtensionDetailsData(data.data);
          }
        }
      )
    }
    
  }



  
  selectDID(item) {
    this.DIDNumber = item.id
    this.extensionService.getExtensionDetails(this.DIDNumber).subscribe(
      (data:any)=>{
        if(data.status == '1'){
          console.log('data', data.data);
          this.extensionId = data.data.id
          this.setExtensionDetailsData(data.data);
        }
      }
    )
  }
 
  setExtensionDetailsData(extDetails){

    this.UserProfileId = extDetails.user_id
    this.description = extDetails.description;
    this.musicOnHold = extDetails.musiconhold;
    this.invalidExtension= extDetails.invalid_extension;
    this.dialRecentAgent = extDetails.dialRecentAgent

    this.loginService.getCustomerList().subscribe(
      (data:any) => {
        this.customerList = data
        for (let customerData of this.customerList){
          if(customerData.id == extDetails.user_id) {
            this.customerInitial = customerData.name
          }
        }
      }
    )

    this.voicerecodrservice.getVoiceRecordList(this.UserProfileId,0).subscribe(
      (data:any) =>{
        this.voicelist = data
        console.log('search music list', this.voicelist)
        for (let voiceData of this.voicelist){
          if(voiceData.fileUrl == this.musicOnHold) {
            this.musicOnHoldInitial = voiceData.name
          }
          if(voiceData.fileUrl == this.invalidExtension) {
            this.invalidExtensionInitial = voiceData.name
          }
        }
      }
    )

    this.inboundRouteService.getDIDList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
        console.log('did list', this.didList)
        for (let did of this.didList){
          if(did.id == extDetails.didNumber_id) {
            this.DIDNumberInitial = did.name
          }
        }
      }
    )
    this.FetchExtenionMembersData(1)
  }





  customerEvent(data) {
    this.UserProfileId = data
    if(this.UserProfileId == 0){
      this.clearDatas();
      console.log('cleared')
    }else{
      this.inboundRouteService.getDIDList(this.UserProfileId).subscribe(
        (data:any) =>{
          this.didList = data
        }
      )
    }
  }

  clearDatas(){
    this.DIDNumber = 0
    this.description =''
    this.musicOnHold = ''
    this.invalidExtension = ''
  }


  onChangeSearchDID(val: string) {
    this.inboundRouteService.searchDID(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
        console.log('search did list', this.didList)
      }
    )
  }
  
  onFocusedDID(e){
    this.inboundRouteService.getDIDList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
        console.log('did focused list', this.didList)
      }
    )
  }

  onMusiconHold(data){
    console.log("path55555", data);
    this.musicOnHold = data;
  }

  onInvalidExtension(data){
    console.log("path11111111",data);
    this.invalidExtension=data;
  }

  onFilePathExtension(data){
    console.log("path",data);
    this.extensionFile=data;
    this.handleFileInput(this.extensionFile);
  }

  handleFileInput(files: FileList) {
    this.extensionFileToUpload = files.item(0);
    console.log('e f selected');
  }

  hideUploadView(){
    console.log("hide upload");
    if(this.extensionNumber == ''){
      this.uploadView = true;
    }else{
      this.uploadView = false;
    }

  }

  onSubmit(){
    if(this.isAdmin == 'true' && this.UserProfileId == 0){
      Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    }else if(this.DIDNumber == 0) {
      Swal.fire('Oops..!', 'Select Valid DID Number', 'warning')
    }else if(this.channelId == "0") {
      Swal.fire('Oops..!', 'Select ChannelId', 'warning')
    }else {
      this.extensionData = {
        'didNumber':this.DIDNumber,
        'description':this.description,
        'musiconhold':this.musicOnHold,
        'invalid_extension':this.invalidExtension,
        'user':this.UserProfileId,
        'channelId' : this.channelId,
        'dialRecentAgent' : this.dialRecentAgent,
      }
      if (this.extensionId != 0){
        console.log("dataaaaaa",this.extensionData)
        this.extensionService.updateExtensionData(this.extensionId, this.extensionData).subscribe(
          (res:any)=>{

            console.log('res', res['id']);
            if(res && res['id']){
              this.extensionId = res.id
              this.notifyService.sendNotification('Updated', 'Extension updated successfully', 'is-success')
            }
          }
        )
      }else {
        console.log("dtzzzzz",this.extensionData)
        this.extensionService.addExtensionData(this.extensionData).subscribe(
          (res:any)=>{
            console.log('res', res['id']);
            if(res && res['id']){
              this.isEdit = false;
              this.extensionId = res.id
              this.notifyService.sendNotification('Created', 'Extension Created successfully', 'is-success')              
            }
          }
        )
      }
    }
  }




 


  FetchExtenionMembersData(pageNo){
    this.extensionService.getExtenionMemberDetailsNew(this.extensionId,this.searchText,this.callStatus,pageNo,this.action,this.project).subscribe(
      (res:any) => {
        this.extensionmembersData = res["results"]
        this.total_page =  res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
        this.action = ""

      }
  )

  }

  searchonExtensionMembers(){
    this.FetchExtenionMembersData(1)
  }


  fetchNumber(i) {
    if(i == 'prev'){
      if (this.current_page > 1){
        this.FetchExtenionMembersData(this.current_page-1);
      }
    }
  
    else if (i == 'next'){
      if (this.current_page < this.last){
        this.FetchExtenionMembersData(this.current_page+1);
      }
     
    }
  
    else if(i == 'first'){
      this.FetchExtenionMembersData(1);
    }
  
    else if(i == 'last'){
      this.FetchExtenionMembersData(this.last);
    }
  
    else{
  
      if (parseInt(i)=== parseInt(i, 10) && i > 0 && i <=  this.last){
        this.FetchExtenionMembersData(i);
      }
      else{
        Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
      }
  
    }
  
  }


  onEditMember(eventData, memberId) {
    let ele = eventData.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
    let inputs = ele.getElementsByClassName('input')
    let buttons = ele.getElementsByClassName('button')
    let icons = ele.getElementsByClassName('icon')
    inputs[3].disabled = false
    inputs[2].disabled = false
    buttons[0].style.display = "block"
    buttons[1].style.display = "block"
    icons[0].style.display = "none"
    icons[1].style.display = "none"
  }

  onCancelMember(eventData, memberId){
    this.extensionService.getExtensionMemberDetails(memberId).subscribe(
      (res:any) => {
        let memberData = res
        let ele = eventData.target.parentElement.parentElement.parentElement.parentElement
        let inputs = ele.getElementsByClassName('input')
        let buttons = ele.getElementsByClassName('button')
        let icons = ele.getElementsByClassName('icon')
        inputs[1].disabled = true
        inputs[1].value = memberData.number
        inputs[2].disabled = true
        inputs[2].value = memberData.destination
        buttons[0].style.display = "none"
        buttons[1].style.display = "none"
        icons[0].style.display = "block"
        icons[1].style.display = "block"
      }
    )
  }

  onUpdateMember(eventData, memberId, oldnumber){
    let ele = eventData.target.parentElement.parentElement.parentElement.parentElement
    let inputs = ele.getElementsByClassName('input')
    if(inputs[1].value == ''){
      Swal.fire('Oops..!', 'Entered valid number', 'warning')
    }else if(inputs[2].value == '') {
      Swal.fire('Oops..!', 'Entered valid destination', 'warning')
    }else if(oldnumber != inputs[1].value && this.extensionFile.map(function(o) { return o.number; }).indexOf(inputs[1].value) != -1){
      Swal.fire('Oops..!', 'Already existing number', 'warning')
    }else {
      let memberData = {
        'extension': this.extensionId,
        'number': inputs[1].value,
        'destination': inputs[2].value,
        'name':inputs[3].value
      }
      this.extensionService.updateExtensionMemberDetails(memberId, memberData).subscribe(
        (res:any) => {
          let updateMemberData = res
          let ele = eventData.target.parentElement.parentElement.parentElement.parentElement
          let inputs = ele.getElementsByClassName('input')
          let buttons = ele.getElementsByClassName('button')
          let icons = ele.getElementsByClassName('icon')
          inputs[1].disabled = true
          inputs[1].value = updateMemberData.number
          inputs[2].disabled = true
          inputs[2].value = updateMemberData.destination
          buttons[0].style.display = "none"
          buttons[1].style.display = "none"
          icons[0].style.display = "block"
          icons[1].style.display = "block"
          this.FetchExtenionMembersData(this.current_page)
          this.notifyService.sendNotification('Updated', 'Extension number update successfully', 'is-success')
        }
      )
    }
  }


  onDeleteMember(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The Extension Number will be Deleted !!!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.extensionService.DeleteExtensionMember(id,this.DIDNumber).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyService.sendNotification('Deleted', 'Extension number delete successfully', 'is-success')
            this.FetchExtenionMembersData(this.current_page)
          }
        )
      }else{
        this.notifyService.sendNotification('Cancelled', 'Extension number deleting has been cancelled', 'is-danger')
      }
    })
  }


  onDeleteSelectedExtensions(id){
    Swal.fire({
      title: 'Are you sure ?',
      text: 'All filterd Extension numbers will be deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.action = "delete"
        this.FetchExtenionMembersData(1)
        this.notifyService.sendNotification('Suceess', 'Extension Memebers are deleted successfully', 'is-danger')
      }else{
        this.notifyService.sendNotification('Cancelled', 'Extension all number deleting has been cancelled', 'is-danger')
      }
    })

  }


  Onclearproject() {
    console.log("clear")
    this.project = ""
  }


  selectproject(item) {
    console.log("============dfsfd")
    this.project = item.id
 
   }
 
   getProjectList(){
         this.reportservice.getprojectList(this.userData.id,"" ,"All").subscribe(
           (data:any) =>{
             this.projectlist = data
           }
         )
   }
 
   onChangeSearchproject(val: string) {
       this.reportservice.getprojectList(this.userData.id,"" ,"All").subscribe(
         (data:any) =>{
           this.projectlist = data
         }
       )
 
   }


}

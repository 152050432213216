import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-incoming-credit-management',
  templateUrl: './incoming-credit-management.component.html',
  styleUrls: ['./incoming-credit-management.component.css']
})
export class IncomingCreditManagementComponent implements OnInit {

  companies: any;
  data: any;
  companyList: any;
  credit :any;
  comp:any;
  existing_credit: any;
  permissions:any;
  permis = false;
  userData:any;

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_s_addIncomingCredit"){
        this.permis = true;
    }
  }
    this.usermanagementservice.getCompanyList().subscribe(
      (data:any) =>{
        console.log("tmep listss",data);
        this.companies = data
      }
     )
  
  }



  AddCredit = function(){
    this.usermanagementservice.getUserbyCompanyName(this.companyList).subscribe(
      (data:any) => {             
        var userId = data.data;
        this.Data = {
      'remaining_credit': this.credit,
      'user': userId,
    }
    console.log("dataaaaa",this.Data)
   this.usermanagementservice.AddCreditData(this.Data).subscribe(
      (res:any) => {             
        Swal.fire('Submitted', 'Threasold Frequency added', 'success')
        console.log("response is ",res)
      }
    )
  }
  )
  }

  getcompanycredit(e){
    console.log(this.companyList);
    console.log('iiii');
    this.comp = {
       'company': this.companyList,
       'type': ""
    }
    this.usermanagementservice.getExistingCompanyCredit(this.comp).subscribe(
      (data:any) => {     
        console.log(data.data);
        this.existing_credit = data.data        
                }
    )
  }

}

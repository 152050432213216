import { Component,OnInit, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute,Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subscription, timer } from "rxjs";
import { map, share } from "rxjs/operators";
import { LoginService } from '../login.service';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.css']
})
export class ImpersonateComponent implements OnInit, OnDestroy {
	title = 'bonvoice';
	public login:boolean = false; // default value should be false ;setting true for dev
	private username:string;
	private password:string;
	keyword:string = 'name';
	customerList:any = new Array();
	userData:any;
	CustomerName:string = '';
	isAdmin:string = '';
	href: string;
	myDate = new Date();
	userName: any;
	time = new Date();
	rxTime = new Date();
	intervalId;

	constructor(
		private loginservice:LoginService,
		private activeroute: ActivatedRoute,	
		private router:Router,
		
	){}

	ngOnInit(){

    console.log("***************",this.login,"******************")

		  // Using Basic Interval
		this.intervalId = setInterval(() => {
		this.time = new Date();
		}, 1000);

		this.loginservice.loginevent.subscribe(
			(loginstatus:boolean)=>{
				this.login = loginstatus
		});
		this.loginservice.getLoginStatus();
		if(this.login){
			this.userData = this.loginservice.getUserdata(true);
			this.router.navigate(['/home']);
			this.href = this.router.url;
			
		}

		this.userName = this.userData.name
	}
	ngOnDestroy() {
		clearInterval(this.intervalId);
		
	  }
	
	onLogin(){
		var data = {"username":this.username,"password":this.password}
    console.log("====PassWord",this.password,"====Username",this.username)
		this.loginservice.impersonateLogin(data).subscribe(
			(datas:any) => {
				if(datas["status"] == "1"){
					this.loginservice.setUserdata(datas["data"]);
					this.loginservice.setLoginStatus(1)
					// this.loginservice.get_auth_token(data)
					this.login = true;
					this.userData = this.loginservice.getUserdata(true);
          console.log(this.login,"***************",this.userData)
					if(datas.data.group == "superadmin"){
						localStorage.setItem('isAdmin', 'true')
					}else{
						localStorage.setItem('isAdmin', 'false')						
					}
					this.isAdmin = localStorage.getItem('isAdmin')
					this.router.navigate(['/home']);
				}else{
					Swal.fire({
						type: 'error',
						title: 'Oops...',
						text: datas["error"],
						footer: 'Please try with correct username/password'
					})
				}
			}
		)
	}

}

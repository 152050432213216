import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { SharedService } from "src/global";

@Injectable({
    providedIn: "root",
})
export class sendatatoexternalapilog{



    constructor(
        private http: HttpClient,
        public sharedService: SharedService
    ) { }
    
    API_URL = this.sharedService.getGlobalVar();

    

    getreport(body, pageNo) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "senddatatoexternalapilog/?page="+ pageNo, body, {headers:headers})
    }

}
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InboundRouteService } from "src/app/inbound-route/inbound-route.service";
import { LoginService } from "src/app/login.service";
import { DialerService } from "src/app/dialer/dialer.service";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NotificationService } from "src/app/notification/notification.service";
import { BroadcastService } from "src/app/voicebroadcast/voicebroadcast.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";


@Component({
  selector: "app-all-upload-excel",
  templateUrl: "./all-upload-excel.component.html",
  styleUrls: ["./all-upload-excel.component.css"],
})
export class AllUploadExcelComponent implements OnInit {
  ExtensionData: any = new Array();
  keyword = "name";
  keyword1 = "name";
  dialerFileToUpload: File;
  didList: any;
  subUser: any;
  subUserIds: any;
  subUserList: any;
  DIDNumber: number = 0;
  DIDNumberInitial: string = "";
  subUserInitial: string = "";
  UserProfileId: number = 0;
  isAdmin: string = "false";
  userData: any;
  SUBU: any;
  sublist: any;
  project: any;
  projectlist: any;
  category: any;
  categorylist: any;
  subcategory: any;
  subcategorylist: any;
  ProjectInitial: any;
  SUBInitial: any;
  categoryInitial: any;
  subcategoryInitial: any;
  users: any = new Array();
  projectName: string = "";
  permissions: any;
  permis = false;
  channelId: string = "0";
  dialRecentAgent: string = "0";


  userType: any;
  adminId : any;
  
  public newarray: any = [
    {
      SlNumber: "",
      Extension: "",
      Name: "",
      PhoneNumber: "",
      Description: "",
      User: "",
    },
  ];
  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router,
    private inboundRouteService: InboundRouteService,
    private loginService: LoginService,
    private dialerService: DialerService,
    private notifyService: NotificationService,
    private broadcastservice: BroadcastService,
    private route: Router
  ) {}

  ngOnInit() {
    this.users = [];

    this.subUserIds = [];
    this.isAdmin = localStorage.getItem("isAdmin");
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions;
    for (let element of this.permissions) {
      console.log(element.codename);
      if (element.codename == "cadmin_v_dialerexcel") {
        this.permis = true;
      }
    }
    if (this.isAdmin == "false") {
      this.UserProfileId = this.userData.id;
    }

    this.dialerService
      .getdialerprojectAll(this.UserProfileId)
      .subscribe((data: any) => {
        console.log(data.data);
        this.projectlist = data.data;
      });
    console.log("usrrrrrrr", this.UserProfileId);
  }

  selectProject(item) {
    this.project = item.pid;
  }

  onChangeSearchProject(val: string) {
    this.dialerService
      .searchdialerprojectAll(val, this.UserProfileId)
      .subscribe((data: any) => {
        console.log(data.data);
        this.projectlist = data.data;
      });
  }

  onFocusedProject(e) {
    this.dialerService
      .getdialerprojectAll(this.UserProfileId)
      .subscribe((data: any) => {
        this.projectlist = data.data;
      });
  }

  onClearProject() {
    this.project = 0;
  }

  //  user AutoCompleted Functions

  selectDID(item) {
    this.UserProfileId = item.id;
  }

  onChangeSearchDID(val: string) {
    this.dialerService
      .searchdialeruserList(val, this.UserProfileId)
      .subscribe((data: any) => {
        this.sublist = data;
        console.log("search did list", this.didList);
      });
  }

  onFocusedDID(e) {
    this.dialerService
      .getdialeruserList(this.UserProfileId)
      .subscribe((data: any) => {
        this.sublist = data;
        console.log("did focused list", this.didList);
      });
  }

  onClearDID() {
    this.UserProfileId = 0;
  }

  // category AutoCompleted Functions

  selectcategory(item) {
    this.category = item.id;
  }

  onChangeSearchcategory(val: string) {
    this.dialerService
      .searchdialercategoryList(val, this.project)
      .subscribe((data: any) => {
        this.categorylist = data;
      });
  }

  onFocusedcategory(e) {
    this.dialerService
      .getdialercategoryList(this.project)
      .subscribe((data: any) => {
        this.categorylist = data;
      });
  }

  onClearcategory() {
    this.category = 0;
  }
  // subcategory AutoCompleted Functions

  selectsubcategory(item) {
    this.subcategory = item.id;
  }

  onChangeSearchsubcategory(val: string) {
    this.dialerService
      .searchdialersubcategoryList(val, this.category)
      .subscribe((data: any) => {
        this.subcategorylist = data;
      });
  }

  onFocusedsubcategory(e) {
    this.dialerService
      .getdialersubcategoryList(this.category)
      .subscribe((data: any) => {
        this.subcategorylist = data;
      });
  }

  handleFileInput(files: FileList) {
    console.log("file data", files);
    this.dialerFileToUpload = files.item(0);
    console.log("file", this.dialerFileToUpload);
  }

  openAlert(): void {
    const options: SweetAlertOptions = {
      title: 'Error',
      text: 'An error occurred!',
      confirmButtonText: 'OK'
    };
  
    Swal.fire(options);
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  

  onUploadDialerExcel() {
    if (!this.dialerFileToUpload || this.dialerFileToUpload.size === 0) {
      Swal.fire('Oops..!', 'Please select a non-empty Excel file', 'warning');
      return; // Exit the function early if the file is empty
    }
    // this.spinnerService.show();
    this.dialerService
      .onUploadDialerExcel(
        this.channelId,
        this.dialerFileToUpload,
        this.project,
        this.category,
        this.subcategory,
        "all",
        this.UserProfileId,
        this.dialRecentAgent,
        this.userType
      )
      .subscribe((res: any) => {
        this.ExtensionData = res.data;
        console.log("data", this.ExtensionData);
        this.category = "";
        this.subcategory = "";
        if (res.status == "1") {
          // this.spinnerService.hide();
          this.route.navigateByUrl("/dialer/dialerList");
          Swal.fire("success..!", "Excel uploaded successfully", "success");
        } else if (res.status == "2") {
          console.log("STATUS -----------------  2")
          // this.spinnerService.hide();
          Swal.fire("oops..!", res.error, "warning");
        } else if (res.status == "3") {
          console.log("STATUS -----------------  3");
          // this.spinnerService.hide();
          Swal.fire(
            "oops..!",
            "Space is not allowed in file name !!",
            "warning"
          );
          this.reloadCurrentRoute();
        }
        }, (error) => {
            this.openAlert(); // Display popup message
      });
  }

 
  
  sampleBroadcastSheet(): void {
    this.broadcastservice.downloadsamplesheet(
      this.newarray,
      "DialerExcelSheet"
    );
  }
}

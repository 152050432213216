import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-table-content',
  templateUrl: './new-table-content.component.html',
  styleUrls: ['./new-table-content.component.css']
})
export class NewTableContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  rows = [
    { id: 1, name: 'Anjali', department: 'QA testing', date: '26-12-24', expanded: false },
    { id: 2, name: 'sankari', department: 'QA testing', date: '26-12-24', expanded: false },
    // Add other rows...
  ];

  // Function to toggle the expanded state of a row
  toggleRowDetails(row: any): void {
    row.expanded = !row.expanded;
  }
  editRow(row: any): void {
    // Add logic for edit action
    console.log('Edit row:', row);
  }

  callRow(row: any): void {
    // Add logic for call action
    console.log('Call row:', row);
  }

  notesRow(row: any): void {
    // Add logic for note action
    console.log('Notes row:', row);
  }
  BlockcallRow(row: any): void {
    // Add logic for note action
    console.log('Blockcall row:', row);
  }
}

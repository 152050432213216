import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UsermanagementService } from '../usermanagement.service';

@Component({
  selector: 'app-combined-users',
  templateUrl: './combined-users.component.html',
  styleUrls: ['./combined-users.component.css']
})
export class CombinedUsersComponent implements OnInit {
  userList: any = new Array();
  keyword: string = "username";
  @Input() userLabel: string
  @Input() userValue: string
  @Input() userProfileId: string;
  @Output() userEmitter: any = new EventEmitter();
  userData: any;

  constructor(
    private userManagementService: UsermanagementService
  ) { }

  ngOnInit() {
    const qParams = [
      { key: 'user_profile_id', value: this.userProfileId, },
      { key: 'keyword', value: "" }
    ]
    this.userManagementService.getCombinedUsers(qParams).subscribe(
      (res: any) => {
        this.userList = res.data
      }
    ) 
  }

  selectUser(item) {
    this.userEmitter.emit(item.auth_user_id)
  }

  onFocusedUser() {
    const qParams = [
      { key: 'user_profile_id', value: this.userProfileId, },
      { key: 'keyword', value: "" }
    ]
    this.userManagementService.getCombinedUsers(qParams).subscribe(
      (res: any) => {
        this.userList = res.data
      }
    )
  }


  onChangeSearchUser(val: string) {
    const qParams = [
      { key: 'user_profile_id', value: this.userProfileId, },
      { key: 'keyword', value: val }
    ]
    this.userManagementService.getCombinedUsers(qParams).subscribe(
      (res: any) => {              
        this.userList = res.data
      }
    )
  }

  onClearUser() {
    this.userEmitter.emit(0)
  }

}




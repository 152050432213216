import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-sms-credit',
  templateUrl: './add-sms-credit.component.html',
  styleUrls: ['./add-sms-credit.component.css']
})
export class AddSmsCreditComponent implements OnInit {
  companyList: string | undefined;
  companies: any;
  DidList: any;
  didnumbers: any;
  planName: any;
  disableSelect = true;
  credit: any;
  ids: any;



  constructor(
    private usermanagementservice: UsermanagementService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.ids = params['id'];
      if (this.ids == 'new') {
        this.loadCompany()
      }
      else {
        this.loadDID()
      }
    });

  }
  loadCompany() {
    this.usermanagementservice.CustomerComapnyList().subscribe(
      (data: any) => {
        this.companies = data
      }
    )
  }
  loadDID() {
    var datas = {
      'didnumber': this.ids
    }
    this.usermanagementservice.smsdidlist(datas).subscribe(
      (data: any) => {
        console.log("*****************",data,"****************")
        this.companyList = data['company']
        this.didnumbers = data['didNumber']
        this.planName = data['plan']
        if (this.planName !== '1') {
          this.disableSelect = false;
          this.credit = data['credit']
        }

      }
    )
  }
  getcompanycredit() {
    this.usermanagementservice.getExistingDidNumber(this.companyList).subscribe(
      (data: any) => {
        this.DidList = data.data

      }
    )
  }
  // getplanName() {
  //   if (this.planName == 1) {
  //     this.disableSelect = true;
  //     this.credit=0;
  //   }
  //   else {
  //     this.disableSelect = false;
  //   }
  // }
  AddPlan() {
    if (this.companyList == undefined){
      Swal.fire('Oops..!', 'please fill details', 'warning')
    }
    else {
      var data = {
        'company': this.companyList,
        'credit': this.credit
      }
      this.usermanagementservice.addSmsPlanDid(data).subscribe(
        (data: any) => {
          if (data['status'] == 1) {
            Swal.fire('Created', 'Created Plan', 'success')
            this.companyList = '';
            this.credit = '';
          }
          else {
            Swal.fire('Oops..!', data['message'], 'warning')
          }

        }
      )
    }

  }
  UpdatePlan(){
    if (this.companyList == undefined) {
      Swal.fire('Oops..!', 'please fill details', 'warning')
    }
    else {
      var data = {
        'company': this.didnumbers,
        'credit': this.credit
      }
      this.usermanagementservice.updateSmsPlanDid(data).subscribe(
        (data: any) => {
          if (data['status'] == 1) {
            Swal.fire('Updated', 'Plan Updated', 'success')
          }
          else {
            Swal.fire('Oops..!', data['message'], 'warning')
          }

        }
      )
    }

  }

}

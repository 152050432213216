import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { DatePipe } from '@angular/common';
import { ReportService } from '../inbound-report/report.service'
import { FormGroup , FormControl} from '@angular/forms';
import * as moment from 'moment';
import {ExcelService} from 'src/app/inbound-report/excel.service';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-vb-report',
  templateUrl: './vb-report.component.html',
  styleUrls: ['./vb-report.component.css'],
  providers: [DatePipe]
})
export class VbReportComponent implements OnInit {
  userData: any;
  usrId: any;
  reportForm: FormGroup;
  permissions: any;
  permis: boolean;
  myDate = new Date();
  today = new Date();
  tomorrow =  new Date(this.today.setDate(this.today.getDate() + 1));
  tdy =  new Date(this.myDate.setDate(this.myDate.getDate()));
  filteredVoiceData: any;
  voiceRecordData: any;
  newarray: any = new Array();
  count: any;
  head: string[][];
  userType:any


  constructor(
    private loginservice: LoginService,
    private datePipe: DatePipe,
    private reportservice: ReportService,
    private excelService:ExcelService,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.usrId = this.userData.id
    this.userType=localStorage.getItem('userType')
    this.reportForm = new FormGroup({
      toDate: new FormControl(''),
      fromDate: new FormControl(''),
      user: new FormControl(0),
    })
    
    this.permissions = this.userData.permissions
    console.log(this.permissions)
    for (let element of this.permissions) {
     
      if(element.codename == "cadmin_q_VBReport"){
        this.permis = true;
    }
  }

  var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
  var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
  console.log("ffff",from)
  console.log("tooo",to)
  this.reportservice.vbReport(this.userData.id, from, to,this.userType).subscribe(
    (res:any) => {
      console.log("res",res["data"]);
      this.voiceRecordData = this.filteredVoiceData = res["data"];
      
    }
  )}

  SearchVBReport(){
    if(this.reportForm.invalid){
      return;
    }else{
      var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      console.log(moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("MM-DD-YYYY"), "yyyy", this.reportForm.value)
      this.reportservice.vbReport(this.userData.id, from, to,this.userType).subscribe(
        (res:any) => {
          this.voiceRecordData = this.filteredVoiceData = res["data"];
          console.log(res);
        }
    )
    }
  }


  exportAsXLSX():void {
    var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    if(from == undefined){
      var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
    }
    for (let element of this.voiceRecordData) {
      this.newarray.push({'Broad CastName':element['broadCastName'],'Phone Number':element['phoneNumber'],
      'DID':element['callerId'],'Connected Duration':element['connectedDuration'],'StartTime':element['legAstartTime'],
      'Endtime':element['callEndTime'],'Call Status':element['callStatus']})
    }
     this.excelService.exportAsExcelFile(this.newarray, "Inbound Report from: "+from+" To: "+to+"");
     this.newarray=[];
 }


 downloads() {
  var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  if(from == undefined){
    var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
    var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
  }
  this.count = 1   
  for (let element of this.voiceRecordData) {
        
        this.newarray.push([this.count,element['broadCastName'],element['phoneNumber'],element['callerId'],
        element['connectedDuration'],element['legAstartTime'],element['callEndTime'],
        element['callStatus']])
        this.count = this.count + 1
      }
      this.head = [['SlNo','BroadCast Name','Phone Number','DID','Connected Duration','Start Time','EndTime', 'CallStatus']]

      console.log(this.newarray)
      var doc = new jsPDF();

      
      doc.text('VB Report', 11, 8);
      doc.setFont("helvetica");
      doc.setFontType("bold");
      doc.setFontSize(3);
  
  
      (doc as any).autoTable({
        head: this.head,
        body: this.newarray,
        theme: 'grid',
        styles: {fontSize: 6},
        tableWidth: 'auto',
        columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 10 },
       
      })
  
      // Open PDF document in new tab
      doc.output('dataurlnewwindow')
  
      // Download PDF document  
      doc.save('vbReport.pdf');
    
    

  }
  // searchText(id: any, from: string, to: string, current_page: (id: any, from: string, to: string, current_page: any, searchText: any, arg5: string) => void, searchText: any, arg5: string) {
  //   throw new Error('Method not implemented.');
  // }
  // current_page(id: any, from: string, to: string, current_page: any, searchText: any, arg5: string) {
  //   throw new Error('Method not implemented.');
  // }

}

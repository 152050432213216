import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class AudioReportService{

    constructor(
        private http: HttpClient,
        public sharedService: SharedService,
    ){}

    API_URL = this.sharedService.getGlobalVar()
    


    getArchiveList(user_id: number, page_no: number, searchText: string){

        const header = new HttpHeaders()
        header.append('Content-Type','application/json')
        return this.http.get(this.API_URL + 'audioReport/?id=' + user_id + '&page=' + page_no + '&label=' + searchText, {headers: header})

    }

    addAudioArchive(data: any){
        const header = new HttpHeaders
        header.append('Contect-Type', 'application/json')
        const body = {

            "from_date": data.from_date,
            "to_date": data.to_date,
            "user": data.user_id
        }

        return this.http.post(this.API_URL + 'audioReport/', data, {headers: header})
    }
    updateAudioArchive(data: any){
        const header = new HttpHeaders
        header.append('Contect-Type', 'application/json')
        const body = {
            "processing_status" : false,
            "id": data.id,
        }

        return this.http.put(this.API_URL + 'audioReport/' + data.id + '/', body, {headers: header})
    }

    getZipFile(id: number){
        const header = new HttpHeaders()
        header.append('Content-Type','application/json')
        return this.http.get(this.API_URL + 'audiozip/' + id + '/', {
            headers: header,
            responseType: "blob",
            reportProgress: true,
            observe: "events",
        })
    }


    getZipFilePath(id: number): Observable<string> {
        return this.http.get<{ file_path: string }>(this.API_URL + 'audiozip-path/' + id + '/').pipe(
            map(response => this.API_URL + response.file_path  )
        );
    }



    updateDownloadStatus(id: number){
        const header = new HttpHeaders()
        header.append('Content-Type','application/json')
        const body = {}
        return this.http.post(this.API_URL + 'audiozip/' + id + '/',body, {headers: header})
    }

}

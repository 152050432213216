import { Component, OnInit ,Input, EventEmitter,Output} from '@angular/core';
import { ReportService } from "../../inbound-report/report.service";
import { NotificationService } from "src/app/notification/notification.service";
import { API_URL } from 'src/global';
import { LoginService } from 'src/app/login.service';


@Component({
  selector: 'app-audiocomp',
  templateUrl: './audiocomp.component.html',
  styleUrls: ['./audiocomp.component.css']
})
export class AudiocompComponent implements OnInit {

  @Input() public id:any = null;
  @Input() public date:any;
  @Input() public callrecord:any;
  @Input() public call_type: any = '';
  @Input() fromDate: string;


  @Input() public daysWithin: number;
  public userData: any;


  public audiocomp: boolean = true;

  filteredCallRecords: any[] = [];



  api: string = API_URL;

  constructor(
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private loginservice: LoginService,


  ) { }



  ngOnInit() {
    console.log('AudiocompComponent initialized');
    this.userData = this.loginservice.getUserdata(false);

    const callRecordSettingType = this.userData.call_record_setting_type;
    const daysWithin = this.userData.days_within;

    if (callRecordSettingType === 'all') {
      this.audiocomp = true;
      return;
    }

    if (callRecordSettingType === 'days_within' && daysWithin > 0) {
      const today = new Date();

      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - daysWithin); 

      console.log(`Valid date range for display (last ${daysWithin} days): from ${pastDate.toISOString()} to ${today.toISOString()}`);

      const audioFileDate = new Date(this.date);

      if (isNaN(audioFileDate.getTime())) {
        console.error("Invalid audio file date:", this.date);
        this.audiocomp = false;
        return;
      }

      
      const isWithinRange = (audioFileDate >= pastDate) && (audioFileDate <= today);
      this.audiocomp = isWithinRange;
      console.log("Audio file date (midnight):", audioFileDate.toISOString());
      console.log("Is audio file within date range?", this.audiocomp);
    } else {
      
      this.audiocomp = false;
      console.log("Invalid setting type or daysWithin value.");
    }
  }



  getcallrecord(vffile, date, call_id: any = null, file_type = "") {
 
    this.reportservice.getcallrecord(vffile, date,call_id, file_type).subscribe(
      (res: any) => {

          let voice_url = this.api + "uploads/downloadedWaveFile/" + vffile.replace(".gsm", ".wav");
          var html_con = '<audio id="audio1" preload controls style="width: 270px; height: 50px; max-width: none; margin: 10px;">' + "  <source src=" + voice_url + " </audio>";
          document.getElementById("audio_" + vffile).innerHTML = html_con;

        this.notifyService.sendNotification(
          "Downloaded",
          "Download Voice successfully",
          "is-success"
        )
      }
    )
  }

  isAudioFileAvailable(url: string){
    console.log("url of audio file: ", url)  
  }

  audioFileErrors: boolean[] = []
  downloadEnabled: boolean[] = []

  handleAudioError(audioFileName: string) {
    this.audioFileErrors[audioFileName] = true
    this.downloadEnabled[audioFileName] = true
  }

  disableVoiceDownload(audioFileName: string){
    this.downloadEnabled[audioFileName] = false
  }

}

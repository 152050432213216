import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from "../../usermanagement.service";
import { FormGroup , FormControl} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-time-duration',
  templateUrl: './time-duration.component.html',
  styleUrls: ['./time-duration.component.css']
})
export class TimeDurationComponent implements OnInit {
  companies: any;
  data: any;
  companyList: any;
  credit :any;
  comp:any;
  existing_credit: any;
  permissions:any;
  permis = false;
  userData:any;
  reportForm:FormGroup;
  timeData: any;
  date1:any;

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions

    this.reportForm = new FormGroup({
      toDate: new FormControl(''),
      fromDate: new FormControl(''),
      companyList: new FormControl(''),
      user: new FormControl(0),
      
     
    });
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_t_timeDuration"){
        this.permis = true;
    }
  }
    this.usermanagementservice.getCompanyList().subscribe(
      (data:any) =>{
        console.log("tmep listss",data);
        this.companies = data
      }
     )
  }

  totalDuration(){
    if(this.reportForm.invalid){
      return;
    }else{
      var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      var companyList = this.reportForm.value.companyList
      var body = {
        "from" : from,
        "to" : to,
        "company" : companyList
      }
      console.log(moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("MM-DD-YYYY"), "yyyy", this.reportForm.value)
      this.usermanagementservice.timeDurationCalc(body).subscribe(
        (res:any) => {

          console.log(res);
          this.timeData = res["data"];

        }

    )
    }
  }
  }



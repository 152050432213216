import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customercontact',
  templateUrl: './customercontact.component.html',
  styleUrls: ['./customercontact.component.css']
})
export class CustomercontactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

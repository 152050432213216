import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { IvrService } from '../ivr.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';
import { LoginService } from 'src/app/login.service';
import { QueuemanagementService } from 'src/app/queuemanagement.service';

@Component({
	selector: '[app-level-three]',
	templateUrl: './level-three.component.html',
	styleUrls: ['./level-three.component.css']
})
export class LevelThreeComponent implements OnInit {
	public IVRThreeForm: FormGroup;
	public voiceFileData:any;
	voiceLabel: string = 'Voice File';
	@Input() optionLabel:string;
	@Input() levelTwoId:number;
	@Input() optionValue:any;
	@Output() parentEmitter3 = new EventEmitter<any>();
	LevelThreeId:number = 0
	levelFourData:any;
	LevelNum:number = 0;
	parentLevel:any;
	@Input() optionNumber:any;
	optionNumUpdate:number;
	voicelist:any = new Array();
	voiceFileThreeInitial:any;
	destinationList:any = new Array();
	destinationInitial:any;
	keyword:string = 'name';
	@Input() userID:number;

	constructor(
		private ivrservice: IvrService,
		private notifyservice: NotificationService,
		private voicerecodrservice: VoiceRecordService,
		private loginservice: LoginService,
		private queueservice: QueuemanagementService

	) { }

	ngOnInit() {
		this.IVRThreeForm = new FormGroup({
			number: new FormControl('', Validators.required),
			ivrlevel: new FormControl(0),
			voicefile: new FormControl(''),
			Destination: new FormControl(''),
			enablepreviousmenu:new FormControl(0)

		})
		if (this.optionValue) {
			this.voicerecodrservice.getVoiceRecordList(this.userID,0).subscribe(
				(data:any) =>{
					this.voicelist = data
					for (let voiceData of this.voicelist){
						if(voiceData.fileUrl == this.optionValue.voicefile) {
							this.voiceFileThreeInitial = voiceData.name
						}
					}
				}
			)
			this.IVRThreeForm.setValue({
				number: this.optionValue.number,
				ivrlevel: this.optionValue.ivrlevel,
				voicefile: this.optionValue.voicefile,
				Destination: this.optionValue.Destination,
				enablepreviousmenu:this.optionValue.enablepreviousmenu

			})
			this.destinationInitial = this.optionValue.Destination
			this.LevelThreeId = this.optionValue.id
			this.optionNumUpdate = this.optionValue.number
		}
		if(this.levelTwoId){
			this.ivrservice.getLevelTwoOptions(this.levelTwoId).subscribe(
				(data:any) => {
					if(data.id) {
						this.parentLevel = data
					}
				}
			)
		}
	}

	voiceFilePath(data) {
		this.voiceFileData = data
	}

	// Department Autocompleted Functions

	selectQueue(item) {
		this.IVRThreeForm.value.Destination = item.name
	}

	onChangeSearchQueue(val: string) {
		this.queueservice.searchQueue(val ,this.userID).subscribe(
			(data:any) =>{
				this.destinationList = data
			}
		)
	}

	onFocusedQueue(e){
		this.queueservice.getQueues(this.userID).subscribe(
			(data:any) =>{
				this.destinationList = data
			}
		)
	}

	onClearDestination() {
		this.IVRThreeForm.value.Destination = ''
	}

	get f() { return this.IVRThreeForm.controls; }

	onAddLevelThree() {
		if(this.voiceFileData != undefined &&this.IVRThreeForm.value.voicefile != this.voiceFileData){
			this.IVRThreeForm.value.voicefile = this.voiceFileData
		}
		if(this.IVRThreeForm.value.voicefile != '' && this.IVRThreeForm.value.Destination != '' && this.IVRThreeForm.value.enablepreviousmenu){
			Swal.fire('Oops..!', 'Voice file, Department and Enable Previous Menu are not allowed simultaneosly', 'error')
		}else if(this.IVRThreeForm.value.voicefile == '' && this.IVRThreeForm.value.Destination == '' && this.IVRThreeForm.value.enablepreviousmenu == false){
			
			Swal.fire('Oops..!', 'Please Select Voice file,Department or Previous Menu', 'error')
		}else if(this.IVRThreeForm.value.voicefile == null && this.IVRThreeForm.value.Destination == '' && this.IVRThreeForm.value.enablepreviousmenu == false){
			Swal.fire('Oops..!', 'Please Select Voice file,Department or Previous Menu', 'error')
		}else{
			if (this.LevelThreeId != 0){
				if (this.optionNumUpdate != this.IVRThreeForm.value.number){
					if(this.optionNumber.indexOf(parseInt(this.IVRThreeForm.value.number)) != -1){
						Swal.fire('Duplicate', 'Already Exists Option Number', 'warning')
					}else{
						this.IVRThreeForm.value.ivrlevel = this.levelTwoId
						this.IVRThreeForm.value.voicefile = this.voiceFileData
						this.ivrservice.UpdateLevelThree(this.LevelThreeId, this.IVRThreeForm.value).subscribe(
							(res:any) => {
								if(res && res['id']){
									this.optionNumber.push(res.number)
									this.optionNumber.splice(this.optionNumber.indexOf(this.optionNumUpdate), 1)
									this.optionNumUpdate = res.number
									this.notifyservice.sendNotification('Updated', 'Level 3 option update successfully', 'is-success')
								}
							}
						)
					}
				}else{
					this.IVRThreeForm.value.ivrlevel = this.levelTwoId
					this.IVRThreeForm.value.voicefile = this.voiceFileData
					this.ivrservice.UpdateLevelThree(this.LevelThreeId, this.IVRThreeForm.value).subscribe(
						(res:any) => {
							if(res && res['id']){
								this.notifyservice.sendNotification('Updated', 'Level 3 option update successfully', 'is-success')
							}
						}
					)
				}
			}else {
				if(this.optionNumber.indexOf(parseInt(this.IVRThreeForm.value.number)) != -1){
					Swal.fire('Duplicate', 'Already Exists Option Number', 'warning')
				}else{
					if (this.IVRThreeForm.invalid) {
						return;
					}else{
						this.IVRThreeForm.value.ivrlevel = this.levelTwoId
						this.IVRThreeForm.value.voicefile = this.voiceFileData
						this.ivrservice.AddLevelThree(this.IVRThreeForm.value).subscribe(
							(res:any) => {
								if(res && res['id']){
									this.LevelThreeId = res['id']
									this.optionNumber.push(res.number)
									this.optionNumUpdate = res.number
									this.notifyservice.sendNotification('Created', 'Level 3 option create successfully', 'is-success')
									this.parentLevel['sublevels'] = this.parentLevel['sublevels'] + 1
									this.ivrservice.UpdateLevelTwo(this.levelTwoId, this.parentLevel).subscribe(
										(res:any) => {
										})
								}
							}
						)
					}
				}
			}
		}
	}

	onDeleteLevelThree(id) {
		Swal.fire({
			title: 'Are you sure ?',
			text: 'Level 3 Option Deleted',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete it!',
			cancelButtonText: 'No, Cancel it!'
		}).then((result) => {
			if (result.value) {
				this.ivrservice.DeleteLevelThreeOptions(id).subscribe(
					(res:any) => {
						this.notifyservice.sendNotification('Deleted', 'Level 2 option delete successfully', 'is-success')
						this.ivrservice.getLevelTwoOptions(this.levelTwoId).subscribe(
							(data:any) => {
								if(data.id) {
									this.parentLevel = data
									this.parentLevel.sublevels = this.parentLevel.sublevels - 1
									this.ivrservice.UpdateLevelTwo(this.levelTwoId, this.parentLevel).subscribe(
										(res:any) => {
											this.parentEmitter3.emit(res);
										}
									)
								}
							}
						)
					}
				)
			}else{
				this.notifyservice.sendNotification('Deleted', 'Level 3 option delete has been cancelled', 'is-danger')
			}
		});
	}
}

import { Component, OnInit } from "@angular/core";
import { AddPushNotificationService } from "../add-pushnotification/add-pushnotification.service";
import { Router } from "@angular/router";
import { LoginService } from "src/app/login.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-pushnotification-history",
  templateUrl: "./pushnotification-history.component.html",
  styleUrls: ["./pushnotification-history.component.css"],
})
export class PushnotificationHistoryComponent implements OnInit {
  NotificationData: any;
  isAdmin: string;
  userData: any;
  permissions: any;

  use_case: number;
  device_type: number;
  company: any;
  user_name: any;
  date: any;
  title: any;
  is_deleted: any;

  formattedDate: string;


  USE_CASES = [
    { id: 1, name: 'Credit Warning' },
    { id: 2, name: 'Call Status Notification' },
    { id: 3, name: 'Follow up Notification' },
    { id: 4, name: 'Account Status Notification' }
  ];

  TYPE_CHOICES = [
    { id: 1, name: 'Android' },
    { id: 2, name: 'iOS' },
    { id: 3, name: 'Web' }
  ];



  total_page: any;
  last: number;
  current_page: number = 1;

  showModal: boolean = false;
  selectedNotification: any;



  constructor(
    private addpushnotificationservice: AddPushNotificationService,
    private router: Router,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem("isAdmin");
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions;

    this.fetchData(1)


  }

  fetchData(page) {

    const filterdata = {
      'use_case': this.use_case,
      'device_type': this.device_type,
      'company': this.company,
      'user_name': this.user_name,
      'title': this.title,
      'date': this.date,
      'is_deleted': this.is_deleted
    }

    console.log("fildata",'filterdata')


    this.addpushnotificationservice
      .getNotificationHistory(filterdata, page)
      .subscribe((data: any) => {
        this.NotificationData = data["results"];
        this.total_page = data["total_pages"];
        this.current_page = data["current_page"];
        this.last = data["total_pages"];

        console.log("data", this.NotificationData);
      });
  }


  fetchNumber(i) {
    if (i == "prev") {
      if (this.current_page > 1) {
        this.fetchData(this.current_page - 1);
        console.log("")
      }
    } else if (i == "next") {
      console.log("next=====")
      if (this.current_page < this.last) {
        this.fetchData(this.current_page + 1);
        console.log("n-------------", this.fetchData(this.current_page + 1))
      }
    } else if (i == "first") {
      this.fetchData(1);
    } else if (i == "last") {
      this.fetchData(this.last);
    } else {
      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.fetchData(i);
      } else {
        Swal.fire("Oops..!", "Enter Valid Number", "warning");
      }
    }
  }

  openModal(notification: any) {
    this.selectedNotification = notification;
    this.showModal = true;
  }


  closeModal() {
    this.showModal = false;
    // this.selectedNotification = null;
  }

}




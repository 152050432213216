import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { LoginService } from "../login.service";
import { UsermanagementService } from "../usermanagement.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-superadmin-autocall",
  templateUrl: "./superadmin-autocall.component.html",
  styleUrls: ["./superadmin-autocall.component.css"],
})
export class SuperadminAutocallComponent implements OnInit {
  search_text: string = "";
  companyList:any = new Array();
  company:string;
  agent_number: string;
  customer_number: string;
  did: string;
  didList:any = new Array();

  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private route: Router
  ) {}

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers(){
    this.loginservice.getCustomerList().subscribe((res:any) => {
      console.log("customer list : ", res);
      this.companyList = res;
    });
  }

  handleChangeCompany(){
    console.log("<<<<<<<<<",this.company,">>>>>>>>>")
    this.usermanagementservice.getDIDs(this.company).subscribe((res:any) => {
      this.didList = res;
      console.log("<<<<<<<",res,"DID")
    });
  }


  handleSubmit() {
    console.log("Agent Num", this.agent_number);
    console.log("Customer Num", this.customer_number);
    console.log("Did", this.did);
    const body = {
      company: this.company,
      agent_no: this.agent_number,
      did: this.did,
      customer_no: this.customer_number,
    };

    this.usermanagementservice.DemoAutoCall(body).subscribe((res: any) => {
      if (res.message == 1) {
        Swal.fire("Success", "AutoCall Initiated");
      } else if (res.message == 2) {
        Swal.fire("Error", "Error");
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { QueuemanagementService } from "../../queuemanagement.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login.service';
import { IvrService } from '../ivr.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';
import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';

import Swal from 'sweetalert2';

@Component({
	selector: '[app-ivr-detail]',
	templateUrl: './ivr-detail.component.html',
	styleUrls: ['./ivr-detail.component.css']
})

export class IvrDetailComponent implements OnInit {
  userToggle: boolean = false;
  userMgmtOver: boolean = false;
  ivrId:any = 0
  IVRForm: FormGroup;
  voiceFileLabel:string = 'Voice File';
  voiceFileData:string = '';
  userData:any;
  keyword:string = 'name';
  LevelOneCount:any = new Array();
  LevelOneData:any;
  optionValue:any;
  LevelNum:number = 0
  optionNum:any = new Array();
  voiceFileInitial:any;
  TimeoutDestinationVoiceFileInital:any;
  voicelist:any = new Array();
  isAdmin:string = 'false'
  UserProfileId:number = 0;
  customerInitial:any;
  customerList:any = new Array();
  isEdit:boolean = true;
  timeoutDestinationNameInitial:any;
  timeoutDestinationName:any;
  timeoutDestinationType:any;
  destinationList:any;
  enableTimeoutDestination:any;
  timeoutDestinationNameInitail:any;


  constructor(
    private loginservice: LoginService,
    private ivrservice: IvrService,
    private activeroute: ActivatedRoute,
    private notifyservice: NotificationService,
    private voicerecodrservice: VoiceRecordService,
    private queueservice:QueuemanagementService
	) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginservice.getUserdata(false);
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
    this.ivrId = +this.activeroute.snapshot.paramMap.get('id');
    this.IVRForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern('[^\\s]*')]),
      description: new FormControl(''),
      welcomemusicCount:new FormControl(''),
      timeoutSec:new FormControl(''),
      invalidtries:new FormControl(''),
      levels: new FormControl(0),
      voiceFile: new FormControl(''),
      user: new FormControl(''),
      enableTimeoutDestination: new FormControl(),
      timeoutDestinationType:new FormControl(''),
      timeoutDestinationName:new FormControl(''),
    })

    this.IVRForm.get('enableTimeoutDestination').valueChanges.subscribe((enabled: boolean) => {
      if (!enabled) {
        // Reset values when unchecked
        this.IVRForm.get('timeoutDestinationType').setValue(''); // Reset to empty
        this.IVRForm.get('timeoutDestinationName').setValue(''); // Reset to empty for name
      }
    });
    
    if(this.ivrId != 0) {
      this.isEdit = false;
      this.ivrservice.GetIVR(this.ivrId).subscribe(
        (res:any) => {
          if(res && res['id']) {
            this.UserProfileId = res.user
            this.loginservice.getCustomerList().subscribe(
              (data:any) => {
                this.customerList = data
                for (let customerData of this.customerList){
                  if(customerData.id == res.user) {
                    this.customerInitial = customerData.name
                  }
                }
              }
            )

            this.voicerecodrservice.getVoiceRecordList(res.user,0).subscribe(
              (data:any) =>{
                this.voicelist = data
                for (let voiceData of this.voicelist){
                  if(voiceData.fileUrl == res.voiceFile) {
                    this.voiceFileInitial = voiceData.name
                    this.voiceFileData = res.voiceFile
                  }

                  if(voiceData.fileUrl == res.timeoutDestinationName && res.timeoutDestinationType == "2"){
                    this.TimeoutDestinationVoiceFileInital = voiceData.name
                  }
                }
              }
            )

            this.IVRForm.setValue({
              name : res.name,
              description: res.description,
              levels: res.levels,
              voiceFile: res.voiceFile,
              welcomemusicCount:res.welcomemusicCount,
              timeoutSec:res.timeoutSec,
              invalidtries:res. invalidtries,

              user: res.user,
              enableTimeoutDestination:res.enableTimeoutDestination,
              timeoutDestinationType:res.timeoutDestinationType,
              timeoutDestinationName:res.timeoutDestinationName
            })

            this.timeoutDestinationType = res.timeoutDestinationType
            this.timeoutDestinationNameInitail=res.timeoutDestinationName
            this.ivrId = res.id
            this.LevelNum = res.levels
            console.log(this.ivrId)
            this.ivrservice.getLevelOneOptionsList(this.ivrId).subscribe(
              (data:any) => {
                this.LevelOneData = data
                this.LevelOneCount = []
                if(this.LevelOneData) {
                  this.optionNum = []
                  for (let i = 0; i < this.LevelNum; i++) {
                    if (this.LevelOneData.length >= i){
                      this.optionValue = this.LevelOneData[i]
                      this.optionNum.push(this.optionValue.number)
                    }else{
                      this.optionValue = undefined
                    }
                    this.LevelOneCount.push({
                      'orderId': 'LevelOne'+ (i+ 1).toString(),
                      'optionLabel': 'OPTION '+ (i+ 1).toString(),
                      'optionValue': this.optionValue
                    })
                  }
                }else {
                  for (let i = 0; i < this.LevelNum; i++) {
                    this.LevelOneCount.push({
                      'orderId': 'LevelOne'+ (i+ 1).toString(),
                      'optionLabel': 'OPTION '+ (i+ 1).toString(),
                      'optionValue': undefined
                    })
                  }
                }
              }
            )
          }
        }
      )
    }

	}


  selectQueue(item) {
		this.timeoutDestinationName = item.name
	  }

	
	  // onChangeSearchQueue(val: string) {
	  //   if(this.timeoutDestinationType == "1"){
		// 	this.userData = this.loginservice.getUserdata(true);
		// 	this.queueservice.searchQueue(val ,this.userData.id).subscribe(
		// 	(data:any) =>{
		// 	  this.destinationList = data
		// 	}
		//   )
		// }
  // }
  
  onChangeSearchQueue(val: string) {
    // Only perform the search if timeoutDestinationType is "1"
    if (this.IVRForm.get('timeoutDestinationType').value === "1") {
      const userData = this.loginservice.getUserdata(true);
      this.queueservice.searchQueue(val, userData.id).subscribe(
        (data: any) => {
          this.destinationList = data; // Update the destination list with the fetched data
        },
        (error) => {
          console.log('Error fetching queue data', error); // Handle any errors
        }
      );
    }
  }

	  onFocusedQueue(e){
		if(this.timeoutDestinationType == "1"){
		  this.userData = this.loginservice.getUserdata(true);
		  this.queueservice.getQueues(this.userData.id).subscribe(
			(data:any) =>{
			  this.destinationList = []
			  this.destinationList = data
			}
		  )
		}
	  }


	voiceFilePath(data) {
		this.voiceFileData = data
  }
  
  TimeoutDestinationVoiceFilePath(data){
		this.timeoutDestinationName = data
  }

  // onDestinyType(val:any) {
  //   this.timeoutDestinationType = val.selectedIndex;
  //   this.timeoutDestinationName = ''
  // }


  onDestinyType(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    this.IVRForm.get('timeoutDestinationType').setValue(value);

    // Clear the corresponding name field when type changes
    this.IVRForm.get('timeoutDestinationName').reset();
  }

  onCheckboxChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (!isChecked) {
      // Reset the form fields when the checkbox is unchecked
      this.IVRForm.get('timeoutDestinationName').reset();
      this.IVRForm.get('timeoutDestinationType').reset();
    } else {
      // Set the timeoutDestinationType to an empty string when the checkbox is checked again
      this.IVRForm.get('timeoutDestinationType').setValue('');
    }
  }
  customerEvent(data) {
    this.UserProfileId = data
  }

	get f() { return this.IVRForm.controls; }


  onSubmit() {

    if(this.isAdmin == 'true' && this.UserProfileId == 0) {
        Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    }else{
      this.IVRForm.value.user = this.UserProfileId
    }



    if(this.ivrId != 0){
        this.IVRForm.value.voiceFile = this.voiceFileData
        this.IVRForm.value.timeoutDestinationName = this.timeoutDestinationName
        this.ivrservice.UpdateIVR(this.ivrId, this.IVRForm.value).subscribe(
          (res:any) => {
            if(res && res['id']){
              this.ivrId = res['id']
              this.notifyservice.sendNotification('Updated', 'IVR data update successfully', 'is-success')
            }
          }
        )
    }else {
      if (this.IVRForm.invalid) {
        return;
      }else{
        this.IVRForm.value.voiceFile = this.voiceFileData
        this.ivrservice.AddIVR(this.IVRForm.value).subscribe(
          (res:any) => {
            if(res && res['id']){
              this.isEdit = false;
              this.ivrId = res['id']
              this.LevelNum = res.levels
              this.notifyservice.sendNotification('Created', 'IVR data create successfully', 'is-success')
              this.loginservice.getCustomerList().subscribe(
                (data:any) => {
                  this.customerList = data
                  for (let customerData of this.customerList){
                    if(customerData.id == res.user) {
                      this.customerInitial = customerData.name
                    }
                  }
                }
              )
            }
          }
        )
      }
    }
  }

  onAddOption() {
    this.ivrservice.getLevelOneOptionsList(this.ivrId).subscribe(
      (data:any) => {
        this.LevelOneData = data
        this.LevelNum = this.LevelNum + 1
        this.LevelOneCount = []
        if(this.LevelOneData) {
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelOneData.length >= i){
              this.optionValue = this.LevelOneData[i]
            }else{
              this.optionValue = undefined
            }
            this.LevelOneCount.push({
              'orderId': 'LevelOne'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionValue
            })
          }
        }else {
          for (let i = 0; i < this.LevelNum; i++) {
            this.LevelOneCount.push({
              'orderId': 'LevelOne'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
  }

  parentEmitterData(response) {
    this.ivrservice.getLevelOneOptionsList(response.id).subscribe(
      (data:any) => {
        this.LevelOneData = data
        this.LevelNum = response.levels
        this.LevelOneCount = []
        if(this.LevelOneData) {
          this.optionNum = []
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelOneData.length >= i){
              this.optionValue = this.LevelOneData[i]
              this.optionNum.push(this.optionValue.number)
            }else{
              this.optionValue = undefined
            }
            this.LevelOneCount.push({
              'orderId': 'LevelOne'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionValue
            })
          }
        }else {
          for (let i = 0; i < this.LevelNum; i++) {
            this.LevelOneCount.push({
              'orderId': 'LevelOne'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
  }

	onComplete(id) {
  
  
		this.ivrservice.SendOnComplete({"id":id,"created":this.isEdit}).subscribe(
      
			(data:any) => {
        
				console.log(data)
				Swal.fire('Data send Successfully', 'Check Status in IVR List', 'success');
			}
		)
		
	}

  info(){
    (<HTMLInputElement>document.getElementById('page-modal')).style.display='block';
  }
  close_modal(){
    (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
  }
  toggleContent() {
    this.userToggle = !this.userToggle;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-sendemail]',
  templateUrl: './sendemail.component.html',
  styleUrls: ['./sendemail.component.css']
})
export class SendemailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { LoginService } from "../../login.service";
import { InboundFeedbackService } from '../inbound-feedback.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-numbers-log',
  templateUrl: './add-numbers-log.component.html',
  styleUrls: ['./add-numbers-log.component.css']
})
export class AddNumbersLogComponent implements OnInit {
  permis: boolean;
  permissions: any;
  userData: any;
  UserProfileData: any;
  mobile: any;
  username: any;
  customername: any;
  email: any;
  description: any;
  password: any;
  GROUP: any;
  UserProfileId: any;
  route: any;
  isAdmin: string;
  id: number;
  existedUser: any;

  constructor(
    private loginService: LoginService,
    private inboundFeedbackService:InboundFeedbackService,
    private activeroute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(false);
     this.permissions = this.userData.permissions
      for (let element of this.permissions) {
        console.log(element.codename)
        if(element.codename == "cadmin_a_addUsersLog"){
          this.permis = true;
        }
      }
      this.isAdmin = localStorage.getItem('isAdmin')
      this.UserProfileId = this.userData.id
      
      console.log("rrrrrrrrr",this.UserProfileId)
      this.id = +this.activeroute.snapshot.paramMap.get('id');
  }
  
  onUserprofileBtn() {
    {
         this.UserProfileData = {
           'username':this.username,
           'name':this.customername,
           'email':this.email,
           'description':this.description,         
           'password':this.password,
           'usergroup':"feedbackUser" ,
           'company':this.UserProfileId,
          
           }
         console.log("dataaaaaaa",this.UserProfileData)
         this.inboundFeedbackService.userExistTest(this.username).subscribe( 
          (res:any) => {
            this.existedUser = res;
            console.log("ooooooooooooooooooo",this.existedUser)
          }
         )
        console.log(this.existedUser)
        if(this.existedUser['status'] == 0){
         this.inboundFeedbackService.InboundFeedbackUserCreate(this.UserProfileData).subscribe( 
           (res:any) => {             
               Swal.fire('Created', 'User Profile Successfully Created', 'success')
              
               this.mobile='',
               this.username='',
               this.customername='',
               this.email='',
               this.description='',
               this.password='',
              
               this.route.navigateByUrl('/listUsers/')
             }  
         )
    
        }
        else{
          Swal.fire('Oops..!', 'Please change the mobile number', 'warning')
          
        }
         }
        
     }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { SmsService } from 'src/app/sms/sms.service';
import Swal from 'sweetalert2';
import { LoginService } from "../../login.service";
import { NotificationService } from 'src/app/notification/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sms-list',
  templateUrl: './sms-list.component.html',
  styleUrls: ['./sms-list.component.css']
})
export class SmsListComponent implements OnInit {
  public userData:any;
  public smslist:any;
  public datas:any;
  permissions:any;
  permis = false;

  constructor(
    private smsService: SmsService,
    private loginService: LoginService,
    private notifyservice: NotificationService,
    private router: Router,
  ) {
   }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
    this.permissions = this.userData.permissions
      for (let element of this.permissions) {
        if(element.codename == "cadmin_s_smsList"){
          this.permis = true;
        }
      }
    this.smsService.getSms(this.userData.id).subscribe((data:any) =>{
    console.log(data);
	  this.datas = data;
  }
    )
}

OndeleteSms(id){
  Swal.fire({
    title: 'Are you sure ?',
    text: 'The Sms Assigned data Deleted',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Delete it!',
    cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
    if (result.value) {
      this.smsService.OndeleteSms(id).subscribe(
      (res:any) => {
        console.log('data', res)
        this.notifyservice.sendNotification('Deleted', 'Sms Assigned delete successfully', 'is-success')
        this.smsService.getSms(this.userData.id).subscribe(
          (data:any) => {
            this.datas = data;
          }
        )
      }
      )
    }else{
      this.notifyservice.sendNotification('Cancelled', 'Sms Assigned deleting has been cancelled', 'is-danger')
    }
    })
  }

  OneditSms(id){
    this.router.navigateByUrl('/addSmsDetails/'+id);	
	}
}

import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-list-credit-alert',
  templateUrl: './list-credit-alert.component.html',
  styleUrls: ['./list-credit-alert.component.css']
})
export class ListCreditAlertComponent implements OnInit {
  datas: any;
  public searchText : string;
  permissions:any;
  permis = false; 
  userData:any;
  did:any; 

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) {


  }

  ngOnInit() {
    this.searchText = ''
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_s_creditList"){
        this.permis = true;
    }
  }

  this.loadDid();
  }


  searchBox(){
    console.log(this.searchText)
    this.loadDid();
  }

loadDid(){
  this.usermanagementservice.alertTypeDetails(this.searchText).subscribe(
    (data:any) =>{
      this.datas = data
    } 
   )
}

  deletePlan(username,smsmtype,alertion,){
    if(confirm("Are you sure to delete "+name)) {
      var bodyData={
        'username':username,
        'smstype':smsmtype,
        'alertion':alertion,
      }
      this.usermanagementservice.deleteAlertType(bodyData).subscribe(
        (data:any) => {
          this.datas = data;
          console.log(data);
          this.loadDid();
        }
      )
    }

  }


}

import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { LoginService } from "./login.service";
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { LoggingService } from './logging.service';

import { switchMap, catchError, map, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimer: any;
  private warningTimer: any;
  private logoutTime = environment.inactivity_logoutTime * 60 * 1000; //example 15minutes in - millisesondss
  // private logoutTime = 12000; //example 12 sec in - millisesondss
  constructor(private router: Router, private ngZone: NgZone, private LoginService: LoginService, private loggingService: LoggingService) {
    window.addEventListener('storage', this.handleStorageChange.bind(this));
  }

  startMonitoring() {
    this.resetTimers();
    window.addEventListener('mousemove', this.onUserActivity.bind(this));
    window.addEventListener('keypress', this.onUserActivity.bind(this));
  }

  stopMonitoring(): void {
    this.clearTimers();
    window.removeEventListener('mousemove', this.onUserActivity.bind(this));
    window.removeEventListener('keypress', this.onUserActivity.bind(this));
  }

  private clearTimers(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
  }

  private resetTimers() {
    clearTimeout(this.inactivityTimer);
    clearTimeout(this.warningTimer);
    this.stopMonitoring();

    this.inactivityTimer = setTimeout(() => {
      if (this.isUserLoggedIn()) {
        this.loggingService.logTosessionStorage(`User inactive for ${this.logoutTime / 1000} seconds, logging out.`);
        this.logout();
      }
    }, this.logoutTime);
  }

  private onUserActivity() {
    clearTimeout(this.inactivityTimer);
    this.resetTimers();

    localStorage.setItem('userActive', Date.now().toString());
  }

  private logout() {
    console.log("logout from inactivity!!!!!!!!")
    this.loggingService.logTosessionStorage('User successfully logged out.');

    const userType = localStorage.getItem('userType');
    const userData = this.LoginService.getUserdata(true);
    const fcmToken = localStorage.getItem("fcm_token");
    const group = Array.isArray(userData.group) ? userData.group[0] : userData.group;

    const logoutData = {
      userid: userData.id,
      device_type: 3,
      group: group,
      email: userData.email,
      phone: userData.phone,
      name: userData.name,
      firebase_token: fcmToken,
    };

    const refreshToken = {
      refresh: this.LoginService.getRefreshToken(),
    };

    const logout = { username: userData.name, user_type: userType };

    forkJoin({
      removeFirebase: this.LoginService.removeFirebaseToken(logoutData).pipe(
        catchError((error) => {
          console.error("Error removing Firebase token:", error);
          return of(null);
        })
      ),
      blacklistJWT: this.LoginService.blackListJWTToken(refreshToken).pipe(
        catchError((error) => {
          console.error("Error blacklisting JWT token:", error);
          return of(null);
        })
      ),
    }).subscribe({
      next: ({ removeFirebase, blacklistJWT }) => {
        console.log("Firebase token removed successfully:", removeFirebase);
        console.log("JWT token blacklisted successfully:", blacklistJWT);
        this.LoginService.doLogout(logout);
        console.log("Token Cleared inact-89")
        sessionStorage.setItem('showLogoutAlert', 'true');
        localStorage.setItem('logoutEvent', Date.now().toString());
        window.localStorage.clear();
        this.router.navigate(["/"]);
        window.location.reload();


      },
      error: (error) => {
        this.loggingService.logTosessionStorage(`Error during logout: ${JSON.stringify(error)}`);

        console.error("Error during logout process:", error);
        this.LoginService.doLogout(logout);

        sessionStorage.setItem('showLogoutAlert', 'true');
        localStorage.setItem('logoutEvent', Date.now().toString());
        console.log("Token Cleared inact-104")
        window.localStorage.clear();
        this.router.navigate(["/"]);
        window.location.reload();


      },
    });


  }

  isUserLoggedIn(): boolean {
    let authToken = this.LoginService.getToken();
    const userType = localStorage.getItem('userType');
    return userType && userType !== "virtualUser";
  }

  private handleStorageChange(event: StorageEvent) {
    if (!event.key) return;

    this.loggingService.logTosessionStorage(`Storage event detected: Key - ${event.key}, Value - ${event.newValue}`);

    if (event.key === 'logoutEvent' && event.newValue) {
      this.loggingService.logTosessionStorage('Logout event detected across tabs.');

      console.log("Logout event detected, logging out in this tab.");
      this.logout(); 
    }

    if (event.key === 'userActive' && event.newValue) {
      console.log("User activity detected in another tab, resetting timers.");
      this.loggingService.logTosessionStorage('User activity detected in another tab, resetting timers.');

      this.resetTimers(); 
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InboundRouteService } from '../inbound-route.service';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: '[app-match-pattern-detail]',
  templateUrl: './match-pattern-detail.component.html',
  styleUrls: ['./match-pattern-detail.component.css']
})
export class MatchPatternDetailComponent implements OnInit {
  public MatchPatternForm: FormGroup;
  public id: number;
  private fileToUpload: File = null;
  public uploadData: any;
  userData:any;
  isAdmin:string = 'false'
  UserProfileId:number = 0;
  customerInitial:any;
  customerList:any = new Array();
  isEdit:boolean = true;


  constructor(
    private loginservice: LoginService,
    private matchpatterservice: InboundRouteService,
    private route: Router,
    private activeroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginservice.getUserdata(false);
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    this.MatchPatternForm = new FormGroup({
      number: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      actionDestination: new FormControl(''),
      user: new FormControl(this.userData.id)
    })
    if (this.id != 0) {
      this.isEdit = false;
      this.matchpatterservice.getMatchPattern(this.id).subscribe(
        (res:any) => {
          console.log('return', res)
          this.MatchPatternForm.setValue({
            number: res.number,
            actionDestination: res.actionDestination,
            status: res.status,
            user: res.user,
          });
          this.UserProfileId = res.user
          this.loginservice.getCustomerList().subscribe(
            (data:any) => {
              this.customerList = data
              for (let customerData of this.customerList){
                if(customerData.id == res.user) {
                  this.customerInitial = customerData.name
                }
              }
            }
          )
        }
      )
    }
  }

  customerEvent(data) {
    this.UserProfileId = data
  }

  get f() { return this.MatchPatternForm.controls; }

  onSubmit() {
    if(this.isAdmin == 'true'){
      if(this.UserProfileId == 0) {
        Swal.fire('Oops..!', 'Valid customer Select', 'warning');
      }else{
        this.MatchPatternForm.value.user = this.UserProfileId
        if (this.MatchPatternForm.invalid) {
          return;
        }else{
          this.matchpatterservice.addMatchPattern(this.MatchPatternForm.value).subscribe(
            (res:any) => {
              if(res && res['id']) {
                swal.fire("Created", "Match Pattern Created Successfully", "success")
                this.route.navigateByUrl('/route/matchpattern');
              }else {
                swal.fire('Error Occured!');
              }
            }
          )
        }
      }
    }else{
      this.MatchPatternForm.value.user = this.userData.id
      if (this.MatchPatternForm.invalid) {
        return;
      }else{
        this.matchpatterservice.addMatchPattern(this.MatchPatternForm.value).subscribe(
          (res:any) => {
            if(res && res['id']) {
              swal.fire("Created", "Match Pattern Created Successfully", "success")
              this.route.navigateByUrl('/route/matchpattern');
            }else {
              swal.fire('Error Occured!');
            }
          }
        )
      }
    }
  }

  onUpdate() {
    if(this.isAdmin == 'true' && this.UserProfileId == 0) {
        Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    }else{
      this.MatchPatternForm.value.user = this.UserProfileId
      this.matchpatterservice.updateMatchPattern(this.id, this.MatchPatternForm.value).subscribe(
        (res:any) => {
          if(res && res['id']) {
            swal.fire("Updated", "Match Pattern Updated Successfully", "success")
            this.route.navigateByUrl('/route/matchpattern');
          }else {
            swal.fire('Error Occured!');
          }
        }
      )
    }
    
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onUpload() {
    if (this.fileToUpload == null) {
      swal.fire('Error Occured!', 'Valid File Select', 'warning');
    }else {
      if(this.isAdmin == 'true'){
        if(this.UserProfileId == 0) {
          Swal.fire('Oops..!', 'Valid customer Select', 'warning');
        }else{
          this.MatchPatternForm.value.user = this.UserProfileId
          this.matchpatterservice.uploadMatchPattern(this.fileToUpload).subscribe(
            (data:any) => {
              if (data.status == '1') {
                this.isEdit = false;
                this.uploadData = data.data
                this.loginservice.getCustomerList().subscribe(
                  (data:any) => {
                    this.customerList = data
                    for (let customerData of this.customerList){
                      if(customerData.id == this.UserProfileId) {
                        this.customerInitial = customerData.name
                      }
                    }
                  }
                )
              }else {
                swal.fire('Error Occured!', data.error, 'error');
              }
            }
          )
        }
      }
    }
  }

  onUpdateFile() {
    if (this.fileToUpload == null) {
      swal.fire('Error Occured!', 'Valid File Select', 'warning');
    }else {
      if(this.isAdmin == 'true'){
        if(this.UserProfileId == 0) {
          Swal.fire('Oops..!', 'Valid customer Select', 'warning');
        }else{
          this.matchpatterservice.updateFileMatchPattern(this.fileToUpload).subscribe(
            (data:any) => {
              if (data.status == '1') {
                this.isEdit = false;
                this.uploadData = data.data
                this.loginservice.getCustomerList().subscribe(
                  (data:any) => {
                    this.customerList = data
                    for (let customerData of this.customerList){
                      if(customerData.id == this.UserProfileId) {
                        this.customerInitial = customerData.name
                      }
                    }
                  }
                )
              }else {
                swal.fire('Error Occured!', data.error, 'error');
              }
            }
          )
        }
      }
    }
  }
}

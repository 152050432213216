import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
} from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { LoginService } from './login.service';
import { switchMap, catchError, map, finalize } from 'rxjs/operators';
import { Router } from "@angular/router";
import { forkJoin } from 'rxjs';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private isTokenRefreshing: boolean = false;
  private requestQueue: any[] = [];

  constructor(
    private http: HttpClient,
    private LoginService: LoginService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.LoginService.getToken();
    const refreshToken = this.LoginService.getRefreshToken();

    if (['get_auth_token/', 'api/token/', 'getServer/', 'login/', 'api/logout/', 'api/token/refresh', 'usermanagement/login', 'usermanagement/getAgentUsers'].some(url => request.url.includes(url))) {
      return next.handle(request);
    }
    const userType = localStorage.getItem('userType');
    if (userType === "virtualUser") {
      console.log("Request from virtualUser, no Authorization header added.");
      return next.handle(request);
    }
    if (authToken) {
      request = this.addAuthorizationHeader(request, authToken);
      return next.handle(request).pipe(
        catchError((error: any) => {
          if (error.status === 401 && error.error && error.error.detail === "Token is invalid due to logout.") {
            console.log("Token invalid due to logout. Redirecting to logout page...");
            this.handleExpiredSession();
          }
          return throwError(() => error); 
        })
      );
    }


    // delay before logging out
    if (!authToken && !refreshToken) {
      if (userType === "virtualUser") {
        return next.handle(request);
      } 
      console.log("No auth token or refresh token available. waiting for token...");
      return timer(3000).pipe(
        switchMap(() => {
          const updatedAuthToken = this.LoginService.getToken();  // Recheck token
          if (updatedAuthToken) {
            // retry request with the new token
            request = this.addAuthorizationHeader(request, updatedAuthToken);
            return next.handle(request);
          }
          else {
            console.log("Token still missing after delay, logging out...");
            this.handleExpiredSession();
            return throwError('No auth token or refresh token available after delay');
          }
        })
      );
    }

    if (!authToken && refreshToken && !this.isTokenRefreshing) {
      this.isTokenRefreshing = true;
      return this.LoginService.get_accesstoken_refresh().pipe(
        switchMap((newToken: string) => {
          this.isTokenRefreshing = false;
          this.processQueuedRequests(newToken);

          // retry original request with new token
          request = this.addAuthorizationHeader(request, newToken);
          return next.handle(request);
        }),
        catchError(error => {
          this.isTokenRefreshing = false;
          console.log("Token refresh failed, logging out...");
          this.handleExpiredSession();
          return throwError(error);
        }),
        finalize(() => this.isTokenRefreshing = false)
      );
    }

    // queue the request if a refresh is already in progress
    return new Observable(observer => {
      this.requestQueue.push((newToken: string) => {
        const clonedRequest = this.addAuthorizationHeader(request, newToken);
        next.handle(clonedRequest).subscribe(
          event => observer.next(event),
          error => observer.error(error),
          () => observer.complete()
        );
      });
    });
  }

  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private processQueuedRequests(newToken: string): void {
    this.requestQueue.forEach(callback => callback(newToken));
    this.requestQueue = [];
  }

  private handleExpiredSession(): void {
    const userType = localStorage.getItem('userType');
    if (userType === "virtualUser") {
      console.log("Agent user, skipping logout.");
    } else {
      const userData = this.LoginService.getUserdata(true);
      if (userData) {

        const fcmToken = localStorage.getItem("fcm_token");
        const group = Array.isArray(userData.group)
          ? userData.group[0]
          : userData.group;
  
        const logout = { username: userData.name, user_type: userType };
        // no auth ,refresh token availble to invalidate  firebase, jwt tokens 
     
        console.log("Token Cleared interc-141")
        localStorage.clear();
        this.router.navigate(['/']);
        window.location.reload();
        this.LoginService.doLogout(logout);
      } else { 
        localStorage.clear();
        console.log("Token Cleared interc-147")
          this.router.navigate(['/']);
          window.location.reload();
      }
    }
  }
}

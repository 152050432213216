import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-credit-alert-report',
  templateUrl: './credit-alert-report.component.html',
  styleUrls: ['./credit-alert-report.component.css']
})
export class CreditAlertReportComponent implements OnInit {

  datas: any; 
  public searchText : string;
  public fromdate : any;
  public todate : any;
  permissions:any;
  permis = false;
  userData:any;

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) {


  }

  ngOnInit() {
    this.searchText = ''
    this.fromdate = ''
    this.todate
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      if(element.codename == "cadmin_s_creditList"){
        this.permis = true;         
    }
  }
  console.log("===============================this.logdata========================")
  this.loadDid();
  }


  searchBox(){
    this.loadDid();
  }

loadDid(){
  this.usermanagementservice.creidit_alert_report(this.searchText).subscribe(
    (data:any) =>{
      this.datas = data
    }
   )
}

}
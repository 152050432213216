import { Component, OnInit, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { DIDMgmtService } from 'src/app/didmgmt/didmgmt.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { UsermanagementService } from '../usermanagement/usermanagement.service';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-didmgmt',
  templateUrl: './didmgmt.component.html',
  styleUrls: ['./didmgmt.component.css']
})
export class DidmgmtComponent implements OnInit, AfterViewInit, OnDestroy {
  userData: any;
  datas: any;
  DIDNum: any;
  user: any;
  TempId: number;
  id: number | null = 0;
  name: string;
  companies: any;
  channelId: any;
  ChannelIdArray = new Array();
  DIDPopUp: boolean = false
  DIDList: [] = []
  selectedDID: any
  old_DID_id: any
  autoCallEnabled: boolean = false;
  dataTable: any;
  showDropdown: boolean = false;
  filteredCompanyList: any[] = [];

  didForm: FormGroup;
  showFields: boolean = false;
  selectedOption: string = '';
  showModal: boolean = false;
  showAssignModal: boolean = false;
  dIdNumberFilter: string = '';
  companyList: string = '';
  Status: string = '';
  ChannelIdFilter: string = '';
  location: string = '';
  provider: string = '';
  locationList: string[] = [];
  providerList: string[] = [];
  companyListName: any[] = [];
  DidManagementData: any[] = [];
  autoCallValue: boolean = false;
  selectedDidId: number;
  showEditModal: boolean = false;
  showCallHistoryModal: boolean = false;
  editCompanyList: string = '';
  didCompanyName: string = '';
  didCallHistoryData: any[] = [];
  didStaus: string = '';
  didName: string = '';
  
  selectedCompanyId: number | null = null;



  
  constructor(
    private loginservice: LoginService,
    private didMgmtService: DIDMgmtService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private usermanagementservice: UsermanagementService,
    private fb: FormBuilder
  ) {
    this.didForm = this.fb.group({
      didOption: ['', Validators.required],
      dids: this.fb.array([]),
      didRanges: this.fb.array([]),
      didNumber: [
        '',
        [Validators.required, Validators.pattern(/^\d{1,10}$/)]
      ]
    });
  }

  ngOnInit() {
    this.OnGetLocationAndProviderList();
    this.onGetCompanyNameList();
    this.onGetChannelId();
  }


  ngAfterViewInit(): void {
    this.initializeDatatable();
  }

  ngOnDestroy(): void {
    this.dataTable = null;
  }


  initializeDatatable() {
    if (this.dataTable) {
      return;
    }
    this.dataTable = $('#didTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      paging: true,
      pageLength: 50,
      serverSide: true,
      processing: true,
      searching: false,

      ajax: (dataTablesParameters: any, callback: any) => {
        const page = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        const pageSize = dataTablesParameters.length;

        const DidManagement_Data = {
          "did": this.dIdNumberFilter,
          "company_id": this.selectedCompanyId,
          "status": this.Status,
          "channel_number": this.ChannelIdFilter,
          "location": this.location,
          "provider": this.provider
        }
        this.didMgmtService.DIDManagementList(page, pageSize, DidManagement_Data).subscribe((data: any) => {
          const processedData = data.results.map((item: any, index: number) => {
            const isDisabled = item.is_reserved ? 'disabled style="pointer-events: none; opacity: 0.6;"' : '';
            return {
              ...item,
              slno: (page - 1) * pageSize + index + 1,
              action: `
                <a class="btn-edit" ${isDisabled}><span><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                <a class="btn-delete" ${isDisabled}><span><i class="fa fa-trash-o"></i></span></a>              
                <a class="btn-history" style="margin-right: 10px;"><span><i class="fa fa-history"></i></span></a>
                ${item.company_name
                  ? `
                  <button class="btn btn-sm release-btn" style="background-color:#1abc9c; color: white; padding:4px 8px;" data-id="${item.id}" ${item.is_reserved ? 'disabled' : ''}>
                      <b><i class="fa fa-unlock"></i> RELEASE</b>
                    </button>
                    `
                  : `<button class="btn btn-sm assign-btn" style="background-color:#3498db; color: white; padding:4px 8px;" data-id="${item.id}" ${item.is_reserved ? 'disabled' : ''}>
                      <b><i class="fa fa-lock"></i> ASSIGN</b><span style="margin-left: 13px;"></span>
                    </button>`}
              `
            };
          });
          callback({
            recordsTotal: data.count,
            recordsFiltered: data.count,
            data: processedData
          });
        });
      },
      columns: [
        { data: 'slno', title: 'Sl No', className: 'text-center' },
        { data: 'name', title: 'DID NUMBER', className: 'text-center' },
        { data: 'company_name', title: 'COMPANY NAME', className: 'text-center' },
        { data: 'channel_number', title: 'CHANNEL ID', className: 'text-center' },
        { data: 'location', title: 'LOCATION', className: 'text-center' },
        { data: 'provider', title: 'PROVIDER', className: 'text-center' },
        { data: 'status', title: 'STATUS', className: 'text-center' },
        { data: 'action', title: 'ACTIONS', orderable: false },
      ],
      headerCallback: (thead) => {
        $(thead).find('th').css({
          'background-color': '#6c757d',
          'color': '#ffffff',
          'font-weight': 'bold',
          'text-align': 'center'
        });
      }
    });
    this.attachTableEvents();
  }

  attachTableEvents() {
    $('#didTable tbody').off('click', '.btn-delete').on('click', '.btn-delete', (event: any) => {
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.onDeleteDid(row.id);
      }
    });

    $('#didTable tbody').off('click', '.btn-edit').on('click', '.btn-edit', (event: any) => {
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.OnEditDID(row.id);
        this.toggleEditModal();
        this.selectedDidId = row.id;
      }
    });
    $('#didTable tbody').off('click', '.btn-history').on('click', '.btn-history', (event: any) => {
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.selectedDidId = row.id;
        this.didCallHistory(this.selectedDidId);
        this.toggleCallHistoryModal();
      }
    });
    $('#didTable tbody').off('click', '.assign-btn').on('click', '.assign-btn', (event: any) => {
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.selectedDidId = row.id;
        this.showAssignModal = true
      }
    });

    $('#didTable tbody').off('click', '.release-btn').on('click', '.release-btn', (event: any) => {
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.selectedDidId = row.id;
        this.onReleaseCompanyFromDid();
      }
    });
  }

  filterCompanyList(): void {
    const searchTerm = this.companyList.toLowerCase();

    this.filteredCompanyList = this.companies.filter(company =>
      company.name.toLowerCase().includes(searchTerm)
    );

    this.showDropdown = this.filteredCompanyList.length > 0;
  }

  onCompanySelected(event: any) {
    this.companyList = event.name;
  }

  onFocusedCompany() {
    this.showDropdown = true;
    this.usermanagementservice.CompanyList().subscribe(
      (data: any) => {
        this.companies = data
        this.filteredCompanyList = data
      }
    )
  }

  clearCompanyDatas(): void {
    this.companyList = '';
    this.filteredCompanyList = [];
    this.showDropdown = false;
    this.selectedCompanyId = null;
  }



  selectCompany(company: any): void {
    this.companyList = company.name;
    this.selectedCompanyId = company.id;
    this.filteredCompanyList = [];
    this.showDropdown = false;
  }

  OnEditDID(id: number) {
    this.usermanagementservice.getDid(id).subscribe(
      (data: any) => {
        this.DIDNum = data[0].name
        this.channelId = data[0].channel_id__channel_number
        this.editCompanyList = data[0].user__company__name
        this.autoCallEnabled = data[0].autocall_enabled
      });
  }

  onEditDidTable(id: number) {
    this.user = this.loginservice.getUserdata(true);
    id = this.selectedDidId;

    const onEdit_Data = {
      'name': this.DIDNum,
      'user': this.user.id,
      'autocall_enabled': this.autoCallEnabled,
      'channelId': this.channelId
    }

    this.didMgmtService.EditDid(id, onEdit_Data).subscribe(
      (res: any) => {
        Swal.fire('Updated', 'DID Successfully Updated', 'success')
        this.OnDIDManagementList(1, 50);
        this.toggleEditModal();
      }
    )
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.autocomplete-container')) {
      this.showDropdown = false;
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
    if (!this.showModal) {
      this.resetForm();
    }
  }

  toggleAssignCompanyModal() {
    this.showAssignModal = !this.showAssignModal;
  }

  toggleEditModal() {
    this.showEditModal = !this.showEditModal;
  }


  get dids(): FormArray {
    return this.didForm.get('dids') as FormArray;
  }

  get didRanges(): FormArray {
    return this.didForm.get('didRanges') as FormArray;
  }

  addDID(): void {
    this.dids.push(this.fb.group({
      didNumber: ['', Validators.required],
      channelId: ['', Validators.required]
    }));
  }

  removeDID(index: number): void {
    this.dids.removeAt(index);
  }

  addRange(): void {
    this.didRanges.push(this.fb.group({
      startRange: ['', Validators.required],
      endRange: ['', Validators.required],
      channelId: ['', Validators.required]
    }));
  }

  removeRange(index: number): void {
    this.didRanges.removeAt(index);
  }

  onOptionChange(event: any): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedOption = selectedValue;

    while (this.dids.length !== 0) {
      this.dids.removeAt(0);
    }

    while (this.didRanges.length !== 0) {
      this.didRanges.removeAt(0);
    }

    if (this.selectedOption === 'Add Individual DIDs') {
      this.addDID();
    } else if (this.selectedOption === 'Add By Range') {
      this.addRange();
    }
  }

  OnGetLocationAndProviderList() {
    this.didMgmtService.getLocationAndProviderList().subscribe((res: any) => {
      if (res.status === 1)
        this.locationList = res.data.locations;
      this.providerList = res.data.providers;
    })
  }

  onGetCompanyNameList() {
    this.usermanagementservice.CompanyList().subscribe(
      (data: any) => {
        this.companyListName = data
      }
    )
  }

  onGetChannelId() {
    this.usermanagementservice.getChannelIds().subscribe(
      (data: any) => {
        this.ChannelIdArray = data.sort((a: any, b: any) => a.channel_number - b.channel_number);
      }
    )
  }

  OnDIDManagementList(page: number, pageSize: number) {
    const DidManagement_Data = {
      "did": this.dIdNumberFilter || '',
      "company_id": this.selectedCompanyId || '',
      "status": this.Status || '',
      "channel_number": this.ChannelIdFilter || '',
      "location": this.location || '',
      "provider": this.provider || ''
    }

    this.didMgmtService.DIDManagementList(page, pageSize, DidManagement_Data).subscribe((data: any) => {
      this.DidManagementData = data.results;
      // const tableData = this.DidManagementData.map((item: any, index: number) => {
      //   const isDisabled = item.is_reserved === true ? "class ='disabled' style='pointer-events: none; opacity: 0.5;'" : '';
      //   console.log("disabled value", item.is_reserved)
      //   return {
      //     ...item,
      //     slno: (page - 1) * pageSize + index + 1,
      //     user__company__name: item.user__company__name,
      //     action: `
      //       <button type='button' disabled>Check</button>
      //       <a id="btn-edit" ${isDisabled}><span class=""><i class="fa fa-edit" aria-hidden="true"></i></span></a>
      //       <a id="btn-delete" ${isDisabled}><span class=""><i class="fa fa-trash-o"></i></span></a>

      //     `
      //   };
      // });

      if (this.dataTable) {
        this.dataTable.clear();
        // this.dataTable.rows.add(tableData);
        this.dataTable.draw();
        this.attachTableEvents();
        // this.clearFilters();
      }
    })
  }

  clearFilters() {
    this.dIdNumberFilter = '';
    this.companyList = '';
    this.Status = '';
    this.ChannelIdFilter = '';
    this.location = '';
    this.provider = '';
  }

  submitDidForm() {

    if (!this.selectedOption) {
      Swal.fire('Validation Error', 'Please select an option before submitting.', 'warning');
      return;
    }

    let didSubmitData: any = {
      mode: this.selectedOption === 'Add Individual DIDs' ? 'single' : 'range',
      did_data: []
    }

    if (this.selectedOption == 'Add Individual DIDs') {
      let isInvalid = this.dids.value.some((item: any) => !item.didNumber || !item.channelId);
      if (isInvalid) {
        Swal.fire('Validation Error', 'Please fill all the required fields.', 'warning');
        return;
      }

      didSubmitData.did_data = this.dids.value.map((item: any) => ({
        did: item.didNumber,
        channel_number: item.channelId
      }));
    } else if (this.selectedOption == 'Add By Range') {

      let isInvalid = this.didRanges.value.some((item: any) =>
        !item.startRange || !item.endRange || !item.channelId
      );

      if (isInvalid) {
        Swal.fire('Validation Error', 'Please fill all the required fields.', 'warning');
        return;
      }
      didSubmitData.did_data = this.didRanges.value.map((item: any) => ({
        start_did: item.startRange,
        end_did: item.endRange,
        channel_number: item.channelId
      }));
    }
    this.didMgmtService.createDid(didSubmitData).subscribe((response: any) => {
      if (response.status === 1) {
        let responseMessage = response.message;
        if (response.data && response.data.excluded_dids && response.data.excluded_dids.length > 0) {
          responseMessage += `${response.data.excluded_dids.join(', ')}`
        }
        Swal.fire('Success', responseMessage, 'success')
        this.didForm.reset();
        this.toggleModal();
      } else if (response.status === 2) {
        Swal.fire('Warning', response.message, 'warning')
      } else if (response.status === 0) {
        Swal.fire('Error', response.message, 'error')

      }
    })
  }

  resetForm() {
    this.didForm.reset();
    this.selectedOption = null;
  }

  onAssignCompanyToDid() {

    if (!this.companyList || this.companyList.trim() === '') {
      this.didForm.markAsTouched();
      Swal.fire({
        title: 'Validation Error',
        text: 'Please fill all the required fields!',
        customClass: {
          container: 'swal2-container',
          popup: 'swal2-popup'
        }
      });
      return;
    }

    const assign_companyData = {
      "action": "assign",
      "did_id": this.selectedDidId,
      "company_id": this.selectedCompanyId,
      "autocall_enabled": this.autoCallValue
    }
    this.didMgmtService.ManageDidCompany(assign_companyData).subscribe((res: any) => {
      try {
        if (res.status == 1) {
          Swal.fire('Success', res.message, 'success')
          this.selectedCompanyId = null
          this.toggleAssignCompanyModal();
          this.OnDIDManagementList(1, 50);
        } else if (res.status == 2) {
          Swal.fire('Warning', res.message, 'warning');
        } else if (res.status == 0) {
          Swal.fire('Error', res.message, 'error');
        }
      } catch {
        Swal.fire('Error!', 'Something went wrong while processing the response. Please try again.', 'error');
      }
    });
  }

  onReleaseCompanyFromDid() {
    Swal.fire({
      title: 'Warning',
      html: "All data associated with this DID will be lost. <br> Are you sure you want to release this DID from the assigned company?",
      showCancelButton: true,
      confirmButtonText: 'Yes, Release DID',
      cancelButtonText: 'Cancel',
      cancelButtonColor: 'red'
    }).then((result) => {
      if (result.value) {
        const release_companyData = {
          "action": "release",
          "did_id": this.selectedDidId
        }
        this.didMgmtService.ManageDidCompany(release_companyData).subscribe((res: any) => {
          try {
            if (res.status == 1) {
              Swal.fire('Success', res.message, 'success')
              this.OnDIDManagementList(1, 50)
            } else if (res.status == 2) {
              let errorMessage = res.message;
              if (res.data && res.data.did_dependencies && res.data.did_dependencies.length > 0) {
                errorMessage += `${res.data.did_dependencies.join(', ')}`
              }
              Swal.fire('Warning', errorMessage, 'warning');
            } else if (res.status == 0) {
              Swal.fire('Error', res.message, 'error');
            }
          }
          catch {
            Swal.fire('Error!', 'Something went wrong while processing the response. Please try again.', 'error');
          }

        });
      }
    })
  }

  onDeleteDid(didId: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'All data associated with this DID will be lost. You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.didMgmtService.deleteDid(didId).subscribe((res: any) => {
          try {
            if (res.status === 1) {
              Swal.fire('Deleted!', res.message, 'success');
              this.OnDIDManagementList(1, 50)
            } else if (res.status == 2) {
              Swal.fire('Warning!', res.message, 'warning');
            } else if (res.status == 0) {
              Swal.fire('Error!', res.message, 'error');
            }
          } catch {
            Swal.fire('Error!', 'Something went wrong with the delete request. Please try again.', 'error');
          }
        });
      }
    });
  }


  toggleCallHistoryModal() {
    this.showCallHistoryModal = !this.showCallHistoryModal;
  }

  closeCallHistoryModal(){
    this.toggleCallHistoryModal();
    this.didCompanyName = '';
    this.didName = '';
    this.didStaus = '';
    this.didCallHistoryData = [];

  }
  didCallHistory(id: number) {
    const request_body = {
      "did_id": id
    }
    this.didMgmtService.getDidCallHistory(request_body).subscribe(
      (data: any) => {
        data = data['data']
        console.log(data)
        this.didName = data['did']
        this.didCompanyName = data['company']
        this.didStaus = data['status']
        this.didCallHistoryData = data['call_details']

        this.OnDIDManagementList(1, 50);
        // this.toggleCallHistoryModal();
      });
      // this.OnDIDManagementList(1, 50);
      // this.toggleCallHistoryModal();
  }


}

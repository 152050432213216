import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VoiceRecordService } from '../voice-record/voice-record.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-voice',
  templateUrl: './voice.component.html',
  styleUrls: ['./voice.component.css']
})
export class VoiceComponent implements OnInit {
  voicelist:any = new Array();
  keyword:string = "name";  
  @Input() voiceLabel: string
  @Input() voiceValue: string;
  @Input() voiceInitial:string;
  @Input() userID:number;
  @Output() VoiceFileEmitter:any = new EventEmitter();
  @Input() broadcastID:number=0;
  userData:any;
  isAdmin:string = 'false';

  constructor(
    private loginservice: LoginService,
    private voiceRecordService: VoiceRecordService

  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }


  selectVoice(item) {
    this.VoiceFileEmitter.emit(item.fileUrl)
  }

 
  onChangeSearchVoice(val: string) {
    this.voiceRecordService.getVoiceRecordList(this.userID,this.broadcastID).subscribe(
      (data:any) =>{
        this.voicelist = data
        console.log('search music list', this.voicelist)
      }
    )
  }

  
  onFocusedVoice(e){
    this.voiceRecordService.getVoiceRecordList(this.userData.id,this.broadcastID).subscribe(
      (data:any) =>{
        this.voicelist = data
        console.log('music list', this.voicelist,this.userData.id)
      }
    )
  }

  onClearVoice() {
    this.VoiceFileEmitter.emit('')
  }
}

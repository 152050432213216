import { Component, OnInit } from '@angular/core';
import { AudioReportService } from './audio-report.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { HttpResponse, HttpEvent, HttpEventType } from '@angular/common/http';


@Component({
  selector: 'app-audio-report',
  templateUrl: './audio-report.component.html',
  styleUrls: ['./audio-report.component.css'],
  providers: [DatePipe],
})
export class AudioReportComponent implements OnInit {



  constructor(

    private reportService: AudioReportService,
    private datePipe: DatePipe,

  ) { }

  from_date: Date;
  to_date: Date;

  label: String = "";
  user_data: any;
  audioArchiveList = [];
  page_no: number = 1;
  pagination = {current_page: 1, total_pages: 0};
  searchText: string = "";
  isShownprogressbar: boolean = false;
  progress:number = 0;
  disabledButtons: { [key: number]: boolean } = {};

  ngOnInit() {
    this.user_data = JSON.parse(localStorage.userdata);
    this.getAudioArchives();
    

  }

  getAudioArchives(){
    this.reportService.getArchiveList(this.user_data.id, this.pagination.current_page, this.searchText).
    subscribe((res:any)=>{
      if (res.status == "1"){
        this.audioArchiveList = res.data
        this.pagination["current_page"] = res.current_page
        this.pagination["total_pages"] = res.total_pages
      }     
      
    })
  }


  addAudioArchive(){

    const currentDate = new Date();

    const fromDate = new Date(this.from_date);
    const toDate = new Date(this.to_date);
    
    if (isNaN(fromDate.getTime())) {
      Swal.fire("Alert!", "Please fill all fields", "info")
    } 
    if (isNaN(toDate.getTime())) {
      Swal.fire("Alert!", "Please fill all fields", "info")
    }
    if (fromDate > currentDate || toDate > currentDate) {
      Swal.fire("Error", "Dates in the future are not allowed.", "error");
      return;
    }
    const data = {
      "label": this.label,
      "from_date": this.datePipe.transform(this.from_date, "yyyy-MM-dd"),
      "to_date": this.datePipe.transform(this.to_date, "yyyy-MM-dd"),
      "user": this.user_data.id

    }
    this.reportService.addAudioArchive(data).
    subscribe((res:any)=>{
      if (res.status == "1"){
        this.getAudioArchives();
        Swal.fire("Success","Added Successfully.","success")
        this.label = "";
        this.from_date = null;
        this.to_date = null;

      }
    })

  }

  updateAudioArchive(id: number) {
    
    this.disabledButtons[id] = true;
    const data = {
      "id": id,
    }
    this.reportService.updateAudioArchive(data).
    subscribe((res:any)=>{
      if (res.status == "1"){
        this.getAudioArchives();
        Swal.fire("Success","Updated Successfully.","success")
        this.label = "";
        this.from_date = null;
        this.to_date = null;

      }
    })

  }


  refreshAudioList(){
    this.getAudioArchives();
  }


onDownloadZip(id: number, filename: string) {
  
  this.reportService.getZipFilePath(id).subscribe((filePath: string) => {
    // Open the file in a new tab
    window.open(filePath, '_blank');
  }, (error) => {
    console.error("Error getting file path:", error);
  });
}
 

  // onDownloadZip(id: number, filename: string) {
  //   this.reportService.getZipFile(id).
  //   subscribe((event)=>{
      
  //     switch (event.type) {
  //       case HttpEventType.Sent:
  //         this.isShownprogressbar = true;
  //         console.log("Request has been made!");
  //         break;
  //       case HttpEventType.ResponseHeader:
  //         console.log("Response header has been received!");
  //         break;
  //       case HttpEventType.DownloadProgress:
  //         this.progress = Math.round((event.loaded / event.total) * 100);
  //         console.log(`Uploaded! ${this.progress}%`);
  //         break;
  //       case HttpEventType.Response:
  //         console.log("Download Completed!", event.body);
  //         const blob = new Blob([event.body], { type: 'application/zip' });
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = filename;
  //         a.click();
  //         window.URL.revokeObjectURL(url);

  //         a.click();
  //         setTimeout(() => {
  //           this.progress = 0;
  //           this.isShownprogressbar = false;
  //         }, 1500);
  //     }    
      
  //     if (this.progress === 100 && HttpEventType.Response){
  //       this.reportService.updateDownloadStatus(id).
  //       subscribe((res: any)=>{
          
  //         if(res.status === "1"){
  //           Swal.fire("Success","Download Completed.","success");
  //           this.getAudioArchives();
  //         }
  //         else{
  //           Swal.fire("Alert!", "Download Failed !", "info");
  //         }
  //       })
  //     }
  //   })
  // }


  paginate(event) {
    if (event == "first") {
      this.pagination.current_page = 1;
      this.getAudioArchives()
    }

    if (event == "last") {
      this.pagination.current_page = this.pagination.total_pages;

      this.getAudioArchives()
    }

    if (event == "next" && this.pagination.current_page < this.pagination.total_pages ) {
      this.pagination.current_page += 1;
      this.getAudioArchives()
    }

    if (event == "prev" && this.pagination.current_page != 1) {
      this.pagination.current_page -= 1;
      this.getAudioArchives()
    }
  }

  fetchNumber(num) {
    if (parseInt(num)=== parseInt(num, 10) && num > 0 && num <= this.pagination.total_pages) {
      this.pagination.current_page = parseInt(num);
      this.getAudioArchives()
    }
    else{
      Swal.fire('Oops..!','Enter Valid Number', 'warning');
    }
    
  }

  searchAudioReport(){
    if(this.searchText != ""){
      this.getAudioArchives();
    }
    else{
      Swal.fire('Oops..!','Enter Valid Search Key.', 'warning');
    }

  }

  clearSearch(){
    this.searchText = "";
    this.getAudioArchives();
  }

}

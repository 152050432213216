import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';
import { LoginService } from 'src/app/login.service';
import { DialerService } from 'src/app/dialer/dialer.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { BroadcastService } from "src/app/voicebroadcast/voicebroadcast.service";
import { ExtensionService } from 'src/app/extension/extension.service';

@Component({
  selector: 'app-add-dialer',
  templateUrl: './add-dialer-project.component.html',
  styleUrls: ['./add-dialer-project.component.css']
})
export class AddDialerProjectComponent implements OnInit {
  keyword = 'name';
  keyword1 = 'name';
  dialerFileToUpload: File;
  didList:any;
  subUser:any;
  subUserIds:any;
  category:string = '';
  categories:string = '';
  
  subcat:string;
  subcats:string = '';
  suboption:string='';
  suboptions:string='';
  label:string = '';
  labels:string = '';
  dlabel:string = '';
  dlabels:string = '';
  categ_list:string = '';
  label_list:string='';
  subUserList:any;
  DIDNumber:any;
  DIDNumberInitial:any;
  subUserInitial:string = '';
  UserProfileId:number = 0;
  isAdmin:string = 'false'
  userData:any;
  users:any = new Array();
  categoryArray:any = new Array();
  categoryArraysend:any;
  labelArraysend:any;
  labelArray:any = new Array();
  labelArray1:any = new Array();
  updateid:any

  projectName:string='';
  public id:any = 0;
  projectList: any;
  usrList: any;
  updatedata:any
  public newarray:any =[{
		SlNumber: '',
		User: ''
		}];
  constructor(
    private router: Router,
    private inboundRouteService: InboundRouteService,
    private loginService: LoginService,
    private dialerService: DialerService,
    private notifyService: NotificationService,
    private activeroute: ActivatedRoute,
    private broadcastservice: BroadcastService,
    private extensionservice: ExtensionService,

  ) { }
  
  ngOnInit() {
    console.log(this.label,"==========",this.labels,"=========",this.categories,"=========",this.category)
    this.users = []
    this.subUserIds = []
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
    this.dialerService.fetchOneProjectDetail(this.id).subscribe(
      (data:any) => {
        this.projectList = data.data
        if(this.projectList != null){
        this.projectName=this.projectList.projectName
        this.DIDNumberInitial=this.projectList.did
        this.categoryArray = this.projectList.proCatgry
        this.users = this.projectList.proUsers
        this.labelArray = this.projectList.feedBackLabels
        console.log(data);
      }
      
        });
  }


  handleFileInput(files: FileList) {
    console.log("file data", files)
    this.usrList = files.item(0);
    console.log("file", this.usrList)
    
  }
  
  selectDID(item) {
    this.DIDNumber = item.id
    

  }


  selectSubUser(item) {
    this.subUser = item.id
    var temp = {'id':item.id, 'name': item.name}
    this.users.push(temp)
    this.subUserIds.push(item.id)
    this.subUserIds = this.subUserIds.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    })
    console.log("ids", this.subUserIds )
  }

  deleteUser(item){
    console.log("uuuu", item.name)
    // this.users.remove(item)
    const index = this.users.indexOf(item, 0);
    if (index > -1) {
      this.users.splice(index, 1);
      this.subUserIds.splice(index, 1);
    }
    
  }

  onChangeSearchDID(val: string) {
    
    this.extensionservice.searchExtension(val, this.UserProfileId).subscribe(
      (data: any) => {
        this.didList = []
        for (let e of data) {
          if (e != undefined) {
            this.didList.push(e.didNumber)
          }
        }
      }
    )



  }


  onChangeSearchSubUser(val: string) {
    this.dialerService.searchSubUser(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.subUserList = data
      }
    )
  }



  onFocusedDID(e){
    
    this.extensionservice.searchInitialExtension(this.UserProfileId).subscribe(
      (data: any) => {
        if (data) {
          this.didList = []
          for (let i = 0; i < data.length; i++) {
            if (data[i].didNumber !== undefined) {
              this.didList.push(data[i].didNumber)
            }
          }
        }
      }
    )


  }

  onFocusedSubUser(e){
    this.dialerService.getSubUserList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.subUserList = data
      }
    )
  }
  clearDatas(){
    this.DIDNumber = 0
    
  }

addcategoriess(){
  // ngAfterViewInit(){
    // var modal = document.getElementbyID('page-modal');
    (<HTMLInputElement>document.getElementById('page-modal')).style.display='block';
    this.categories += this.category;
    this.category=''
  // }
} 

addsubcategory(){
    this.subcats += this.subcat+","
    this.subcat=''
   
  // }
} 
  
save_categories(){
  this.categoryArray = [];
  if(this.categories==""){
    Swal.fire('Oops..!', 'Please Fill All The Mandatory Fields', 'warning')

  }
  else{
    this.categ_list += this.categories+":>"+this.subcats;
    var length = this.categoryArray.push({'category':this.categories,'sub':this.subcats}); 
    console.log("length",this.categoryArray);
     (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
     this.categories='';
     this.subcats='';
   
  }
} 

addlabeloptions(){
  // ngAfterViewInit(){
    // var modal = document.getElementbyID('page-modal');
    (<HTMLInputElement>document.getElementById('label-modal')).style.display='block';
    this.labels += this.label;
    this.label=''
  // }
} 
addlabeloptions1(id){
    this.updateid=id;
   
    (<HTMLInputElement>document.getElementById('label-modal1')).style.display='block';
    this.updatedata=JSON.parse(JSON.stringify(this.labelArray[id]));
  
    
    this.labels += this.updatedata['label'];
    this.suboptions+=this.updatedata['option'];
    
    // this.label=''
  
} 

close_label_modal(){
     (<HTMLInputElement>document.getElementById('label-modal')).style.display='None';
     this.labels='';
     this.suboptions='';
   
  // }
} 
close_label_modal1(){
  
  (<HTMLInputElement>document.getElementById('label-modal1')).style.display='None';
  this.labels='';
  this.suboptions='';

// }
} 
addsuboptions(){
    this.suboptions += this.suboption+","
    this.suboption=''
   
  // }
} 
addsuboptions1(){
  this.suboptions += this.suboption+","
  this.suboption=''
 
// }
} 
save_labels(){
  if(this.labels==""){
    Swal.fire('Oops..!', 'Please Fill All The Mandatory Fields', 'warning')
    

  }
  else{
    this.label_list += this.labels+":>"+this.subcats;
    var length = this.labelArray.push({'label':this.labels,'option':this.suboptions}); 
    console.log("length",this.labelArray);
     (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
     this.labels='';
     this.suboptions='';
   
  }
} 
save_labels1(){
  if(this.labels!=""){
  this.label_list += this.labels+":>"+this.subcats;
  this.labelArray.splice(this.updateid, 1);
  var length=this.labelArray.splice(this.updateid,0, {'label':this.labels,'option':this.suboptions});
  // var length = this.labelArray.push({'label':this.labels,'option':this.suboptions}); 
  console.log("length",this.labelArray);
   (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
   this.labels='';
   this.suboptions='';
   this.close_label_modal1()
   Swal.fire('Updated', 'Label Updated', 'success')
  }
  else{
    Swal.fire('Oops..!', 'Please enter Label Name', 'warning')
  }
 
 
} 
remove_labels(index)
{
//  this.categoryArray.remove()
this.labelArray.splice(index, 1);
} 
EditProject(id){
  this.router.navigateByUrl('/projectDetails/'+id);
  
}
close_modal(){
     (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
     this.categories='';
     this.subcats='';
   
  // }
} 
close_modal1(){
  (<HTMLInputElement>document.getElementById('page-modal1')).style.display='None';
  this.categories='';
  this.subcats='';

// }
} 
remove_categories(index)
{
//  this.categoryArray.remove()
this.categoryArray.splice(index, 1);
} 
  clearDatasSubUser(){
    this.subUser = 0
  }
 adddlabel = function(){
	       	this.dlabels += this.dlabel+","
           this.dlabel=''
        	}


  onUploadDialer(Proid){
    if(Proid != "0"){
      if(this.users.length>0)
      {
        for(let i=0;i<this.users.length;i++){
            this.subUserIds.push(this.users[i].ids) ;

            this.subUserIds = this.subUserIds.filter(function(elem, index, self) {
              return index === self.indexOf(elem);
            })
            console.log("wwwwwwww",this.subUserIds)
        }
      }
      
      this.DIDNumber = this.DIDNumberInitial
    }
  
    this.categoryArraysend = JSON.stringify( this.categoryArray);
     this.labelArraysend = JSON.stringify( this.labelArray);
    if(this.projectName == '' || this.DIDNumber == 0 || this.subUserIds.length <= 0 && this.usrList == undefined){
      Swal.fire('Oops..!', 'Please Enter All The Mandatory Fields', 'warning')
    // }else if(this.DIDNumber == 0) {
    //   Swal.fire('Oops..!', 'Please Select DID Number', 'warning')
    // }else if(this.subUserIds.length <= 0 && this.usrList == undefined){
    //   Swal.fire('Oops..!', 'Please select SubUsers', 'warning')
    }
    else {
      if(this.usrList == undefined){
        this.usrList="false"
      }
      this.dialerService.AddDialer(Proid,this.usrList,this.projectName, this.UserProfileId,this.DIDNumber, this.subUserIds,this.categoryArraysend,this.labelArraysend,this.dlabels).subscribe(
        (res:any) => {
          this.projectName = '';
          this.DIDNumber = 0;
          this.categoryArraysend = '';
          this.labelArraysend=''
          console.log("data", res);
          this.subUserIds = [];
          this.categoryArray=[];
          this.router.navigate(['dialer/dialerList/']);
          this.notifyService.sendNotification('Done', 'Project Details sent successfully', 'is-success')
        }
      )
    }
  }

  sampleUserSheet():void {
    this.broadcastservice.downloadsamplesheet(this.newarray, "UserSheet");
     }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtual-sip',
  templateUrl: './virtual-sip.component.html',
  styleUrls: ['./virtual-sip.component.css']
})
export class VirtualSIpComponent implements OnInit {
  isAdmin: string;
  menus: { link: string; name: string; perm: string; }[];

  constructor() { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    
      this.menus = [
       
        {"link":"/virtualSip/addvirtualSip", "name":"Add VirtualSip","perm":"cadmin_q_addVirtualSip"},
        {"link":"/virtualSip/virtualSipList", "name":"VirtualSip List","perm":"cadmin_q_virtualSipList"},
      ]
  }

}

import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: '[app-rightbar]',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.css']
})
export class RightbarComponent implements OnInit {
  userData: any;
  UserProfileId: any;
  data: any;
  answer: any;
  total: any;
  noAnswer: any;
  userName: any;
  mobile: any;
  address: any;
  email: any;
  balance: any;
  company: any;
  busy: any;
  terminate: any;
  unAvail: any;
  usertype:any

  constructor(
    private loginservice: LoginService,
  ) { 

  }


  ngOnInit() {
   
    this.userData = this.loginservice.getUserdata(false);
    this.UserProfileId = this.userData.id;
    this.userName = this.userData.name
    this.usertype=this.userData.usertype
    this.loginservice.getCallRecordsRightSidebar(this.UserProfileId,this.usertype).subscribe(
      (res:any) => {        
         this.total = res[5].total;
         this.answer = res[0].anwered;
         this.noAnswer = res[1].Noanswered;
         this.busy = res[2].busy;
         this.terminate = res[3].terminate;
         this.unAvail = res[4].unAvail;
        });

        this.loginservice.getUserDetails(this.UserProfileId, this.usertype).subscribe(
          (res:any) => {
            this.mobile = res[0].mobile;
            this.address = res[0].address;
            this.email = res[0].email;
            this.balance = res[0].balance;
            this.company = res[0].company;
            });

  }

}

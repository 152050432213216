import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';

@Component({
  selector: 'app-inbound-user-detail',
  templateUrl: './inbound-user-detail.component.html',
  styleUrls: ['./inbound-user-detail.component.css']
})
export class InboundUserDetailComponent implements OnInit {
  public userData:any;
  keyword = 'name';
  isAdmin:string = 'false'
  UserProfileId:number = 0;
  id:number = 0;
  did:string;
  dids:string = '';
  cpp:string ;
  pulse:string;
  mobile:string;
  address:string = '';
  UserProfileData:any;
  UserData:any;
  username:string;
  customername:string;
  description:string;
  email:string;
  user_id:number;
  password:string;
  channels:string;
  GROUP:any;
  grouplist:any;
  groupInitial:any;
  dialerPad:any;
  report:any;
  ChatId:any;

  constructor(
    private loginservice: LoginService,
    private activeroute: ActivatedRoute,
    private usermanagementservice:UsermanagementService,
  ) { }

  ngOnInit() {
    console.log("shareef");
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginservice.getUserdata(false);
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }else{
      let userID = JSON.parse(localStorage.getItem('customer'))
      if(userID != null){
        this.UserProfileId = userID.id
      }
    }
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    this.usermanagementservice.getUserProfile(this.id).subscribe(
      (data:any) => {
        this.address = data.address
        this.cpp = data.cpp
        this.pulse = data.pulse 
        this.mobile = data.mobile
        this.username = data.username
        this.customername = data.name
        this.description = data.description
        this.email = data.email
        this.user_id =data.user
        this.dids = data.did_numbers
        this.channels=data.no_of_channels
        this.dialerPad=data.dialerPadPermission
        this.report=data.reportPermission
        this.ChatId=data.chat_id
          });
         
        }

}

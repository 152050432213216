import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ReportService } from '../inbound-report/report.service'
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { API_URL, VOICE_URL } from 'src/global';
import { ExcelService } from 'src/app/inbound-report/excel.service';
import * as jsPDF from 'jspdf';
import { NotificationService } from '../notification/notification.service';
import { UsermanagementService } from "../../app/usermanagement.service";
import Swal from 'sweetalert2';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportSettingsService } from '../report-settings/report-settings.service';


@Component({
  selector: 'app-missed-call-report',
  templateUrl: './missed-call-report.component.html',
  styleUrls: ['./missed-call-report.component.css'],
  providers: [DatePipe]
})
export class MissedCallReportComponent implements OnInit {
  permis = false;
  permissions: any;
  userData: any;
  reportForm: FormGroup;
  voiceRecordData: any = new Array();
  filteredVoiceData: any;
  newarray: any = new Array();
  public searchText: string = '';
  myDate = new Date();
  today = new Date();
  tomorrow = new Date(this.today.setDate(this.today.getDate() + 1));
  current_day = new Date();
  yesterday = new Date(this.current_day.setDate(this.current_day.getDate() - 1));
  tdy = new Date(this.myDate.setDate(this.myDate.getDate()));
  wordarray: any = new Array();
  excelQuestions: string[] = [];
  usrId: any;
  DialerReportDataz: { "que1": string; }[];
  DialerReportData: any = new Array();
  count: number;
  head: string[][];
  private hostUrl: string = API_URL;
  voice_url = VOICE_URL;
  fromdate: string = '';
  todate: string = '';
  callStatus: string = '';
  MissedcallReportData: any = new Array();
  total_page: any;
  current_page: number = 1;
  last: number;
  today_informat = this.datePipe.transform(this.today, 'dd-MM-yyyy');
  ExcelPdfReportData: any = new Array();

  userType: any;


  // Param for address book
  addresspopup: boolean = false;
  contact: any;
  contact_initial: any;


  // Settingsforcomment
  commentopen: boolean = false;
  comment: any
  call_id: any;

  tagList: any = new Array()
  showTagDiv: boolean = false

  constructor(
    private usermanagementservice: UsermanagementService,
    private loginservice: LoginService,
    private reportservice: ReportService,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private reportsettingservice: ReportSettingsService,

  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.usrId = this.userData.id
    this.contact_initial = { 'number': '', 'name': '', 'email': '', 'id': '', 'user': this.userData.id }
    this.contact = this.contact_initial
    this.usrId = localStorage.getItem('usrId')

    this.userType = localStorage.getItem('userType')
    this.reportForm = new FormGroup({

      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      callDirection: new FormControl(''),
      user: new FormControl(0),
      search: new FormControl(''),
      tag_filter: new FormControl('')
    });
    this.permissions = this.userData.permissions

    for (let element of this.permissions) {
      console.log(element.codename)
      if (element.codename == "cadmin_p_missedcallreport") {
        this.permis = true;
      }
    }

    this.route.params.subscribe(params => {
      const param = params['missed_call_report']; // 'id' matches the route parameter
      console.log('missed_call_report', param)

      if (param != undefined) {
        // var from = this.datePipe.transform(this.yesterday, 'yyyy-MM-dd');
        // var to = this.datePipe.transform(this.yesterday, 'yyyy-MM-dd');
        var from = this.datePipe.transform(param, 'yyyy-MM-dd')
        var to = this.datePipe.transform(param, 'yyyy-MM-dd')
        var missed_call_report = this.datePipe.transform(param, 'yyyy-MM-dd')
        var incomingDirection = 'inbound'

        this.reportservice.missedCallReport(this.userData.id, from, to, this.userType, incomingDirection, this.searchText, 1, missed_call_report, "", this.reportForm.value.tag_filter).subscribe(
          (res: any) => {
            this.voiceRecordData = this.filteredVoiceData = res.results
            this.MissedcallReportData = res["results"]
            this.total_page = res['total_pages']
            this.current_page = res['current_page']
            this.last = res['total_pages']
          }
        )
      } else {
        console.log(this.tdy)
        console.log(this.tomorrow)
        var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
        var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
        console.log("ffff", from)
        console.log("tooo", to)
        console.log("-----this.reportForm----", this.reportForm.value)

        this.reportservice.missedCallReport(this.userData.id, from, to, this.userType, this.callStatus, this.searchText, 1, null, "", this.reportForm.value.tag_filter).subscribe(
          (res: any) => {
            this.voiceRecordData = this.filteredVoiceData = res.results
            this.MissedcallReportData = res["results"]
            this.total_page = res['total_pages']
            this.current_page = res['current_page']
            this.last = res['total_pages']
          }
        )
      }
    });
    this.getCallTags()
  }


  fetchNextPage() {
    console.log("=====", this.current_page)
    this.SearchMissedReport(this.current_page + 1)
  }

  fetchPreviousPage() {
    this.SearchMissedReport(this.current_page - 1)

  }


  fetchFirstPage() {
    this.SearchMissedReport(1)
  }

  fetchLastPage() {
    this.SearchMissedReport(this.last)
  }

  fetchByPageNumber(page) {
    if (parseInt(page) === parseInt(page, 10) && page > 0 && page <= this.total_page) {
      this.current_page = parseInt(page);
      this.SearchMissedReport(page)
    }
    else {
      Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
    }
    // this.SearchMissedReport(page)
  }






  exportAsXLSX(): void {
    var text;


    if (this.reportForm.value.fromDate && this.reportForm.value.toDate) {
      var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
    else {
      var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
    }


    this.reportservice.missedCallReport(this.userData.id, from, to, this.userType, this.reportForm.value.callDirection, this.reportForm.value.search, this.current_page, null, "all", this.reportForm.value.tag_filter).subscribe(
      (res: any) => {
        this.ExcelPdfReportData = res
        for (let element of this.ExcelPdfReportData) {


          var objz = {
            'DID': element.call_info[0].DID, 'Source': element['customer']['number'], 'Country code': element['country_code'],
            'Date': element['call_time'], 'Agent': element['agentNumber'], 'DepartmentName': element['department_name'], 'Duration': element['totalcalldDuration'], 'callDirection': element['call_type'], 'Comment': element['comment'],
            'Tag': element['tag'], 'Status': element['callStatus']
          }


          this.wordarray.push(objz)
        }

        if (this.fromdate, this.todate) {
          text = "meiisedcall Report from: " + from + " To: " + to + ""
        }
        else {
          text = "missedcall Report of " + this.today_informat
        }
        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];

      })

  }


  SearchMissedReport(pageNumberData = 1) {

    if (this.reportForm.value.fromDate && this.reportForm.value.toDate) {
      var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
    else {
      var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
    }


    if (!from && !to) {
      console.log("no fomand to")

    }
    this.userType = localStorage.getItem('userType')

    console.log(moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("MM-DD-YYYY"), "yyyy", this.reportForm.value)
    this.reportservice.missedCallReport(this.userData.id, from, to, this.userType, this.reportForm.value.callDirection, this.reportForm.value.search, pageNumberData, null, '', this.reportForm.value.tag_filter).subscribe(
      (res: any) => {
        this.voiceRecordData = this.filteredVoiceData = res.results;
        this.MissedcallReportData = res["results"]
        this.total_page = res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']

        console.log("=========LAST PAGE======", this.last, res['total_pages'])

        console.log(res);
      }
    )

  }

  searchBox() {

    // this.FetchMissedcallReportData(1)

    console.log("textttttttttt", this.searchText)
    this.filteredVoiceData = this.voiceRecordData.filter((item) => {
      console.log("item", item)
      console.log("callerIdddddddddddd", item.did)
      console.log("callerIdddddddddddd", item.answeredAgent)
      console.log("search text", this.searchText)
      if (item.answeredAgent == null) {
        item.answeredAgent = " ";
      }
      if (item.customer.number.includes(this.searchText) || item.did.includes(this.searchText) || item.answeredAgent.includes(this.searchText)) {
        console.log("iteeeeeeem", item)
        return item;
      }
    })
  }






  downloads() {
    var countSlNo = 1
    var newarray = []
    var head = [['SlNo', 'DID', 'Source', 'Country code', 'Date', 'Agent', 'DepartmentName', 'Duration', 'callDirection', 'comment', 'Tag', 'callStatus']]

    if (this.reportForm.value.fromDate && this.reportForm.value.toDate) {
      var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
    else {
      var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');

    }


    this.reportservice.missedCallReport(this.userData.id, from, to, this.userType, this.reportForm.value.callDirection, this.reportForm.value.search, this.current_page, null, "all", this.reportForm.value.tag_filter).subscribe(
      (res: any) => {
        console.log(res)
        this.ExcelPdfReportData = res

        console.log("----report", this.ExcelPdfReportData)
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            countSlNo,
            element.call_info[0].DID,
            element['customer']['number'],
            element["country_code"],
            element['call_time'],
            element['agentNumber'],
            element['department_name'],
            element['totalcalldDuration'],
            element['call_type'],
            element['comment'],
            element['tag'],
            element['callStatus']])
          countSlNo = countSlNo + 1
        }
        var pdf = new jsPDF();
        pdf.text('missedcall Report', 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: 'grid',
          styles: { fontSize: 4 },
          tableWidth: 'auto',
          columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        })
        pdf.output('dataurlnewwindow')
        pdf.save('missedcallreport' + from + '_to_' + to + '.pdf');
      })
  }








  LoadPage() {
    this.SearchMissedReport();
  }

  CloseComment() {
    this.commentopen = false
    this.call_id = ""
    this.comment = ""
  }

  updateReportPage() {
    this.SearchMissedReport(this.current_page);
  }

  addCallRecordTag(call_record_id: any, index: any) {
    this.reportsettingservice.getReportTagChild(call_record_id).subscribe((res: any) => {
      const updatedTags = res.tag.split(',').map(tag => tag.trim());
      this.MissedcallReportData[index].tag = updatedTags.join(', ');
    })
  }

  deleteCallRecordTag(call_record_id: any, tag: any, index: any) {
    const body = {
      tag: tag
    }

    this.reportsettingservice.deleteReportTagChild(call_record_id, body).subscribe((res: any) => {
      this.MissedcallReportData[index].tag = res.tags.tag
    })
  }

  getCallTags() {
    let user_id: any
    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res: any) => {
      this.tagList = res.report_tags
    })
  }

}



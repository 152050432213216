import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { Body } from '@angular/http/src/body';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-user-credit-history',
  templateUrl: './user-credit-history.component.html',
  styleUrls: ['./user-credit-history.component.css']
})
export class UserCreditHistoryComponent implements OnInit {

  datas: any;
  public searchText : string;
  public fromdate : any;
  public todate : any;
  permissions:any;
  permis = false;
  userData:any;

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) {


  }

  ngOnInit() {
    this.searchText = ''
    this.fromdate = ''
    this.todate
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      if(element.codename == "cadmin_s_creditList"){
        this.permis = true;
    }
  }

  this.loadDid();
  }


  searchBox(){
    this.loadDid();
  }

loadDid(){
  this.usermanagementservice.usercredit_history(this.searchText).subscribe(
    (data:any) =>{
      this.datas = data
      console.log("=====",this.datas,"====")
    }
   )
}

}


import { Component, OnInit ,Input, EventEmitter,Output} from '@angular/core';
import { ReportService } from '../inbound-report/report.service';
import { DatePipe } from '@angular/common';
import { LoginService } from 'src/app/login.service';
import { NotificationService } from 'src/app/notification/notification.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-address-contact',
  templateUrl: './address-contact.component.html',
  styleUrls: ['./address-contact.component.css']
})
export class AddressContactComponent implements OnInit {

  userData: any;

  @Input() public addresspopup: Boolean;
  @Input() public contact:any;
  @Output() public closeContact = new EventEmitter<void>();
  @Output() public LoadPage = new EventEmitter<void>();
  cont_error:string="";




  constructor(

    private reportservice: ReportService,
    private datePipe: DatePipe,
    private loginservice: LoginService,
    private notifyService: NotificationService,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }



  close() {
    this.cont_error=""
    this.closeContact.emit();
  }



  CreateContact(){
    if (this.contact.name == ""){
      Swal.fire("Please Fill Name")
      return
    }
  
    if (this.contact.id){       
  
      this.reportservice.updatecontact(this.contact,this.contact.id).subscribe((res:any)=>{
        if(res.id){
          this.LoadPage.emit();
          this.close();
          
        }
        else if(res.error){
          this.cont_error = res.error
          return
        }
  
        else{
          this.notifyService.sendNotification('','Error in Creating conatct', 'is-danger')
        }
  
        this.addresspopup=false
        
      })
  
  
    }
  
    else{
  
  
      this.reportservice.createContact(this.contact).subscribe(
        (res:any) => {
    
          if(res.id){
            this.LoadPage.emit();
            this.notifyService.sendNotification('','Contact Created Succesfully', 'is-success')
            this.close()
          }
          else if(res.error){
            this.cont_error = res.error
            return
          }
          else{
            this.notifyService.sendNotification('','Error in Creating conatct', 'is-danger')
            return
          }
  
          this.addresspopup=false
          
        });
  
    }
  
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: '[app-queuemanagemet]',
	templateUrl: './queuemanagemet.component.html',
	styleUrls: ['./queuemanagemet.component.css']
})
export class QueuemanagemetComponent implements OnInit {
	public menus:any

	constructor() { }

	ngOnInit() {
		this.menus = [
			{"link":"/queue/queuelist", "name":"Queue List","perm":"cadmin_q_QueueList"}, 
			{"link":"/queue/addqueue","name":"Add Queue","perm":"cadmin_q_addQueue"},
		]
	}

}

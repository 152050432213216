import { Component, OnInit } from '@angular/core';
import { LoginService } from "../../login.service";
import Swal from 'sweetalert2';
import { SmsService } from 'src/app/sms/sms.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {

  public heading:string;
  public description:string;
  public userData:any;
  public datas:any;
  public promotionalsms:Boolean = false;
  public template_id:string;
  public TemplateForm:FormGroup;

  permissions:any;
  permis = false;
  ids:any;
  sms_model:string="greenads"
  smsList={

  }

  constructor(
    private loginservice: LoginService,
    private smsService: SmsService,
    private notifyservice: NotificationService,
    private route: ActivatedRoute
  ) { } 

  ngOnInit() {

    this.userData = this.loginservice.getUserdata(true);

    this.getSmsProvider()

    this.TemplateForm = new FormGroup({
      heading: new FormControl(Validators.required),
			description: new FormControl(Validators.required),
      template_id:new FormControl(),
      sms_model:new FormControl()
      
    }),

  this.route.params.subscribe(params => {
  this.ids = params['id']
  console.log(this.ids,"===ids===")
  if(this.ids == "new"){
    console.log(this.ids,"==++==++==++==++")
      this.route.params.subscribe(params => {
      this.permissions = this.userData.permissions
          for (let element of this.permissions) {
            if(element.codename == "cadmin_s_templates"){
              this.permis = true;
            }
          }
      this.smsService.TemplateList(this.userData.id).subscribe((data:any) =>{
      this.datas = data;
      })
    });
  }
  else{
    console.log(this.ids,"==++==++==++==++")
    this.LoadTemplate(this.ids)
  }
});
}


changeSMSModel(val){
  this.sms_model=val
}


getSmsProvider(){
  this.smsService.getSmsConfigOfUser(this.userData.id).subscribe((data:any)=>{
    this.smsList=data.data
  })
}


  AddTemplate = function() {
    this.submitted = true;
  if(this.TemplateForm.valid){
		this.user = this.loginservice.getUserdata(true);
    this.UserData = { 
     'heading':this.heading,
     'description':this.description,
     'user':this.user.id,
     'promational_template':this.promotionalsms,
     'template_id':this.template_id,
     'sms_model':this.sms_model
    }

    this.smsService.AddTemplate(this.UserData).subscribe(
      (res:any) => {             
          Swal.fire('Created', 'Template Successfully created', 'success')
          this.smsService.TemplateList(this.userData.id).subscribe(
            (data:any) => {
              this.datas = data;
            }
          )

          this.TemplateForm.reset()

          this.heading = "",
          this.description = "",
          this.promotionalsms=false,
          this.template_id="",
          this.sms_model=""
  })
}
}

UpdateTemplate=function(id){
  this.data = { 
     'heading':this.heading,
     'description':this.description,
     'user':id,
     'promational_template':this.promotionalsms,
     'template_id':this.template_id,
     'sms_model':this.sms_model

    }
    console.log("=====this.data+++++",this.data)
  this.smsService.TemplateUpdate(this.data).subscribe(
    (data:any) => {
      Swal.fire('Updated', 'Template Successfully Updated', 'success')
      this.smsService.TemplateList(this.userData.id).subscribe(
        (data:any) => {
          this.datas = data;
        }
      )
      this.TemplateForm.reset()
          this.heading = "",
          this.description = "",
          this.promotionalsms=false,
          this.template_id="",
          this.sms_model=""
    }
  )
}

LoadTemplate(id){
  console.log(id,"==++==")
  this.smsService.templateupdateDetail(id).subscribe(
    (data: any) => {
      this.heading = data['heading']
      this.description = data['description']
      this.promotionalsms = data['promotionalsms']
      this.template_id=data['template_id']
      this.sms_model=data['sms_model']
      this.ids = id
    }
  )
}

OndeleteTemplate(id){
  Swal.fire({
    title: 'Are you sure ?',
    text: 'The Template data Deleted',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Delete it!',
    cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
    if (result.value) {
      this.smsService.deleteTemplateData(id).subscribe(
      (res:any) => {
        console.log('data', res)
        this.notifyservice.sendNotification('Deleted', 'Queue delete successfully', 'is-success')
        this.smsService.TemplateList(this.userData.id).subscribe(
          (data:any) => {
            this.datas = data;
          }
        )
      }
      )
    }else{
      this.notifyservice.sendNotification('Cancelled', 'Queue deleting has been cancelled', 'is-danger')
    }
    })
  }

}



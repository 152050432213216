import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder,FormArray, Validators } from '@angular/forms';
import { UsermanagementService } from '../usermanagement.service';
import { LoginService } from '../../login.service';
import { FileuploadComponent } from 'src/app/fileupload/fileupload.component';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: '[app-usergroupedit-detail]',
  templateUrl: './subuser-group-edit.component.html',
  styleUrls: ['./subuser-group-edit.component.css']
})
export class UserGroupEditComponent implements OnInit {
  public userData:any;
  id:number = 0;
  group:string;
  group_id:number;
  customerInitial:any;
  isEdit:boolean;
  customerEvent:any;
  SubUserPermissionForm:FormGroup;
  public permissions:any;
  public permissionarrayItems:any;
  public usergroupdata:any;
  GROUP:any;
  grouplist:any;
  groupInitial:any;
   keyword = 'name';
  constructor(
    private loginservice: LoginService,
    private usermanagementservice:UsermanagementService,
    private route: Router,
    private activeroute: ActivatedRoute,
  ) {
   
   }

  ngOnInit() {
              const formdataobj = {}; 
               this.id = +this.activeroute.snapshot.paramMap.get('id');
                this.usermanagementservice.getSubusergroup(this.id).subscribe(
                (data:any) => {
                    this.usergroupdata = data
                     this.group = data.name
                     this.group_id = data.id
       
       
                   });
              this.userData = this.loginservice.getUserdata(true); 
              this.usermanagementservice.getPermissions(this.userData.id).subscribe(
                (data:any) => {
                    this.permissionarrayItems = data
                    for (const perm of this.permissionarrayItems)
                        {
                          formdataobj[perm.codename]=new FormControl(false);
                        }
                     formdataobj["groupname"]=new FormControl('');    
                    this.SubUserPermissionForm = new FormGroup(formdataobj);
                  });
              this.permissionarrayItems = [];
             
           }
     
  onUsergroupBtn() {
   {
        this.usermanagementservice.addUsergroup({"groupname":this.group,'pgroup':this.GROUP},this.userData.id).subscribe(
          (res:any) => { 
               this.usermanagementservice.addSubUserPermission(res.id,this.SubUserPermissionForm.value).subscribe(
                (res:any) => {             
                   Swal.fire('Created', 'User Group Successfully Created', 'success')
             
              this.route.navigateByUrl('/user/')
            } )
          })
        
    }
  }

  // DID AutoCompleted Functions

  selectgroup(item) {
    this.GROUP = item.id
  }
 
  onChangeSearchgroup(val: string) {
    this.usermanagementservice.searchgroupList(val , this.userData.id ).subscribe(
      (data:any) =>{
        this.grouplist = data
      }
    )
  }
  
  onFocusedgroup(e){
    this.usermanagementservice.getgroupList(this.userData.id ).subscribe(
      (data:any) =>{
        this.grouplist = data
      }
    )
  }

  onCleargroup() {
    this.GROUP = 0
  }
   
    onUpdateUsergroupBtn() {
   {
        this.usermanagementservice.updateSubusergroup({"name":this.group,'pgroup':this.GROUP},this.group_id).subscribe(
          (res:any) => { 
             
          })
        
    }
  }


      // onUpdateUsergroupBtn() {
      //     this.UsergroupData = { 
      //       'name': this.group,
      //      'parent_group':this.customername,
      //      'email':this.email,
      //     }
      //     this.usermanagementservice.updateUserData(this.user_id, this.UserData).subscribe(
      //     (res:any) => {
      //       this.UserProfileData = {
      //         'address': this.address,
      //         'cpp': this.cpp,
      //         'pulse': this.pulse,
      //         'mobile': this.mobile,
      //         'description':this.description,
      //         'user':res.id,
      //         'did_numbers':this.dids,
      //         'no_of_channels':this.channels,
              
    
      //         }
      //        this.usermanagementservice.updateUserProfileData(this.id, this.UserProfileData).subscribe(
      //        (res:any) => {
      //          Swal.fire('Updated', 'User Profile Successfully Updated', 'success')
      //          this.address='',
      //         this.cpp='',
      //         this.pulse='',
      //         this.mobile='',
      //         this.username='',
      //         this.customername='',
      //         this.email='',
      //         this.description='',
      //         this.dids='',
      //         this.channels=''

           
      //     }
      //   )
           
      //     }
      //   )
       
      // }
}

import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { WhatsappService } from '../whatsapp-services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

declare var $:any
@Component({
  selector: 'app-whatsapp-placeholders',
  templateUrl: './whatsapp-placeholders.component.html',
  styleUrls: ['./whatsapp-placeholders.component.css']
})
export class WhatsappPlaceholdersComponent implements OnInit, AfterViewInit, OnDestroy {

  contentDataTable: any;
  mediaDataTable: any;
  userData: any;
  whatsappUserList: any[] = [];
  selectedTab: string = "text";
  contentForm: FormGroup;
  mediaForm: FormGroup;
  templateList: any[] = [];
  templateContentList: any[] = [];
  templateMediaList: any[] = [];
  templateContentId: string | null = "";
  templateMediaId: string | null = "";
  mediaOptions = [
    {value: null, name: "Select Media Type"},
    {value: 1, name: "Document"},
    {value: 2, name: "Image" },
    { value: 3, name: "Video" }
  ]


  constructor(
    private whatsappService: WhatsappService,
    private loginService: LoginService,
    private fb: FormBuilder
  ) {
    this.contentForm = this.fb.group({
      name: ["", Validators.required],
      template: [null, Validators.required],
      placeholder: ["", Validators.required],
      content: ["", Validators.required]
    })

    this.mediaForm = this.fb.group({
      name: ["", Validators.required],
      template: [null, Validators.required],
      url: ["", [Validators.required, Validators.pattern('^(https?:\/\/).+')]],
      mediaType: [null, Validators.required]
    })
   }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
  }

  ngAfterViewInit(): void {
    this.initializeContentTable();
    this.initializeMediaTable();
    this.updateContentDataTable();
    this.getTemplateList();    
  }

  ngOnDestroy(): void {
  }


  selectTab(tabName: string) {
    this.selectedTab = tabName;
    if (tabName == "media") {
      this.updateMediaDataTable();
    }
  }


  initializeContentTable() {
    this.contentDataTable = $('#contentTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'sl_no', title: 'Sl No' },
        { data: 'name', title: 'Name' },
        { data: 'template_name', title: 'Template' },
        { data: 'place_holder', title: 'Placeholder'},
        { data: 'content', title: 'Value' },
        { data: 'action', title: 'Action'}
      ]
    });
  }


  initializeMediaTable() {
    this.mediaDataTable = $('#mediaTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'sl_no', title: 'Sl No' },
        { data: 'name', title: 'Name' },
        { data: 'template_name', title: 'Template' },
        { data: 'media_type_name', title: 'Media Type' },        
        { data: 'media_url', title: 'URL' },
        { data: 'action', title: "Action"}
      ]
    });
  }

  onContentSubmit() {
    this.markFormGroupAsTouched(this.contentForm);
    if (this.templateContentId) {  
      const data = {
        id: this.templateContentId,
        name: this.contentForm.value.name,
        template: this.contentForm.value.template,
        place_holder: this.contentForm.value.placeholder,
        content: this.contentForm.value.content
      }

      this.whatsappService.updateTemplateContent(data).subscribe((res: any) => {
        if (res.status === "1") {
          Swal.fire("Success", "Updated successfully.", "success");
          this.updateContentDataTable();
          this.contentForm.reset();
          this.templateContentId = null;
        } else {
          Swal.fire("Something went wrong!", "Failed to update.", "error");
        }
      })
    } else {
      if (this.contentForm.valid) {
        const data = {
          name: this.contentForm.value.name,
          template: this.contentForm.value.template,
          place_holder: this.contentForm.value.placeholder,
          content: this.contentForm.value.content
        }
        this.whatsappService.addTemplateContent(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Placeholder added successfully.", "success")
            this.updateContentDataTable();
            this.contentForm.reset();
          } else {
            Swal.fire("Something went wrong!", "Failed to add place holder", "error")
          }
        })
      }
    }

  }

  onMediaSubmit() {
    this.markFormGroupAsTouched(this.mediaForm);
    if (this.templateMediaId) {

      const data = {
        id: this.templateMediaId,
        name: this.mediaForm.value.name,
        template: this.mediaForm.value.template,
        media_url: this.mediaForm.value.url,
        media_type: this.mediaForm.value.mediaType
      }

      this.whatsappService.updateTemplateMedia(data).subscribe((res: any) => {
        if (res.status === "1"){
          Swal.fire("Success", "Template media updated successfully.", "success");
          this.updateMediaDataTable();
          this.mediaForm.reset();
          this.templateMediaId = null;
        } else {
          Swal.fire("Something went wrong!", "Failed to update template media", "error")
        }
      })
      
    } else {
      const data = {
        name : this.mediaForm.value.name,
        template: this.mediaForm.value.template,
        media_url: this.mediaForm.value.url,
        media_type: this.mediaForm.value.mediaType
      }

      this.whatsappService.addTemplateMedia(data).subscribe((res: any) => {
        if (res.status === "1") {
          Swal.fire("Success", "Template media added successfully.", "success")
          this.updateMediaDataTable();
          this.mediaForm.reset();
        } else {
          Swal.fire("Something went wrong", "Failed to add template media", "error")
        }
      })
    }
  }

  getTemplateList() {
   
    let qParams = []
    if (this.userData.usergroup === "superadmin") {
      qParams.push({
        key: "scope", value: "superadmin"
      })
    } else {
      qParams.push(
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id }
      )
    }

    this.whatsappService.getTemplatesList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.templateList = res.data
      }
    })
  }

  updateContentDataTable() {

    if (this.userData.usergroup == 'superadmin') {
      const qParams = [
        { key: "scope", value: "superadmin" }
      ]
      this.whatsappService.getTemplateContentList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.templateContentList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
              action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
            }));
            this.contentDataTable.clear();
            this.contentDataTable.rows.add(this.templateContentList);
            this.contentDataTable.draw();
          }
        }
      )
    } else {
      
      const qParams = [
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id }
      ]      
      this.whatsappService.getTemplateContentList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.templateContentList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
              action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
            }));
            this.contentDataTable.clear();
            this.contentDataTable.rows.add(this.templateContentList);
            this.contentDataTable.draw();
          }
        }
      )
    }

    $('#contentTable tbody').on('click', '#btn-delete', (event: any) => {

      const row = this.contentDataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.deleteTemplateContent(row.id);
      }
    });

    $('#contentTable tbody').on('click', '#btn-edit', (event: any) => {

      const row = this.contentDataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.editTemplateContent(row);
      }
    });
  }

  updateMediaDataTable() {

    if (this.userData.usergroup == 'superadmin') {
      const qParams = [
        { key: "scope", value: "superadmin" }
      ]
      this.whatsappService.getTemplateMediaList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.templateMediaList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
              action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
            }));
            this.mediaDataTable.clear();
            this.mediaDataTable.rows.add(this.templateMediaList);
            this.mediaDataTable.draw();
          }
        }
      )
    } else {

      const qParams = [
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id }
      ]
      this.whatsappService.getTemplateMediaList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.templateMediaList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
              action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
            }));
            this.mediaDataTable.clear();
            this.mediaDataTable.rows.add(this.templateMediaList);
            this.mediaDataTable.draw();
          }
        }
      )
    }

    $('#mediaTable tbody').on('click', '#btn-delete', (event: any) => {

      const row = this.mediaDataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.deleteTemplateMedia(row.id);
      }
    });

    $('#mediaTable tbody').on('click', '#btn-edit', (event: any) => {

      const row = this.mediaDataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.editTemplateMedia(row);
      }
    });
  }

  deleteTemplateContent(id) {
    
      Swal.fire({
        title: "Are you sure ?",
        text: "The placeholder will be delete.",
        showCancelButton: true,
        confirmButtonText: "Yes delete placeholder.",
        cancelButtonText: "No. Dont delete."
      }).then((resp) => {
        if (!resp.dismiss) {
          this.whatsappService.deleteTemplateContent(id).subscribe((res: any) => {
            if (res.status === "1") {
              Swal.fire("Success", "Placeholder deleted successfully.", "success");
              this.updateContentDataTable();
            } else {
              Swal.fire("Something went wrong !", "Failed to delete placeholder", "error");
            }
          })
        }
      })    
  }

  editTemplateContent(row) {
    this.templateContentId = row.id;
    this.contentForm.patchValue({
      name: row.name,
      template: row.template,
      placeholder: row.place_holder,
      content: row.content
    })
  }

  onContentEditCancel() {
    this.templateContentId = null;
    this.contentForm.reset();
  }

  deleteTemplateMedia(id) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The template media will be deleted.",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "No, Don't Delete"
    }).then((resp) => {
      if (!resp.dismiss) {
        this.whatsappService.deleteTemplateMedia(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template media deleted successfully.")
            this.updateMediaDataTable();
          } else {
            Swal.fire("Something went wrong", "Failed to delete template media.")
          }
        })
      }
    })
  }

  editTemplateMedia(row) {
    this.templateMediaId = row.id;
    this.mediaForm.patchValue({
      name: row.name,
      template: row.template,
      mediaType: row.media_type,
      url: row.media_url
    })
  }

  onMediaEditCancel() {
    this.templateMediaId = null;
    this.mediaForm.reset();
  }


  
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { LoginService } from "../../login.service";
import { InboundFeedbackService } from '../inbound-feedback.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {
  userData: any;
  userprofileData: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private inboundFeedbackService:InboundFeedbackService,
  ) { }

  ngOnInit()  {
    this.userData = this.loginService.getUserdata(true);
    if(this.userData.group == 'superadmin'){
      this.inboundFeedbackService.feedBackUserList(0).subscribe(
        (data:any) => {
          this.userprofileData = data;
        }
      )
    }else{
      this.inboundFeedbackService.feedBackUserList(this.userData.id).subscribe(
        (data:any) => {
          this.userprofileData = data;
        }
      )
    }
  }

  editUserProfile(id){
    // this.usermanagementservice.getUserProfile(id).subscribe(
    //   (data:any) => {

            this.router.navigateByUrl('/userdetail/'+id);
         
      // }
    // )
  }

}

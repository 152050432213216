import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { ExtensionService } from 'src/app/extension/extension.service';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-virtualsip',
  templateUrl: './list-virtualsip.component.html',
  styleUrls: ['./list-virtualsip.component.css']
})
export class ListVIrtualsipComponent implements OnInit {
  isAdmin: string;
  userData: any;
  permissions: any;
  VirtualSipData: any;
  unFilteredVirtualSipData: any
  userId: any;
	dropdownList: { id: number, itemName: string }[] = [];
  dropdownSettings = {};
	selectedItems = [];
  selectedItem : any
	searchText: string = "";

  constructor(
    private loginService:LoginService,
    private extensionService:ExtensionService,
    private router: Router,
    private userManagementService: UsermanagementService
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions

    if (this.isAdmin == 'true') {
      this.userId = '0'

      this.dropdownSettings = {
        singleSelection: true,
        text: "Select Customer",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        enableSearchFilter: true,
        classes: "text-lg",
      };

      this.getAllVsipUsers()
    }
    else {
      this.userId = this.userData.id

      this.getVsipUsers()
    }
  }

  getVsipUsers(){
    this.extensionService.getVirtualSipList(this.userId).subscribe(
      (data:any)=>{
        this.VirtualSipData=data["data"];
      }
    )
  }

  getAllVsipUsers(){
    this.extensionService.getVirtualSipList(this.userId).subscribe(
      (data:any)=>{
        this.VirtualSipData=data["data"];

        this.unFilteredVirtualSipData = [...this.VirtualSipData];

        const companies = new Set<string>();

        this.VirtualSipData.forEach((item: any) => {
          companies.add(item.companyName);
        });
        this.dropdownList = Array.from(companies)
        .sort((a, b) => a.localeCompare(b))
        .map((companyName, index) => ({
          id: index + 1,
          itemName: companyName
        }));
      }
    )
  }


  Edit(id){
		this.router.navigateByUrl('/virtualSipDetails/'+id);
		
  }
  
  Delete(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The Sip will be  Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
  }).then((result) => {
    if (result.value) {
      this.extensionService.deleteSip(id).subscribe(
        (res:any) => {
          Swal.fire(
            'Deleted',
            'Virtual Sip Deleted Successfully',
            'success'
          )

          if (this.isAdmin == 'true'){
            this.getAllVsipUsers()
          }
          else {
            this.getVsipUsers()
          }
        },
        (error: any) => {


          const errorMessage = error.error && error.error.error
            ? error.error.error.replace(/^\[|\]$/g, '')
              .replace(/'/g, '')
              .trim()
            : 'Please try again later.';
          Swal.fire('Error', errorMessage, 'error');

        }
      )
    }else{
      Swal.fire(
        'Cancelled',
        'Virtual Sip Deletion has been Cancelled',
        'error'
      )
      
    }
  })
}

  onItemSelect(item: any) {
    this.selectedItem = item
  }
  onDeSelect(item: any) {
    this.selectedItem = { itemName: "" };
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
    this.selectedItem = { itemName: "" }; 
  }
  OnItemDeSelect(item: any) {
    this.selectedItem = { itemName: "" };
  }

  search() {
    let filteredData = [...this.unFilteredVirtualSipData];

    if (this.selectedItems.length > 0) {
      const selectedCompanyName = this.selectedItems[0].itemName;
      filteredData = filteredData.filter(vb => vb.companyName === selectedCompanyName);
    }
  
    if (this.searchText && this.searchText.trim().length > 0) {
      const searchTextLower = this.searchText.toLowerCase();
      filteredData = filteredData.filter(vb =>
        vb.username.toLowerCase().includes(searchTextLower)
      );
    }
    this.VirtualSipData = filteredData;
	}

  onToggleOnOff(id: number, status: boolean) {

    const data = {
      agent_id: id,
      status: !status,
      scope: "vsip_user"
    }
    
    this.userManagementService.toggleAgentOnOff(data).
      subscribe((res: any) => {
        if (res["status"] == "1") {
          Swal.fire('Updated', res.message, 'success')
        }
        else {
          Swal.fire(res["message"], res.message, 'warning')
        }
      })

  }

}

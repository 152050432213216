import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-user-credit-list',
  templateUrl: './user-credit-list.component.html',
  styleUrls: ['./user-credit-list.component.css']
})
export class UserCreditListComponent implements OnInit {

  datas: any;
  public searchText : string;
  permissions:any;
  permis = false;
  userData:any;

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) {


  }

  ngOnInit() {
    this.searchText = ''
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      if(element.codename == "cadmin_s_creditList"){
        this.permis = true;
    }
  }

  this.loadDid();
  }


  searchBox(){
    this.loadDid();
  }

loadDid(){
  console.log("==============LoadDid---------------")
  this.usermanagementservice.userCreditList(this.searchText).subscribe(
    (data:any) =>{
      console.log("===============================================",data)
      this.datas = data
    }
   )
}

  deletePlan(did,company){
    if(confirm("Are you sure to delete "+name)) {
      console.log("Implement delete functionality here");
      var bodyData={
        'didnumber':did,
        'company':company
      }
      this.usermanagementservice.userCreditDeletePlan(bodyData).subscribe(
        (data:any) => {
          this.datas = data;
          console.log(data,"mmmmmmm");
          this.loadDid();
        }
      )
    }

  }


}


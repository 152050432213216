import { Component, OnInit, Input } from "@angular/core";
import { LoginService } from "../login.service";
import Swal from "sweetalert2";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { ResetPasswordService } from "../shared.service";

@Component({
  selector: "[app-reset-password]",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  uid: string = "";
  token: string = "";
  username: string = "";
  password1: string = "";
  password2: string = "";
  @Input() isBoolean: boolean;

  Url: any = window.location.href.split("/");

  constructor(
    private loginservice: LoginService,
    private router: Router,
    private resetPasswordService: ResetPasswordService,
    private activeroute: ActivatedRoute,
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.uid = this.Url.slice(4)[0];
    this.token = this.Url.slice(5);
    this.loginservice
      .changePassword(
        this.username,
        this.password1,
        this.password2,
        this.uid,
        this.token
      )
      .subscribe((res: any) => {
        if (res.status == 1) {
          Swal.fire({
            title: "Success",
            text: "Password Changed",
          });
          this.resetPasswordService.setShowResetPassword(false);
          console.log();
          this.router.navigate(["/"]);
        } else {
          console.log("Error in changing the password");
        }
      });
  }
}

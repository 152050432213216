import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IvrService } from '../ivr.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { LoginService } from 'src/app/login.service';
import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';
import { QueuemanagementService } from 'src/app/queuemanagement.service';

@Component({
  selector: '[app-level-one]',
  templateUrl: './level-one.component.html',
  styleUrls: ['./level-one.component.css']
})
export class LevelOneComponent implements OnInit {
  public IVROneForm: FormGroup
  public voiceFileData:any=null;
  voiceLabel: string = 'Voice File';
  levelOneDisplay:boolean = false;
  @Input() optionLabel:string;
  @Input() IvrId: number;
  @Input() optionValue: any;
  @Output() parentEmitter1 = new EventEmitter<any>();
  LevelTwoCount:any = new Array();
  LevelTwoData:any;
  LevelOneId:number = 0
  LevelNum:number = 0
  public parentLevel1:any
  optionTwoValue:any;
  @Input() optionNumber:any;
  optionNum:any = new Array();
  optionNumUpdate:number;
  voiceFileOneInitial:any;
  voicelist:any = new Array();
  destinationList:any = new Array();
  destinationInitial:any;
  keyword:string = 'name';
  @Input() userID:number;


  constructor(
    private ivrservice: IvrService,
    private notifyservice: NotificationService,
    private loginservice: LoginService,
    private voicerecodrservice: VoiceRecordService,
    private queueservice: QueuemanagementService
  ) { }

  ngOnInit() {
    this.IVROneForm = new FormGroup({
      number: new FormControl('', Validators.required),
      ivr: new FormControl(''),
      voiceFile: new FormControl(''),
      destination: new FormControl(''),
      sublevels: new FormControl(0),
    });
    if (this.optionValue) {
      this.voicerecodrservice.getVoiceRecordList(this.userID,0).subscribe(
        (data:any) =>{
          this.voicelist = data
          for (let voiceData of this.voicelist){
            if(voiceData.fileUrl == this.optionValue.voiceFile) {
              this.voiceFileOneInitial = voiceData.name
            }
          }
        }
      )
      this.IVROneForm.setValue({
        number: this.optionValue.number,
        ivr: this.optionValue.ivr,
        voiceFile: this.optionValue.voiceFile,
        destination: this.optionValue.destination,
        sublevels: this.optionValue.sublevels
      })
      this.destinationInitial = this.optionValue.destination
      this.LevelOneId = this.optionValue.id
      this.LevelNum = this.optionValue.sublevels
      this.optionNumUpdate = parseInt(this.optionValue.number)
      this.ivrservice.getLevelTwoOptionsList(this.LevelOneId).subscribe(
        (data:any) => {
          this.LevelTwoData = data
          if(this.LevelTwoData) {
            this.optionNum = []
            for (let i = 0; i < this.LevelNum; i++) {
              if (this.LevelTwoData.length >= i){
                this.optionTwoValue = this.LevelTwoData[i]
                this.optionNum.push(this.optionTwoValue.number)
              }else{
                this.optionTwoValue = undefined
              }
              this.LevelTwoCount.push({
                'orderId': 'LevelTwo'+ (i+ 1).toString(),
                'optionLabel': 'OPTION '+ (i+ 1).toString(),
                'optionValue': this.optionTwoValue
              })
            }
          }else {
            for (let i = 1; i <= this.LevelNum; i++) {
              this.LevelTwoCount.push({
                'orderId': 'LevelTwo'+i.toString(),
                'optionLabel': 'OPTION '+i.toString(),
                'optionValue': undefined
              })
            }
          }
        }
      )
    }
    if(this.IvrId){
      this.ivrservice.GetIVR(this.IvrId).subscribe(
        (data:any) => {
          if(data.id) {
            this.parentLevel1 = data
          }
        }
      )
    }
  }

  voiceFilePath(data) {
    this.voiceFileData = data
  }

  get f() { return this.IVROneForm.controls; }

  // Department Autocompleted Functions

  selectQueue(item) {
    this.IVROneForm.value.destination = item.name
  }
  
  onChangeSearchQueue(val: string) {
    this.queueservice.searchQueue(val ,this.userID).subscribe(
      (data:any) =>{
        this.destinationList = data
        console.log('search queue list', this.destinationList)
      }
    )
  }
  
  onFocusedQueue(e){
    this.queueservice.getQueues(this.userID).subscribe(
      (data:any) =>{
        this.destinationList = data
        console.log('queue focused list', this.destinationList)
      }
    )
  }

  onClearDestination() {
    this.IVROneForm.value.destination = ''
  }
  

  onAddLevelOne() {
    if(this.voiceFileData != undefined &&this.IVROneForm.value.voiceFile != this.voiceFileData){
      this.IVROneForm.value.voiceFile = this.voiceFileData
    }
    if(this.IVROneForm.value.voiceFile != null && this.IVROneForm.value.destination != '' && this.IVROneForm.value.voiceFile != ''){
      
      Swal.fire('Oops..!', 'Voice file and Department not allowed simultaneosly', 'error')
    }else if(this.IVROneForm.value.voiceFile == '' && this.IVROneForm.value.destination == ''){
      Swal.fire('Oops..!', 'Please Select Voice file or Department', 'error')
    }else if(this.IVROneForm.value.voiceFile == null && this.IVROneForm.value.destination == ''){
      Swal.fire('Oops..!', 'Please Select Voice file or Department', 'error')
    }else{
      if(this.LevelOneId != 0){
        if (this.optionNumUpdate != this.IVROneForm.value.number){
          if(this.optionNumber.indexOf(parseInt(this.IVROneForm.value.number)) != -1){
            Swal.fire('Duplicate', 'Already Exists Option Number', 'warning')
          }else{
            this.IVROneForm.value.ivr = this.IvrId
            this.ivrservice.UpdateLevelOne(this.LevelOneId, this.IVROneForm.value).subscribe(
              (res:any) => {
                if(res && res['id']){
                  this.LevelOneId = res['id']
                  this.LevelNum = res['sublevels']
                  this.LevelTwoCount = []
                  this.optionNumber.push(res.number)
                  this.optionNumber.splice(this.optionNumber.indexOf(this.optionNumUpdate), 1)
                  this.optionNumUpdate = res.number
                  this.notifyservice.sendNotification('Updated', 'Level 1 option update successfully', 'is-success')
                  this.ivrservice.getLevelTwoOptionsList(this.LevelOneId).subscribe(
                    (data:any) => {
                      this.LevelTwoData = data
                      if(this.LevelTwoData) {
                        for (let i = 0; i < this.LevelNum; i++) {
                          if (this.LevelTwoData.length >= i){
                            this.optionTwoValue = this.LevelTwoData[i]
                            this.optionNum.push(this.optionValue.number)
                          }else{
                            this.optionTwoValue = undefined
                          }
                          this.LevelTwoCount.push({
                            'orderId': 'LevelTwo'+ (i+ 1).toString(),
                            'optionLabel': 'OPTION '+ (i+ 1).toString(),
                            'optionValue': this.optionTwoValue
                          })
                        }
                      }else {
                        for (let i = 1; i <= this.LevelNum; i++) {
                          this.LevelTwoCount.push({
                            'orderId': 'LevelTwo'+i.toString(),
                            'optionLabel': 'OPTION '+i.toString(),
                            'optionValue': undefined
                          })
                        }
                      }
                    }
                  )
                }
              }
            )
          }
        }else{
          this.IVROneForm.value.ivr = this.IvrId
          this.IVROneForm.value.voiceFile = this.voiceFileData
          this.ivrservice.UpdateLevelOne(this.LevelOneId, this.IVROneForm.value).subscribe(
            (res:any) => {
              if(res && res['id']){
                this.LevelOneId = res['id']
                this.LevelNum = res['sublevels']
                this.LevelTwoCount = []
                this.optionNumUpdate = res.number
                this.notifyservice.sendNotification('Updated', 'Level 1 option create successfully', 'is-success')
                this.ivrservice.getLevelTwoOptionsList(this.LevelOneId).subscribe(
                  (data:any) => {
                    this.LevelTwoData = data
                    if(this.LevelTwoData) {
                      for (let i = 0; i < this.LevelNum; i++) {
                        if (this.LevelTwoData.length >= i){
                          this.optionTwoValue = this.LevelTwoData[i]
                        }else{
                          this.optionTwoValue = undefined
                        }
                        this.LevelTwoCount.push({
                          'orderId': 'LevelTwo'+ (i+ 1).toString(),
                          'optionLabel': 'OPTION '+ (i+ 1).toString(),
                          'optionValue': this.optionTwoValue
                        })
                      }
                    }else {
                      for (let i = 1; i <= this.LevelNum; i++) {
                        this.LevelTwoCount.push({
                          'orderId': 'LevelTwo'+i.toString(),
                          'optionLabel': 'OPTION '+i.toString(),
                          'optionValue': undefined
                        })
                      }
                    }
                  }
                )
              }
            }
          )
        }
      }else {
        if(this.optionNumber.indexOf(parseInt(this.IVROneForm.value.number)) != -1){
          Swal.fire('Duplicate', 'Already Exists Option Number', 'warning')
        }else{
          if (this.IVROneForm.invalid) {
            return;
          }else{
            this.IVROneForm.value.ivr = this.IvrId
            this.IVROneForm.value.voiceFile = this.voiceFileData
            this.ivrservice.AddLevelOne(this.IVROneForm.value).subscribe(
              (res:any) => {
                if(res && res['id']){
                  this.optionNumber.push(res.number)
                  this.optionNumUpdate = res.number
                  this.parentLevel1.levels = this.parentLevel1.levels + 1
                  this.notifyservice.sendNotification('Created', 'Level 1 option create successfully', 'is-success')
                  this.ivrservice.UpdateIVR(this.IvrId, this.parentLevel1).subscribe(
                    (res:any) => {
                      console.log('increase............', res)
                      this.parentLevel1 = res
                    })
                  this.LevelOneId = res['id']
                  this.LevelNum = res['sublevels']
                  
                  this.ivrservice.getLevelTwoOptionsList(this.LevelOneId).subscribe(
                    (data:any) => {
                      this.LevelTwoData = data
                      if(this.LevelTwoData) {
                        this.optionNum = []
                        for (let i = 0; i < this.LevelNum; i++) {
                          if (this.LevelTwoData.length >= i){
                            this.optionTwoValue = this.LevelTwoData[i]
                            this.optionNum.push(this.optionTwoValue.number)
                          }else{
                            this.optionTwoValue = undefined
                          }
                          this.LevelTwoCount.push({
                            'orderId': 'LevelTwo'+ (i+ 1).toString(),
                            'optionLabel': 'OPTION '+ (i+ 1).toString(),
                            'optionValue': this.optionTwoValue
                          })
                        }
                      }else {
                        for (let i = 1; i <= this.LevelNum; i++) {
                          this.LevelTwoCount.push({
                            'orderId': 'LevelTwo'+i.toString(),
                            'optionLabel': 'OPTION '+i.toString(),
                            'optionValue': undefined
                          })
                        }
                      }
                    }
                  )
                }
              }
            )
          }
        }
      }
    }
    
  }

  levelOneDisplayBtn() {
    this.ivrservice.getLevelTwoOptionsList(this.LevelOneId).subscribe(
      (data:any) => {
        this.LevelTwoData = data
        this.LevelTwoCount = []
        if(this.LevelTwoData) {
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelTwoData.length >= i){
              this.optionTwoValue = this.LevelTwoData[i]
            }else{
              this.optionTwoValue = undefined
            }
            this.LevelTwoCount.push({
              'orderId': 'LevelTwo'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionTwoValue
            })
          }
        }else {
          for (let i = 1; i <= this.LevelNum; i++) {
            this.LevelTwoCount.push({
              'orderId': 'LevelTwo'+i.toString(),
              'optionLabel': 'OPTION '+i.toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
    this.levelOneDisplay = !this.levelOneDisplay
  }

  onAddOption() {
    this.levelOneDisplay = true
    this.ivrservice.getLevelTwoOptionsList(this.LevelOneId).subscribe(
      (data:any) => {
        this.LevelTwoData = data
        this.LevelNum = this.LevelNum + 1
        this.LevelTwoCount = []
        if(this.LevelTwoData) {
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelTwoData.length >= i){
              this.optionTwoValue = this.LevelTwoData[i]
            }else{
              this.optionTwoValue = undefined
            }
            this.LevelTwoCount.push({
              'orderId': 'LevelTwo'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionTwoValue
            })
          }
        }else {
          for (let i = 1; i <= this.LevelNum; i++) {
            this.LevelTwoCount.push({
              'orderId': 'LevelTwo'+i.toString(),
              'optionLabel': 'OPTION '+i.toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
  }

  onDeleteLevelOne(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Level 1 Option Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.ivrservice.DeleteLevelOneOptions(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'Level 1 option delete successfully', 'is-success')
            this.ivrservice.GetIVR(this.IvrId).subscribe(
              (data:any) => {
                if(data.id) {
                  this.parentLevel1 = data
                  this.parentLevel1.levels = this.parentLevel1.levels - 1
                  this.ivrservice.UpdateIVR(this.IvrId, this.parentLevel1).subscribe(
                    (res:any) => {
                      console.log('decrease............', res)
                      this.parentEmitter1.emit(res);
                    }
                  )
                }
              }
            )
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Level 1 option deleting has been cancelled', 'is-danger')
      }
    });
  }

  parentEmitterData(response) {
    this.ivrservice.getLevelTwoOptionsList(response.id).subscribe(
      (data:any) => {
        this.LevelTwoData = data
        this.LevelNum = response.sublevels
        this.LevelTwoCount = []
        if(this.LevelTwoData) {
          this.optionNum = []
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelTwoData.length >= i){
              this.optionTwoValue = this.LevelTwoData[i]
              this.optionNum.push(this.optionTwoValue.number)
            }else{
              this.optionTwoValue = undefined
            }
            this.LevelTwoCount.push({
              'orderId': 'LevelTwo'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionTwoValue
            })
          }
        }else {
          for (let i = 1; i <= this.LevelNum; i++) {
            this.LevelTwoCount.push({
              'orderId': 'LevelTwo'+i.toString(),
              'optionLabel': 'OPTION '+i.toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
  }
}

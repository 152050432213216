import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../login.service';
import { FileuploadService } from './fileupload.service';
import Swal from 'sweetalert2';

@Component({
  selector: '[app-fileupload]',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  @Input() musicUpload: string;
  @Input() audioLabel: string
  @Output() filePathEmitter = new EventEmitter();
  public fileToUpload:File = null;
  public userData:any;

  constructor(
    private loginservice: LoginService,
    private fileupload: FileuploadService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  upload() {
		if (this.fileToUpload != null) {
			console.log('file is ' + this.fileToUpload);
			this.fileupload.uploadfiles(this.fileToUpload, this.userData.id).subscribe(
        (data:any) =>{
          this.filePathEmitter.emit(data);
        }
      )
		}
	}
}

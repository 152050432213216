import { SharedService } from "./../../global";
import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpErrorResponse,
} from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class AddPushNotificationService {
    constructor(private http: HttpClient, public sharedService: SharedService) { }

    API_URL = this.sharedService.getGlobalVar();



    addNotification(payload: any) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "add-push-notifications/", payload, { headers: headers });
    }

    getNotificationHistory(filterdata: any, page) {
        const headers = new HttpHeaders().set("Content-Type", "application/json");

        let params = new HttpParams().set('page', page);
        if (filterdata) {
            // Check each key in filterdata for valid values before adding to params
            for (const key in filterdata) {
                if (filterdata.hasOwnProperty(key) && filterdata[key]) {
                    params = params.set(key, filterdata[key]);
                }
            }
        }

        const url = this.API_URL + "pushmessages/";
        return this.http.get(url, { headers: headers, params: params });


    }




    getNotificationList(userid: number) {
        const headers = new HttpHeaders();
        const params = new HttpParams().set("userid", userid.toString());
        const body = { userid: userid }
        headers.append("Content-Type", "application/json");
        return this.http.get(this.API_URL + "push-notifications/", { headers: headers, params: params });
    }

    searchNotification(userid: number) {
        const headers = new HttpHeaders();
        const params = new HttpParams().set("userid", userid.toString());
        const body = { userid: userid }
        headers.append("Content-Type", "application/json");
        return this.http.get(this.API_URL + "push-notifications/", { headers: headers, params: params });

    }

    getnotificationdata(userid: number) {
        const headers = new HttpHeaders();
        const params = new HttpParams().set("userid", userid.toString());

        return this.http.get(this.API_URL + "user-push-notifications/", { headers: headers, params: params })

    }

    deleteNotification(userid: number, notificationId: number) {
        let params = new HttpParams().set('userid', userid.toString());
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.delete(this.API_URL + 'user-push-notifications/' + notificationId + '/delete/', { headers: headers, params: params })
    }

    getnotificationtablelist(userid: number) {
        const headers = new HttpHeaders();

        const params = new HttpParams().set("userid", userid.toString());

        return this.http.get(this.API_URL + "user-push-notifications/", { headers: headers, params: params })
    }



    updateNotification(notificationId: number, isNotificationOn) {

        const headers = new HttpHeaders();
        const body = {
            is_notification_on: isNotificationOn
        }
        return this.http.patch(this.API_URL + "device-details-update/" + notificationId + '/', body, { headers: headers })


    }


    getnotilist(userid: string) {

        let params = new HttpParams();
        params = params.set('userid', userid);
        // params = params.set('device_type',device_type.toString())
        const headers = new HttpHeaders();
        return this.http.get(this.API_URL + "login-user-notifications/", { headers: headers, params: params })

    }

    notificationdisplay(userid: number) {
        const headers = new HttpHeaders();
        let params = new HttpParams().set('userid', userid.toString());


        return this.http.get(this.API_URL + "login-user-notifications/", { headers: headers })
    }

    getUserNotifications(userid: number) {
        const headers = new HttpHeaders();
        let params = new HttpParams().set('userid', userid.toString());
        return this.http.get(this.API_URL + "user-push-notifications/", { headers: headers, params: params })

    }


    getpushNotification(userid: number, device_type: number, usergroup, email, phone, name) {
        const headers = new HttpHeaders();
        let params = new HttpParams();
        params = params.set('userid', userid.toString());
        params = params.set('device_type', device_type.toString())
        params = params.set('group', usergroup)
        params = params.set('email', email)
        params = params.set('phone', phone)
        params = params.set('name', name)

        headers.append('Content-Type', 'application/json');
        return this.http.get(this.API_URL + "login-user-notifications/", { headers: headers, params: params })

    }
}


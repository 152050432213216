import { Component, OnInit } from '@angular/core';
import { InboundRouteService } from '../inbound-route.service';
import swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { Router } from '@angular/router';


@Component({
  selector: '[app-match-pattern-list]',
  templateUrl: './match-pattern-list.component.html',
  styleUrls: ['./match-pattern-list.component.css']
})
export class MatchPatternListComponent implements OnInit {
  public matchpatternData: any;
  userData : any;
  permissions:any;
  permis = false;

  constructor(
    private inboundrouteservice: InboundRouteService,
    private loginservice: LoginService,
    private notifyservice: NotificationService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_q_matchPattern"){
        this.permis = true;
      }
    }
    if(this.userData.group == 'superadmin'){
      this.inboundrouteservice.getMatchPatterns(0).subscribe(
        (data:any) =>{
          this.matchpatternData = data
        }
      )
    }else{
      this.inboundrouteservice.getMatchPatterns(this.userData.id).subscribe(
        (data:any) =>{
          this.matchpatternData = data
        }
      )
    }
  }

  deleteMatchPattern(id) {
    swal.fire({
      title: 'Are you sure ?',
      text: 'The Match Pattern Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.inboundrouteservice.deleteMatchPattern(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'Match pattern delete successfully', 'is-success')
            if(this.userData.group == 'superadmin'){
              this.inboundrouteservice.getMatchPatterns(0).subscribe(
                (data:any) =>{
                  this.matchpatternData = data
                }
              )
            }else{
              this.inboundrouteservice.getMatchPatterns(this.userData.id).subscribe(
                (data:any) =>{
                  this.matchpatternData = data
                }
              )
            }
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Match pattern deleting has been cancelled', 'is-danger')
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class VoiceRecordService {

  constructor(
    private http: HttpClient,
    public sharedService:SharedService,

  ) { } 
  
  API_URL = this.sharedService.getGlobalVar()

  getVoiceRecord(id:number) {
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');    
    return this.http.get(this.API_URL+'voiceRecordDetails/'+id+'/', {headers:headers});
  }

  getVoiceRecordList(userProfileId:number,broadcastId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    return this.http.get(this.API_URL+'voiceRecordList/'+userProfileId+'/'+0+'/', {headers:headers});
  }

  addVoiceRecord(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');    
    return this.http.post(this.API_URL+'voiceRecordCreate/', body, {headers:headers});
  }

  updateVoiceRecord(id:number, body) {
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');    
    return this.http.put(this.API_URL+'voiceRecordDetails/'+id+'/', body, {headers:headers});
  }

  deleteVoiceRecord(id:number) {
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');    
    return this.http.delete(this.API_URL+'voiceRecordDetails/'+id+'/', {headers:headers});
  }
}

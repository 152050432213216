import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../app/usermanagement.service";
import { LoginService } from 'src/app/login.service';
import { DatePipe } from '@angular/common';
import { ReportService } from '../../app/inbound-report/report.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import {ExcelService} from 'src/app/inbound-report/excel.service';
import Swal from 'sweetalert2';
import { API_URL, VOICE_URL } from 'src/global';


@Component({
  selector: 'app-agent-report',
  templateUrl: './agent-report.component.html',
  styleUrls: ['./agent-report.component.css'],
  providers: [DatePipe]
})

export class AgentReportComponent implements OnInit {
  datas:any;
  voiceRecordData:any = new Array();
  searchData:string='';
  date1:string;
  callstatusagent:string;
  DialerReportData: any;
  goPageNumber:string;
  last_page:any;
  reportForm:FormGroup;
  pdfData: any;
  countSlNo: number;
  newarray:any =new Array();
  finalData:any =new Array();
  finalArray:any =new Array();

  head: string[][];
  AgentReportData:any = new Array();
  wordarray:any =new Array();
  current_page: number=1;
  total_page:any;
  last: number;
  fromdate: string='';
  todate: string='';
  goPage: number;
  searchText: string='';
  userData: any;
  callStatus:string = '';
  myDate = new Date();
  today =  new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, 'yyyy-MM-dd');
  ExcelPdfReportData:any = new Array();
  voice_url = VOICE_URL
  userType:any;



  

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginservice: LoginService,
    private datePipe: DatePipe,
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private excelService:ExcelService,

  ) {

  }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.FetchAgentReportData(1);
    this.userType=localStorage.getItem('userType')
  }


  FetchAgentReportData(pageNumberData){
       var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd');
    this.userType=localStorage.getItem('userType')
    var body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchText,"callstatus":this.callStatus, "download":""}
    this.usermanagementservice.load_date_agent_report_by_page(body,pageNumberData).subscribe(
      (res:any) => {
        this.AgentReportData = res["results"]
        this.total_page =  res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
              }
  )
  }


  fetchNumber(i) {
    if(i == 'prev'){
      if (this.current_page > 1){
        this.FetchAgentReportData(this.current_page-1);
      }

    }

    else if (i == 'next'){
      if (this.current_page < this.last){
        this.FetchAgentReportData(this.current_page+1);

      }
     
    }

    else if(i == 'first'){
      this.FetchAgentReportData(1);

    }

    else if(i == 'last'){
      this.FetchAgentReportData(this.last);

    }

    else{

      if (parseInt(i)=== parseInt(i, 10) && i > 0 && i <=  this.last){
        this.FetchAgentReportData(i);

      }
      else{
        Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
      }

    }

    
  }


  searchBox(){
    this.FetchAgentReportData(1)
  }




 exportAsXLSX():void {
  var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
  var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd');
  var body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchText,"callstatus":this.callStatus, "download":"yes"}
  this.usermanagementservice.load_date_agent_report_by_page(body,1).subscribe(
    (res:any) => {
      this.datas = res['results']
  for (let element of this.datas) {
    var push_data = {'didnumber':element['didnumber'],'callerId':element['callerId'],'Country code':element['country_code'],
    'legAstartTime':element['legAstartTime'],'Queue Name':element['quenames'],
    'durationTime':element['durationTime'], 'Connected Duration':element['connectedDurations']}
    if (element['count'] > 1){
      var agent_names = ''
      var agent_numbers=''
      var agent_status = ''
      var comma_val = ''
      for (let agent of element['agentdata']){
        agent_names = agent_names+comma_val+agent['agentName']
        agent_numbers = agent_numbers+comma_val+agent['agentNumber']
        agent_status = agent_status+comma_val+agent['callStatus']  
        comma_val = ','
      }
      push_data['Agent']=agent_names        
      push_data['Agent Number']=agent_numbers 
      push_data['Status']=agent_status 
    }
    else{
      push_data['Agent']= element['agentdata'][0]['agentName']        
      push_data['Agent Number']=element['agentdata'][0]['agentNumber'] 
      push_data['Status']=element['agentdata'][0]['callStatus'] 
    }
    this.newarray.push(push_data)
  }
    this.excelService.exportAsExcelFile(this.newarray, "Agent Report from: "+from+" To: "+to+"");
    this.newarray=[];
    })

}




 downloads() {
    this.countSlNo = 1
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd');  
    var body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchText,"callstatus":this.callStatus, "download":"yes"}

    this.head = [['SlNo','DID', 'Source', 'Country code','Date', 'Department Name','Duration','Connected Duration','Agent','Agent Name','Status']]      
    
    this.usermanagementservice.load_date_agent_report_by_page(body,1).subscribe(
      (res:any) => {
        this.datas = res['results']
        for (let element of this.datas) {
          this.newarray.push(this.countSlNo,element['didnumber'],element['callerId'],element['country_code'],
          element['legAstartTime'], element['quenames'],element['durationTime'],
          element['connectedDurations'])
  
          if (element['count'] > 1){
            var agent_names = ''
            var agent_numbers=''
            var agent_status = ''
            for (let agent of element['agentdata']){
              agent_names = agent_names+'\n'+agent['agentName']
              agent_numbers = agent_numbers+'\n'+agent['agentNumber']
              agent_status = agent_status+'\n'+agent['callStatus']  
            }
            this.newarray.splice(8,0, agent_names,agent_numbers,agent_status)          
          }
          else{
            this.newarray.splice(8,0, element['agentdata'][0]['agentName'],element['agentdata'][0]['agentNumber'],element['agentdata'][0]['callStatus'])
          }
  
            this.finalArray.push(this.newarray)
              this.countSlNo = this.countSlNo + 1
              this.finalData = []
              this.newarray = []
            }


            var pdf = new jsPDF();
            pdf.text('Agent Report', 11, 8);
            pdf.setFontSize(3);
            pdf.setTextColor(100);
            (pdf as any).autoTable({
              head: this.head,
              body: this.finalArray,
              theme: 'grid',
              styles: {fontSize: 4},
              tableWidth: 'auto',
              columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
              margin: { top: 10 },
  
            })
  
            // Open PDF document in new tab
            pdf.output('dataurlnewwindow')
            pdf.save('agent.pdf');
            this.head = []
            this.newarray = []
            this.finalArray = []
      
      
      
      });
    

  }

  getcallrecord(vffile, date) {
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    // this.spinnerService.show();
    this.reportservice.getcallrecord(vffile, date).subscribe((res: any) => {
      this.FetchAgentReportData(1)

    });
  }
}

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LoginService } from "../../login.service";
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { VoiceRecordService } from '../../voice-record/voice-record.service'; 
import { VoicemailService } from '../voicemail.service';
import { ActivatedRoute, Router } from '@angular/router'
import { template } from '@angular/core/src/render3';


@Component({
  selector: 'app-voicemail-template',
  templateUrl: './voicemail-template.component.html',
  styleUrls: ['./voicemail-template.component.css']
})

export class VoicemailTemplateComponent implements OnInit {
  voicemailForm: FormGroup
  userData:any
  voiceRecordData:any = new Array()
  filteredSuggestions:any = new Array()
  greetingFileID:any
  isEdit: boolean = false
  template_id: any
  existing_greeting_playback_id: any

  constructor(
    private loginservice: LoginService,
    private formBuilder: FormBuilder,
    private voicerecordservice: VoiceRecordService,
    private voicemailservice: VoicemailService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true)
    this.voicemailForm = this.formBuilder.group({
      id: [this.userData.id],
      name: ['', Validators.required],
      description: [''],
      greeting: ['', [Validators.required]]
    });

    if(this.userData.group == 'superadmin'){
      this.voicerecordservice.getVoiceRecordList(0,0).subscribe(
        (res:any) => {
          this.voiceRecordData = res
        }
      )
    }else{
      this.voicerecordservice.getVoiceRecordList(this.userData.id,0).subscribe(
        (res:any) => {
          this.voiceRecordData = res
        }
      )
    }

    this.activatedRoute.params.subscribe(params => {
      const templateId = params['id']

      if (templateId) {
        this.isEdit = true;
        this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
          (res: any) => {
            const templateData = res.find(template => template.id == templateId)
            if (templateData) {
              this.template_id = templateData.id //template_id used in updateVoicemailTemplate() api call, since django router needs the id of the template to update it 
              this.existing_greeting_playback_id = templateData.greeting_playback //existing_greeting_playback_id is used in updateVoicemailTemplate() api call if the user does not edit voicefile. This is done so that the user don't need to select the previously selected voicefile again.
              this.voicemailForm.patchValue({
                id: templateData.user,
                name: templateData.name,
                description: templateData.description,
                greeting: templateData.greeting_playback_name,
              })
            }
          },
          error => {
            console.error('Error fetching voicemail template:', error)
          }
        )
      }
    })
  }

  onInput(value: string){
    this.filteredSuggestions = this.filterSuggestions(value)
  }

  filterSuggestions(term: string){
    return this.voiceRecordData.filter(item => item.name.toLowerCase().includes(term.toLowerCase()))
  }

  selectSuggestion(suggestion: any)
  {
    this.voicemailForm.get('greeting').setValue(suggestion.name)
    this.greetingFileID = suggestion.id
    this.filteredSuggestions = []
  }

  onSubmit() {
    if (this.voicemailForm.valid) {
      const formData = this.voicemailForm.value
      formData.greeting = this.greetingFileID
      if (this.isEdit)
      {
        this.updateFunction(formData)
      }
      else 
      {
        this.createFunction(formData)
      } 
    }
    else {
      Swal.fire('Error', 'Please enter all the fields.', 'error')
    }
  }

  updateFunction(formData){
    console.log('formdata',formData)
    if(!formData.greeting){
      formData.greeting = this.existing_greeting_playback_id
    }
    this.voicemailservice.updateVoicemailTemplate(formData, this.template_id).subscribe(
      response => {
        Swal.fire('Success', 'Voicemail template updated successfully!', 'success')
        this.router.navigate(['/template-list'])
      },
      error => {
        console.log('this.template_id',this.template_id)
        const errorMessage = (error && error.error && error.error.error) || 'An error occurred. Please try again.'
        Swal.fire('Error', errorMessage, 'error');
      }
    )
  }

  createFunction(formData){
    console.log(formData)
    this.voicemailservice.createVoicemailTemplate(formData).subscribe(
      response => {
        Swal.fire('Success', 'Voicemail template created successfully!', 'success')
        this.voicemailForm.get('name').reset()
        this.voicemailForm.get('description').reset()
        this.voicemailForm.get('greeting').reset()
      },
      error => {
        const errorMessage = (error && error.error && error.error.error) || 'An error occurred. Please try again.'
        Swal.fire('Error', errorMessage, 'error')
      }
    )
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inbound-feedback',
  templateUrl: './inbound-feedback.component.html',
  styleUrls: ['./inbound-feedback.component.css']
})
export class InboundFeedbackComponent implements OnInit {
  public menus:any;

  constructor() { }

  ngOnInit() {
    this.menus = [
			{"link":"/inboundFeedback/feedBackReport","name":"Inbound FeedBack Report","perm":"cadmin_t_InboundFeedbackReport"},
			{"link":"/inboundFeedback/listUsers", "name":"Users List","perm":"cadmin_a_listUsers"},
			{"link":"/inboundFeedback/addUsers","name":"Add Users Log","perm":"cadmin_a_addUsersLog"},
			{"link":"/inboundFeedback/questionList","name":"Add Questions","perm":"cadmin_t_addQuestions"},
		]
  }

}

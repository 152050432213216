import { Component, OnInit } from '@angular/core';
import { QueuemanagementService } from "../../queuemanagement.service";
import { LoginService } from "../../login.service";
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { Router } from '@angular/router';


@Component({
	selector: 'app-queuelist',
	templateUrl: './queuelist.component.html',
	styleUrls: ['./queuelist.component.css']
})

export class QueuelistComponent implements OnInit {
	public queuelist: any;
	public userData: any;
	permissions: any;
	public cadmin_q_QueueTransfer: string = "false";
	CustomerList: any;
	dropdownList: { id: number, itemName: string }[] = [];
	dropdownSettings = {};
	selectedItems = [];
	selectedItem: any;
	public allCompanies: any = [];
	userType: any;
	isAdminUser: boolean = false;

	searchText: string = "";
	searchCustomer: string = "";
	itemname: string = "";
	current_page: number = 1;
	last: number;
	total_page: any;
	queueListData: any

	constructor(
		private queueservice: QueuemanagementService,
		private loginService: LoginService,
		private notifyservice: NotificationService,
		private router: Router,
		private usermanagementservice: UsermanagementService,
	) { }

	ngOnInit() {
		this.selectedItem = ''
		this.userData = this.loginService.getUserdata(true)
		this.usermanagementservice.getCompanies().subscribe((data: any) => {
			this.allCompanies = data;
			this.dropdownList = this.allCompanies.map((item, index) => ({
				id: index + 1,
				itemName: item.name
				// itemName: item.name.toLowerCase()
			}));
		});

		this.selectedItems = [];
		this.dropdownSettings = {
			singleSelection: true,
			text: "Select Customer",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			enableSearchFilter: true,
			classes: "text-lg",
		};
		if (this.userData.group == 'superadmin') {
			this.isAdminUser = true
			this.queueservice.getQueuesList(this.searchText, '', '', '1').subscribe((data: any) => {
				// this.queuelist = data;
				this.permissions = this.userData.permissions
				console.log(this.permissions)
				for (const perm of this.permissions) {
					if (perm.codename == 'cadmin_q_QueueTransfer') {
						this.cadmin_q_QueueTransfer = "true";
					}
				}

				this.queuelist = data["results"];
				this.total_page = data["total_pages"];
				this.current_page = data["current_page"];
				this.last = data["total_pages"];

				// alert(JSON.stringify(this.cadmin_q_QueueTransfer))
				// alert(API_URL)
			})
		} else {
			this.queueservice.getQueuesList(this.searchText, '', this.userData.id, '1').subscribe((data: any) => {
				// this.queuelist = data;
				this.permissions = this.userData.permissions
				console.log(this.permissions)
				for (const perm of this.permissions) {
					if (perm.codename == 'cadmin_q_QueueTransfer') {
						this.cadmin_q_QueueTransfer = "true";
					}
				}

				this.queuelist = data["results"];
				this.total_page = data["total_pages"];
				this.current_page = data["current_page"];
				this.last = data["total_pages"];

				// alert(JSON.stringify(this.cadmin_q_QueueTransfer))
				// alert(API_URL)
			})
		}

	}

	FetchIvrReportData(pageNo) {

		if (this.selectedItem) {
			this.itemname = this.selectedItem.itemName;
		}
		if (this.userData.group == 'superadmin') {
			this.isAdminUser = true
			this.queueservice.getQueuesList(this.searchText, this.selectedItem.itemName, '', pageNo).subscribe(
				(data: any) => {
					this.queuelist = data["results"];
					this.total_page = data["total_pages"];
					this.current_page = data["current_page"];
					this.last = data["total_pages"];
				}
			)
		} else {
			this.queueservice.getQueuesList(this.searchText, '', this.userData.id, pageNo).subscribe(
				(data: any) => {
					this.queuelist = data["results"];
					this.total_page = data["total_pages"];
					this.current_page = data["current_page"];
					this.last = data["total_pages"];
				}
			)
		}

	}

	onItemSelect(item: any) {
		this.selectedItem = item
	}
	onDeSelect(item: any) {
		this.selectedItem = { itemName: "" };
	}
	onSelectAll(items: any) {
	}
	// o
	onDeSelectAll(items: any) {
		this.selectedItem = { itemName: "" }; // Resetting selectedItem object with an empty itemName
	}

	OnItemDeSelect(item: any) {
		this.selectedItem = { itemName: "" };
	}


	searchBox() {
		this.FetchIvrReportData(1);
	}


	Oneditqueue(id) {
		this.router.navigateByUrl('queue/queueDetails/' + id);

	}

	ShowErrorMsg(msg) {
		this.notifyservice.sendNotification('Queue Error', msg, 'is-danger')
	}

	Ondeletequeue(id) {
		Swal.fire({
			title: 'Are you sure ?',
			text: 'The Department data Deleted',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, Delete it!',
			cancelButtonText: 'No, Cancel it!'
		}).then((result) => {
			if (result.value) {
				this.queueservice.deleteQueueData(id).subscribe(
					(res: any) => {
						console.log('data', res)
						this.notifyservice.sendNotification('Deleted', 'Queue delete successfully', 'is-success')
						if (this.userData.group == 'superadmin') {
							this.queueservice.getQueuesList(this.searchText, '', '', '1').subscribe(
								(data: any) => {
									this.queuelist = data;
									// alert(this.queuelist)
								}
							)
						} else {
							this.queueservice.getQueuesList(this.searchText, '', this.userData.id, '1').subscribe(
								(data: any) => {
									this.queuelist = data;
									// alert(this.queuelist)
								}
							)
						}
					}
				)
			} else {
				this.notifyservice.sendNotification('Cancelled', 'Queue deleting has been cancelled', 'is-danger')
			}
		})
	}


	fetchNumber(i) {
		if (i == "prev") {
			if (this.current_page > 1) {
				this.FetchIvrReportData(this.current_page - 1);
			}
		} else if (i == "next") {
			if (this.current_page < this.last) {
				this.FetchIvrReportData(this.current_page + 1);
			}
		} else if (i == "first") {
			this.FetchIvrReportData(1);
		} else if (i == "last") {
			this.FetchIvrReportData(this.last);
		} else {
			if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
				this.FetchIvrReportData(i);
			} else {
				Swal.fire("Oops..!", "Enter Valid Number", "warning");
			}
		}
	}

}



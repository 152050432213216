import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from "../../app/usermanagement.service";
import { NotificationService } from '../notification/notification.service';
import { SharedService } from './../../global';

@Component({
  selector: 'app-livecallreport',
  templateUrl: './live-call-report.component.html',
  styleUrls: ['./live-call-report.component.css']
})
export class LivecallreportComponent implements OnInit {

  userData: any;
  live_call_datas: any[] = [];
  userType: any;
  permissions: any;
  cadmin_l_liveCallListen = false
  cadmin_l_liveCallWhisper = false
  cadmin_l_liveCallBarge = false
  socket: WebSocket


  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private notifyservice: NotificationService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem("userType")
    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions;
    for (let element of this.permissions) {
      if (element.codename == "cadmin_l_liveCallListen") {
        this.cadmin_l_liveCallListen = true;
      }
      if (element.codename == "cadmin_l_liveCallWhisper") {
        this.cadmin_l_liveCallWhisper = true;
      }
      if (element.codename == "cadmin_l_liveCallBarge") {
        this.cadmin_l_liveCallBarge = true
      }
    }
    this.LiveCallDataWebSocket()
  }


  callMonitor(call_id: any, listen_mode: any) {
    const callRecord = this.live_call_datas.find(call => call.call_record_vf === call_id);
    const secondaryAdminNumber = localStorage.getItem("secondary_number")

    const CallData = {
      dial_one: secondaryAdminNumber || this.userData.phone,
      outbound_caller_id: callRecord.DID,
      listen_call_id: callRecord.call_record_vf,
      listen_mode: listen_mode,
      channel_id: callRecord.channel_id,
      user_id: this.userData.id,
    };

    if (callRecord.destination_type === 'Department' || callRecord.destination_type === 'IVR' ||
      callRecord.destination_type === 'CALLBACK' || callRecord.destination_type === 'VSIP Forward' ||
      callRecord.destination_type === 'Dialout'
    ) {
      CallData['call_type'] = 'INBOUND';
    }

    this.usermanagementservice.live_call_monitor(CallData).subscribe(
      (res: any) => {
        if (res.Success) {
          this.notifyservice.sendNotification("Success", "Call initiated successfully", "is-info");
        }
        else if (res.Failed) {
          this.notifyservice.sendNotification("Error", "Call initiation failed", "is-danger");
        }
      }
    )
  }

  LiveCallDataWebSocket() {
    const WS_URL = this.sharedService.getWebSocket() + 'ws/live_call_report/';
    this.socket = new WebSocket(WS_URL);

    this.socket.onopen = () => {
      console.log('WebSocket connection established.');
    };

    this.socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.status === "Success") {
        console.log('Connection successful. Sending data...');

        const data = {
          userId: this.userData.id,
          usertype: this.userData.usertype
        };

        this.socket.send(JSON.stringify(data));
      }

      else if (response.type === "initial_cache_data") {
        this.live_call_datas = response.data
      }

      else if (response.type === "cache_data") {
        if (response.action === "add") {
          console.log('Adding live call data:', response.data);
          this.live_call_datas = [...this.live_call_datas, ...response.data];
          console.log("live_call_datas rest: ", this.live_call_datas)
        }
        else if (response.action === "update") {
          console.log('Updating live call data:', response.data);
          this.live_call_datas = this.live_call_datas.map(item =>
            item.call_record_vf === response.data[0].call_record_vf ? response.data[0] : item
          );
          console.log("live_call_datas rest: ", this.live_call_datas)
        }
        else if (response.action === "delete") {
          console.log('Deleting live call data with call_record_vf:', response.call_record_vf);
          this.live_call_datas = this.live_call_datas.filter(item =>
            item.call_record_vf !== response.call_record_vf
          );
          console.log("live_call_datas rest: ", this.live_call_datas)
        }
        else {
          console.log('Unexpected cache_data action:', response.action);
        }
      }

      else {
        console.log('Unexpected response:', response);
      }
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed.');
    };
  }

  ngOnDestroy() {
    if (this.socket) {
      this.socket.close();
      console.log('WebSocket connection closed in ngOnDestroy.');
    }
  }


  isAgentDataVisible(data: any): boolean {
    return (
      (
        data.destination_type === 'Department' ||
        data.destination_type === 'IVR' ||
        data.destination_type === 'Autocall' ||
        data.destination_type === 'VSIP Outgoing' ||
        data.destination_type === 'VSIP Forward' ||
        data.destination_type === 'Dialout' ||
        data.destination_type === 'Autocall Callback' ||
        data.destination_type === 'Dialer Callback' ||
        data.destination_type === 'VSIP Callback' ||
        data.destination_type === 'Time Condition'
      ) &&
      data.call_type === 1
    ) ||
    (
      (
        data.destination_type === 'Dialout' || 
        data.destination_type === 'VSIP Outgoing' || 
        data.destination_type === 'VSIP Forward'
      ) && 
      data.call_type === 0
    )
  }


  isActionButtonsVisible(data: any): boolean {
    return (
      (this.cadmin_l_liveCallListen || this.cadmin_l_liveCallWhisper || this.cadmin_l_liveCallBarge) &&
      (
        data.destination_type === 'Department' ||
        data.destination_type === 'IVR' ||
        data.destination_type === 'Autocall' ||
        data.destination_type === 'Dialout' ||
        data.destination_type === 'VSIP Outgoing' ||
        data.destination_type === 'VSIP Forward' ||
        data.destination_type === 'Autocall Callback' ||
        data.destination_type === 'Dialer Callback' ||
        data.destination_type === 'VSIP Callback' ||
        data.destination_type === 'Time Condition'
      ) &&
      this.userType === 'admin' &&
      data.call_type === 1
    );
  }

}

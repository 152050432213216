import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { ReportService } from 'src/app/inbound-report/report.service';

@Component({
  selector: 'app-address-book-filter',
  templateUrl: './address-book-filter.component.html',
  styleUrls: ['./address-book-filter.component.css']
})

export class AddressBookFilterComponent implements OnInit {
  @Output() filterData = new EventEmitter<any>()

  showFilterDiv = false
  userData: any
  tagList: [] = []
  selectedTags: string[] = []


  constructor(
    private loginservice: LoginService,
    private reportservice: ReportService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }

  toggleFilterField() {
    this.showFilterDiv = !this.showFilterDiv
    if(this.showFilterDiv){
      this.getAllFilterTags();
    }  
  }

getAllFilterTags(){
  let user_id: any;

  if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser'  || this.userData.usertype == 'virtualUser') {
    user_id = this.userData.admin;
  } else {
    user_id = this.userData.id;
  }

  this.reportservice.getAllTags(user_id).subscribe(
    (res: any) => {
      this.tagList = res[0].tagarray
    }
  )
}

  SelectedTags(tag: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedTags.push(tag);
    } else {
      this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag !== tag);
    }
  }
  
  applyFilters(){
    let filter_data = {}

    if (this.selectedTags){
      const tagsString = this.selectedTags.join(',')
      filter_data['tags'] = tagsString
    }

    this.filterData.emit(filter_data)
  }

  clearFilters(){
    this.showFilterDiv = false
    this.selectedTags = []
    this.filterData.emit({})
  }

}

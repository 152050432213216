import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-inbound-route]',
  templateUrl: './inbound-route.component.html',
  styleUrls: ['./inbound-route.component.css']
})
export class InboundRouteComponent implements OnInit {
	public menus:any

  constructor() { }

  ngOnInit() {
		this.menus = [
			{"link":"/route/inboundroute","name":"InboundRoute","perm":"cadmin_q_inboundReportList"},
			{"link":"/route/timecondition", "name":"Timecondition","perm":"cadmin_q_timeCondition"},
			{"link":"/route/matchpattern","name":"Matchpattern","perm":"cadmin_q_matchPattern"},
		]
  } 

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';
import { LoginService } from 'src/app/login.service';
import { DialerService } from 'src/app/dialer/dialer.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
@Component({
  selector: 'app-add-dialer',
  templateUrl: './add-dialer.component.html',
  styleUrls: ['./add-dialer.component.css']
})
export class AddDialerComponent implements OnInit {
  keyword = 'name';
  keyword1 = 'name';
  dialerFileToUpload: File;
  didList:any;
  subUser:any;
  subUserIds:any;
  subUserList:any;
  DIDNumber:number = 0;
  DIDNumberInitial:string = '';
  subUserInitial:string = '';
  UserProfileId:number = 0;
  isAdmin:string = 'false'
  userData:any;
  users:any = new Array();
  projectName:string='';
  constructor(
    private router: Router,
    private inboundRouteService: InboundRouteService,
    private loginService: LoginService,
    private dialerService: DialerService,
    private notifyService: NotificationService
  ) { }
  
  ngOnInit() {
    this.users = []
    this.subUserIds = []
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
  }


  handleFileInput(files: FileList) {
    console.log("file data", files)
    this.dialerFileToUpload = files.item(0);
    console.log("file", this.dialerFileToUpload)
    
  }
  
  selectDID(item) {
    this.DIDNumber = item.id
    

  }


  selectSubUser(item) {
    this.subUser = item.id
    var temp = {'id':item.id, 'name': item.name}
    this.users.push(temp)
    this.subUserIds.push(item.id)
    this.subUserIds = this.subUserIds.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    })
    console.log("ids", this.subUserIds )
  }

  deleteUser(item){
    console.log("uuuu", item.name)
    // this.users.remove(item)
    const index = this.users.indexOf(item, 0);
    if (index > -1) {
      this.users.splice(index, 1);
      this.subUserIds.splice(index, 1);
    }
    
  }

  onChangeSearchDID(val: string) {
    this.inboundRouteService.searchDID(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
      }
    )
  }


  onChangeSearchSubUser(val: string) {
    this.dialerService.searchSubUser(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.subUserList = data
        console.log('search did list', this.didList)
      }
    )
  }



  onFocusedDID(e){
    this.inboundRouteService.getDIDList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
      }
    )
  }

  onFocusedSubUser(e){
    this.dialerService.getSubUserList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.subUserList = data
      }
    )
  }
  clearDatas(){
    this.DIDNumber = 0
    
  }


  clearDatasSubUser(){
    this.subUser = 0
  }


  onUploadDialer(){
    
    if(this.projectName == ''){
      Swal.fire('Oops..!', 'Please Enter All The Fields', 'warning')
    }else if(this.DIDNumber == 0) {
      Swal.fire('Oops..!', 'Please Select DID Number', 'warning')
    }else if(this.subUserIds.lenght > 0){
      Swal.fire('Oops..!', 'Please select SubUsers', 'warning')
    }else {
      
      this.dialerService.uploadDialer(this.dialerFileToUpload, this.projectName, this.UserProfileId, this.DIDNumber, this.subUserIds).subscribe(
        (res:any) => {
          let updateMemberData = res
          console.log("data", res)
          
          this.notifyService.sendNotification('Updated', 'Extension number update successfully', 'is-success')
        }
      )
    }
  }

}

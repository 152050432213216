import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-calling-management',
  templateUrl: './auto-calling-management.component.html',
  styleUrls: ['./auto-calling-management.component.css']
})
export class AutoCallingManagementComponent implements OnInit {
  isAdmin: string;
  menus: { link: string; name: string; perm: string; }[];

  constructor() { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    if(this.isAdmin == 'true'){
      this.menus = [
        {"link":"/autoCall/autoCallList", "name":"Autocall Clients","perm":"cadmin_a_autoCallList"},
        {"link":"/autoCall/addAutoCall/0", "name":"Add Autocall Clients","perm":"cadmin_a_autoCallingManagement"},
      ]
    }else {
      this.menus = [
        {"link":"/autoCall/addAutoCall/0", "name":"Add Autocall Clients","perm":"cadmin_a_autoCallingManagement"},
      ]
    }
  }

}

import { Component, OnInit } from '@angular/core'
import Swal from 'sweetalert2'
import { LoginService } from "../../login.service"
import { FormGroup, FormBuilder, FormArray } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service'
import { ExtensionService } from 'src/app/extension/extension.service' 
import { Validators } from '@angular/forms'
import { CallTransferService } from '../call-transfer.service'

@Component({
  selector: 'app-call-transfer-create-template',
  templateUrl: './call-transfer-create-template.component.html',
  styleUrls: ['./call-transfer-create-template.component.css']
})
export class CallTransferCreateTemplateComponent implements OnInit {
  callTransferForm: FormGroup
  userData: any
  agentUsersList: any[] = [] // To store the list of agent users
  selectedAgentMobile: string | null = null // To store the selected agent's mobile number
  numberType: string[] = [] // to sotre the number type of each shortCode array
  sipUsersList: any[] = []
  dialerAgentList: any[] = []
  submitted: boolean = false // this var is used in order to ensure all fields are entered when submit button is clicked
  isEdit: boolean = false
  template_id: any
  deletedShortcodeIds: number[] = [] // to store the id's of deleted shortCodes while updating

  constructor(
    private loginservice: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usermanagementservice: UsermanagementService,
    private extensionservice: ExtensionService,
    private calltransferservice: CallTransferService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true)
    this.callTransferForm = this.formBuilder.group({
      user_id: this.userData.id,
      name: ['', Validators.required],
      description: [''],
      shortCodes: this.formBuilder.array([])
    })

    this.activatedRoute.params.subscribe(params => {
      const templateId = params['id']

      if (templateId) {
        this.isEdit = true
        this.calltransferservice.getCallTransferTemplate(this.userData.id).subscribe(
          (res: any) => {
            const templateData = res.find(template => template.id == templateId)
            if (templateData) {
              this.template_id = templateData.id
              console.log("templateData", templateData)
              this.callTransferForm.patchValue({
                name: templateData.name,
                description: templateData.description,
              })
              const shortCodesArray = templateData.shortcodes.map(shortcode => this.createShortCodeGroup(shortcode))
              this.callTransferForm.setControl('shortCodes', this.formBuilder.array(shortCodesArray))
              this.fetchDeptAndDialerAgents()
              this.fetchSIPusers()  
            }
          }
        )
      }
    })
  }

/*
createShortCodeGroup() function is to create new short code array to insert short codes. 
It is also made to be compatible for editing situation as well.
it is for that reason it has (data ? data.short_code : '') 
*/
  createShortCodeGroup(data: any = null) {
    return this.formBuilder.group({
      shortCode_id: [(data ? data.id : '')],
      shortCode: [(data ? data.short_code : ''), [Validators.required, Validators.maxLength(9), Validators.pattern(/^[0-9]+$/)]],
      numberType: [(data ? data.number_type : ''), Validators.required],
      number: [(data ? data.number : ''), Validators.required]
    })
  }

  addShortCodeArray() {
    this.submitted = false
    const shortCodesArray = this.callTransferForm.get('shortCodes') as FormArray
    shortCodesArray.push(this.createShortCodeGroup())
  }

  deleteShortCodeArray(index: number) {
    const shortCodesArray = this.callTransferForm.get('shortCodes') as FormArray
    console.log('shortCodesArray',shortCodesArray)

    if (this.isEdit) { // this is to delete a short during updation
      const shortcodeIdToDelete = shortCodesArray.at(index).get('shortCode_id').value
      console.log('shortcodeIdToDelete',shortcodeIdToDelete)
      this.calltransferservice.deleteShortcode(shortcodeIdToDelete).subscribe(
        () => {
          console.log('Shortcode deleted successfully');
        },
        (error) => {
          console.error('Error deleting shortcode:', error);
        }
      )
    }

    shortCodesArray.removeAt(index)
    this.numberType[index] = ''
    }

  onNumberTypeChange(event: any, index:any) {
    const selectedValue = event.target.value
    console.log("index", index)
    this.numberType[index] = selectedValue

    if (selectedValue === 'Agent') {
      this.fetchDeptAndDialerAgents()
    }
    else if (selectedValue === 'SIP user'){
      this.fetchSIPusers()
    }
  }

  fetchDeptAndDialerAgents() {
    this.usermanagementservice.getAgentUsers(this.userData.id).subscribe(
      (response: any) => {
        console.log("Dept Agent Users: ", response)
        if (response.status === '1') {
          this.agentUsersList = response.data.map(agent => ({
            name: agent.name,
            number: agent.number
          }))
        }
      }
    )
    this.usermanagementservice.getUserList(this.userData.id).subscribe(
      (response: any) => {
        console.log("Dialer Agent Users: ", response)
        this.dialerAgentList = response.map(agent => ({
          name: agent.name,
          number: agent.mobile
        }))
        this.agentUsersList = this.agentUsersList.concat(this.dialerAgentList)
        console.log("agentUsersList: ", this.agentUsersList)
      }
    )
  }

  fetchSIPusers(){
    this.extensionservice.getVirtualSipList(this.userData.id).subscribe(
      (response: any) => {
        console.log("SIP Users: ", response)
        this.sipUsersList = response.data
      }
    )
  }

  onFormSubmit(){
    this.submitted = true
    if(this.callTransferForm.valid){
      const callTransferFormData = this.callTransferForm.value
      if (this.isEdit)
      {
        this.updateFunction(callTransferFormData)
      }
      else 
      {
        this.createFunction(callTransferFormData)
      } 
    }
    else {
      Swal.fire('Error', 'Please enter all the fields.', 'error')
    }
  }

  updateFunction(callTransferFormData){
    console.log('update function callTransferFormData' , callTransferFormData)
    this.calltransferservice.updateCallTransferTemplate(callTransferFormData, this.template_id).subscribe(
      response => {
        Swal.fire('Success', 'Call transfer template updated successfully!', 'success')
        this.router.navigate(['/call-transfer-list'])
      },
      error => {
        console.log('this.template_id',this.template_id)
        const errorMessage = (error && error.error && error.error.error) || 'An error occurred. Please try again.'
        Swal.fire('Error', errorMessage, 'error')
      }
    )
  }

  createFunction(callTransferFormData){
    console.log("callTransferForm submitted", callTransferFormData)
    this.calltransferservice.createCallTransferTemplate(callTransferFormData).subscribe(
      (response: any) => {
        Swal.fire('Success', 'Call transfer template created successfully!', 'success')
        this.router.navigate(['/call-transfer-list'])
      },
      error => {
        const errorMessage = (error && error.error && error.error.error) || 'An error occurred. Please try again.'
        Swal.fire('Error', errorMessage, 'error')
      }  
    )
  }
}

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { SharedService } from 'src/global'

@Injectable({
    providedIn: 'root'
})

export class VoicemailService {
    constructor(
        private http:HttpClient,
        public sharedService:SharedService
    ) { }

    API_URL = this.sharedService.getGlobalVar()

    createVoicemailTemplate(formData: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.post(this.API_URL+'router/voicemail/',formData, {headers:headers})
    }

    getVoicemailTemplate(id:number){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.get(this.API_URL+'router/voicemail/?id=' + id, {headers:headers})
    }

    deleteVoicemailTemplate(template_id:number, user_id:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        let body = { 'template_id':template_id, 'user_id':user_id }
        return this.http.request('delete',this.API_URL+'router/voicemail/' + template_id + '/', {headers:headers, body:body})
    }

    updateVoicemailTemplate(formData: any, template_id:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.put(this.API_URL+'router/voicemail/' + template_id + '/',formData, {headers:headers})
    }
}
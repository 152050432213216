import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from 'src/global';

@Injectable({
  providedIn: 'root'
})


export class CallSummaryReportService {
    constructor(private http: HttpClient, public sharedService: SharedService) {}

    API_URL = this.sharedService.getGlobalVar();


    getCallSummaryReport(call_id:string, user_id:number){      
      const header = new HttpHeaders();
      header.append("Content-Type", "application/json");
      const payload = {
        "call_id": call_id,
        "user_id": user_id
      }
      return this.http.post(this.API_URL + "callSummaryReport/", payload, {headers: header})

    }
  

  }
import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class BreakTimeReportService {
    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ) { }

    API_URL = this.sharedService.getGlobalVar();
    header = new HttpHeaders().set("Content-Type", "application/json");


    getAgentUser(data: any) {
        return this.http.post(this.API_URL + "usermanagement/fetchAgentorDelete/", data, {headers: this.header})
    }

    getAgentUsersList(data: any) {
        return this.http.post(this.API_URL + "usermanagement/combined-agents/", data, { headers: this.header });
    }

    getBreakTimeReport(data: any) {
        return this.http.post(this.API_URL + "usermanagement/agent_break_report/", data, {headers: this.header})
    }

    getBreakTimeDetail(data: any, pageNumber: number) {
        return this.http.post(this.API_URL + `usermanagement/agent_break_report/?page=${pageNumber}`, data, { headers: this.header })
    }


}

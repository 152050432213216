import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl } from '@angular/forms'
import { SharedService } from '../global';

@Injectable({
	providedIn: 'root'
})
export class QueuemanagementService {

	constructor(
		private http: HttpClient,
		public sharedService: SharedService

	) {
	}

	API_URL = this.sharedService.getGlobalVar()

	getQueues(userProfileId: number, active: Boolean = true) {
		console.log("==============sfsfsf", this.API_URL)
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.get(this.API_URL + 'queueList/' + userProfileId + '/', { headers: headers });
	}
	getQueuesList(search, searchCustomer, userid, current_page) {
		const body = { "search": search, "searchCustomer": searchCustomer, 'userid': userid }
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.post(this.API_URL + 'queueLists/?page=' + current_page, body, { headers: headers })
	}

	searchQueue(val: string, userProfileId: number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.get(this.API_URL + 'queueList/' + userProfileId + '/?search=' + val, { headers: headers });
	}

	AddQueue(body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		console.log('bodyyyyyyyy', body);
		return this.http.post(this.API_URL + 'queueList/0/', body, { headers: headers })
	}

	EditQueue(id, body) {
		console.log(body);
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.put(this.API_URL + 'queueDet/' + id + "/", body, { headers: headers })
	}

	deleteQueueData(id) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.delete(this.API_URL + 'queueDetails/' + id + '/', { headers: headers })
	}

	oneQueue(id: number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.get(this.API_URL + 'queueDetails/' + id + '/', { headers: headers });
	}
	ExistQueue(qName, userId, userName) {
		const headers = new HttpHeaders();
		const body = { "qName": qName, "userId": userId, userName: userName }
		console.log(qName)
		headers.append('Content-Type', 'application/json');
		return this.http.post(this.API_URL + 'ExistQueue/', body, { headers: headers });
	}
}

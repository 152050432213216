import { Component, OnInit } from '@angular/core';
import { LeadsquaredService } from './leadsquare.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from "sweetalert2";

@Component({
  selector: 'app-leadsquared-integration',
  templateUrl: './leadsquared-integration.component.html',
  styleUrls: ['./leadsquared-integration.component.css']
})
export class LeadsquaredIntegrationComponent implements OnInit {

  crmConfigValid: boolean = true;
  crmConfigErrors: any[] = [];
  sendDataToExternalAPIID: string = "";
  userProfile: any;
  leadsForm: FormGroup;
  vsipEnabled: boolean = false;
  agentListArray: any[] = [];

  constructor(
    private leadSquaredService: LeadsquaredService,
    private fb: FormBuilder
  ) {
    this.leadsForm = this.fb.group({
      agentName: ["", Validators.required],
      agentNumber: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
      apiHost: ["", [Validators.required, Validators.pattern(/^https?:\/\/\S*$/)]],
      crmEmail: ["", [Validators.required, Validators.email]],
      vsipEnabled: [false],
      vsipId: [""]
    });

  }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.checkCRMConfig();
    this.initializeAgentList();
  }



  checkCRMConfig() {
    this.leadSquaredService.getCRMConfig(this.userProfile.id).subscribe((res: any) => {
      if (res["status"] == "1") {
        const data = res["data"]
        this.sendDataToExternalAPIID = data["id"]
        if (data["format"] != "leadsquared") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Format is not configured")
        }
        if (data["autocall"] !== "true" && data["autocall"] !== "false") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall is not configured");
        }

        if (data["autocall_did"] == "") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall DID is not configured")
        }

        if (data["autocall_channel_id"] == "") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall channel id is not configured")
        }
      }
      else if (res["status"] == "0" && res["error"] == "Config not found.") {
        this.crmConfigValid = false;
        this.crmConfigErrors.push("CRM configuration is not found.")
      }

    })
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  onVsipChange() {
    this.vsipEnabled = this.leadsForm.value.vsipEnabled
    const vsipIdControl = this.leadsForm.get("vsipId")
    if (this.vsipEnabled) {
      vsipIdControl.setValidators([Validators.required]);
    } else {
      vsipIdControl.clearValidators();
    }

    vsipIdControl.updateValueAndValidity();
  }


  onSubmit() {
    this.markFormGroupAsTouched(this.leadsForm);
    if (this.leadsForm.valid) {
      const data = {
        SendDataToExternalAPI: this.sendDataToExternalAPIID,
        agent_name: this.leadsForm.value.agentName,
        agent_number: this.leadsForm.value.agentNumber,
        api_host: this.leadsForm.value.apiHost,
        crm_email: this.leadsForm.value.crmEmail,
        vsip_enabled: this.leadsForm.value.vsipEnabled,
        vsip_id: this.leadsForm.value.vsipId
      }

      this.leadSquaredService.addCRMAgents(data).subscribe((res: any) => {
        if (res["status"] === "1") {
          Swal.fire("Success", "Agent created successfully.", "success")
          this.initializeAgentList();
          this.leadsForm.reset();
          this.leadsForm.patchValue({ vsipEnabled: false });
        } else {
          Swal.fire("Something went wrong!", "Agent creation failed", "error")
        }
      });

    }
  }

  initializeAgentList() {
    const qParams = [
      { key: "scope", value: "agent_list" },
      { key: "user_id", value: this.userProfile.id }
    ]
    this.leadSquaredService.getCRMAgents(qParams).subscribe((res: any) => {
      if (res["status"] === "1") {
        this.agentListArray = res["data"]
      }
    })
  }

  onAgentDelete(agentId: string) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The agent will be deleted.",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete agent.",
      cancelButtonText: "No, Dont delete.",
    }).then((resp) => {
      if (!resp.dismiss) {
        this.leadSquaredService.deleteAgent(agentId).subscribe((res: any) => {
          if (res["status"] === "1") {
            Swal.fire("Success", res["message"], "success")
            this.initializeAgentList();
          }
        })
      }
    })


  }

}

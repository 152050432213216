import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InboundRouteService } from "src/app/inbound-route/inbound-route.service";
import { LoginService } from "src/app/login.service";
import { DialerService } from "src/app/dialer/dialer.service";
import Swal, { SweetAlertOptions } from "sweetalert2";

import { NotificationService } from "src/app/notification/notification.service";
import { BroadcastService } from "src/app/voicebroadcast/voicebroadcast.service";

import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
@Component({
  selector: "app-add-dialer-excel",
  templateUrl: "./add-dialer-excel.component.html",
  styleUrls: ["./add-dialer-excel.component.css"],
})
export class AddDialerExcelComponent implements OnInit {
  ExtensionData: any = new Array();
  keyword = "name";
  keyword1 = "name";
  dialerFileToUpload: File;
  didList: any;
  subUser: any;
  subUserIds: any;
  subUserList: any;
  DIDNumber: number = 0;
  DIDNumberInitial: string = "";
  subUserInitial: string = "";
  UserProfileId: number = 0;
  isAdmin: string = "false";
  userData: any;
  SUBU: any;
  sublist: any;
  project: any;
  projectlist: any;
  category: any;
  categorylist: any;
  subcategory: any;
  subcategorylist: any;
  ProjectInitial: any;
  SUBInitial: any;
  categoryInitial: any;
  subcategoryInitial: any;
  users: any = new Array();
  projectName: string = "";
  permissions: any;
  permis = false;
  channelId: string = "0";
  dialRecentAgent: string = "0";



  userType : any;
  adminId : any;







  public newarray: any = [
    {
      SlNumber: "",
      Extension: "",
      Name: "",
      PhoneNumber: "",
      Description: "",
    },
  ];
  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router,
    private inboundRouteService: InboundRouteService,
    private loginService: LoginService,
    private dialerService: DialerService,
    private notifyService: NotificationService,
    private broadcastservice: BroadcastService,
    private route: Router
  ) {}

  ngOnInit() {
    this.users = [];
    this.subUserIds = [];
    this.isAdmin = localStorage.getItem("isAdmin");
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions;
    this.userType =this.userData.usertype

    for (let element of this.permissions) {
      console.log(element.codename);
      if (element.codename == "cadmin_v_dialerexcel") {
        this.permis = true;
      }
    }



    if (this.userType == 'dialerUser') {      
      this.SUBU = this.userData.id  
    }

    if (this.isAdmin == "false") {
      this.UserProfileId = this.userData.id;
    }

    this.onFocusedDID() // to fetch subusers
  }
  // DID AutoCompleted Functions

  selectProject(item) {
    this.project = item.id;
    this.onFocusedcategory()
  }

  onChangeSearchProject(val: string) {
    this.dialerService.searchdialerprojectList(val, this.SUBU).subscribe(
    (data: any) => {
      this.projectlist = data;
    });
  }

  onFocusedProject() {
    this.dialerService.getdialerprojectList(this.SUBU).subscribe(
    (data: any) => {
      this.projectlist = data;
    });
  }

  onClearProject() {
    this.project = 0;
  }

  //  user AutoCompleted Functions

  selectDID(item) {
    this.SUBU = item.id;
    this.onFocusedProject()
  }

  onChangeSearchDID(val: string) {
    this.dialerService.searchdialeruserList(val, this.UserProfileId).subscribe(
    (data: any) => {
      this.sublist = data;
      console.log("search did list", this.didList);
    });
  }

  onFocusedDID() {
    this.dialerService.getdialeruserList(this.UserProfileId).subscribe(
    (data: any) => {
      this.sublist = data;
      console.log("did focused list", this.didList);
    });
  }

  onClearDID() {
    this.SUBU = 0;
  }

  // category AutoCompleted Functions

  selectcategory(item) {
    this.category = item.id;
    this.onFocusedsubcategory()
  }

  onChangeSearchcategory(val: string) {
    this.dialerService
      .searchdialercategoryList(val, this.project)
      .subscribe((data: any) => {
        this.categorylist = data;
      });
  }

  onFocusedcategory() {
    this.dialerService.getdialercategoryList(this.project).subscribe(
    (data: any) => {
      this.categorylist = data;
    });
  }

  onClearcategory() {
    this.category = 0;
  }
  // subcategory AutoCompleted Functions

  selectsubcategory(item) {
    this.subcategory = item.id;
  }

  onChangeSearchsubcategory(val: string) {
    this.dialerService
      .searchdialersubcategoryList(val, this.category)
      .subscribe((data: any) => {
        this.subcategorylist = data;
      });
  }

  onFocusedsubcategory() {
    this.dialerService.getdialersubcategoryList(this.category).subscribe(
    (data: any) => {
      this.subcategorylist = data;
    });
  }

  onClearsub() {
    this.subcategory = 0;
  }
  handleFileInput(files: FileList) {
    console.log("file data", files);
    this.dialerFileToUpload = files.item(0);
    console.log("file", this.dialerFileToUpload);
  }

  selectSubUser(item) {
    this.subUser = item.id;
    var temp = { id: item.id, name: item.name };
    this.users.push(temp);
    this.subUserIds.push(item.id);
    this.subUserIds = this.subUserIds.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    console.log("ids", this.subUserIds);
  }

  deleteUser(item) {
    console.log("uuuu", item.name);
    // this.users.remove(item)
    const index = this.users.indexOf(item, 0);
    if (index > -1) {
      this.users.splice(index, 1);
      this.subUserIds.splice(index, 1);
    }
  }

  onChangeSearchSubUser(val: string) {
    this.dialerService
      .searchSubUser(val, this.UserProfileId)
      .subscribe((data: any) => {
        this.subUserList = data;
        console.log("search did list", this.didList);
      });
  }

  onFocusedSubUser(e) {
    this.dialerService
      .getSubUserList(this.UserProfileId)
      .subscribe((data: any) => {
        this.subUserList = data;
      });
  }
  clearDatas() {
    this.DIDNumber = 0;
  }

  clearDatasSubUser() {
    this.subUser = 0;
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }


  openAlert(): void {
    const options: SweetAlertOptions = {
      title: 'Error',
      text: 'An error occurred!',
      confirmButtonText: 'OK'
    };
  
    Swal.fire(options);
  }
  


  
  onUploadDialerExcel() {

    if (!this.dialerFileToUpload || this.dialerFileToUpload.size === 0) {
      Swal.fire('Oops..!', 'Please select a non-empty Excel file', 'warning');
      return; // Exit the function early if the file is empty
    }

    
    this.dialRecentAgent;
    this.adminId = this.projectlist[0].user

    if (this.project == "") {
      Swal.fire("Oops..!", "Please Choose Project Name", "warning");
      // this.reloadCurrentRoute();
      return;
    } else if (this.SUBU == 0) {
      Swal.fire("Oops..!", "Please Select DID Number", "warning");
      // this.reloadCurrentRoute();
      return;
    } else {
      // this.spinnerService.show();
      this.dialerService
        .onUploadDialerExcel(
          this.channelId,
          this.dialerFileToUpload,
          this.project,
          this.category,
          this.subcategory,
          this.SUBU,
          this.adminId,
          this.dialRecentAgent,
          this.userType
        )
        .subscribe((res: any) => {
          this.ExtensionData = res.data;
          console.log("data", this.ExtensionData);
          this.category = "";
          this.subcategory = "";
          console.log("<<<<<<<<<<<<<<", res.status, ">>>>>>>>>>>>");

          if (res.status == "1") {
            // this.spinnerService.hide();

            this.route.navigateByUrl("/dialer/dialerList");
            Swal.fire("success..!", "Excel uploaded successfully", "success");
          } else if (res.status == "2") {
            // this.spinnerService.hide();
            Swal.fire("oops..!", res.error, "warning");
            // this.reloadCurrentRoute();
          } else if (res.status == "3") {
            this.spinnerService.hide();
            console.log("::::::::", res.error);
            Swal.fire(
              "oops..!",
              "Space is not allowed in file name !!",
              "warning"
            );
            this.reloadCurrentRoute();
          }
         

          // this.notifyService.sendNotification('Updated', 'Extension number update successfully', 'is-success')
        },(error)=>{
          this.openAlert(); 
        });
    }
  }

  sampleBroadcastSheet(): void {
    this.broadcastservice.downloadsamplesheet(
      this.newarray,
      "DialerExcelSheet"
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-auto-call',
  templateUrl: './list-auto-call.component.html',
  styleUrls: ['./list-auto-call.component.css']
})

export class ListAutoCallComponent implements OnInit {

  datas: any;
  public searchText : string;
  permissions:any;
  permis = false;


  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,

  ) { }

  ngOnInit() {
    this.loadClients();
  }


  loadClients(){
    this.usermanagementservice.getAutocallClients().subscribe(
      (data:any) =>{
        this.datas = data
      }
     )
  }


  Ondeleteautocall(id, user_name){

    var body =  {
      "type":"delete", 
      "id":id,
      "user_name":user_name
    }

    this.usermanagementservice.getAutocallDetails(body).subscribe(
      (data:any) => {  
        var data = data
        if(data.status == 0){
          Swal.fire('Error', data.message, 'warning')
        }
        else{
          Swal.fire('Success', data.message, 'success')
          this.loadClients();  
        }
  }
  )

  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SmsService } from '../sms.service';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ivr-templates',
  templateUrl: './ivr-templates.component.html',
  styleUrls: ['./ivr-templates.component.css']
})
export class IvrTemplatesComponent implements OnInit {

  templateForm: FormGroup
  userData: any;
  templateId: string | null = null;
  templateList: any[] = [];
  ivrList: any[] = [];
  isCustomNumber: boolean = false;

  constructor(
    private fb: FormBuilder,
    private smsService: SmsService,
    private loginService: LoginService
  ) { 
  
    this.templateForm = this.fb.group({
      name: ["", Validators.required],
      ivr: ["", Validators.required],
      dtmf: ["", Validators.required],
      isPromotional: [false],
      isCustomNumber: [false],
      customNumbers: [""],
      content: ["", Validators.required]
    })

  }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
    this.getIVRList();
    this.getTemplateList();
    
  }

  getTemplateList() {
    
    
    const qParams = [
      {key: "user_id", value: this.userData.id}
    ]
    
    this.smsService.getIVRTemplateList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.templateList = res.data
      }
    })
  }

  getIVRList() {
    this.smsService.getIVRList(this.userData.id).subscribe((res: any) => {
      this.ivrList = res;   
    })
  }

  onSubmit() {

    this.markFormGroupAsTouched(this.templateForm);

    if (this.templateForm.valid) {
      let data = {
        name: this.templateForm.value.name,
        user: this.userData.id,
        ivr: this.templateForm.value.ivr,
        dtmf_value: this.templateForm.value.dtmf,
        promotional_sms: this.templateForm.value.isPromotional,
        enable_custom_number: this.templateForm.value.isCustomNumber,
        custom_number: this.templateForm.value.customNumbers,
        template_contetnt: this.templateForm.value.content
      }
      if (this.templateId) {
        data["id"] = this.templateId
        this.smsService.updateIVRTemplate(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template updated successfully.", "success");
            this.templateForm.reset();
            this.templateForm.patchValue({ ivr: "", isPromotional: false, isCustomNumber:false });
            this.templateId = null;
            this.isCustomNumber = false;
            this.getTemplateList();
          } else {
            Swal.fire("Something went wrong!", "Failed to update template.", "error")
          }
        })

      } else {
        this.smsService.addIVRTemplate(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template created successfully.", "success");
            this.templateForm.reset();
            this.templateForm.patchValue({ ivr: "", isPromotional: false, isCustomNumber: false });
            this.isCustomNumber = false;
            this.getTemplateList();
          } else {
            Swal.fire("Something went wrong!", "Template creattion failed.", "error");
          }
        });

      }
    }
  }

  onChangeCustomNumber(event) {
    
    const control = this.templateForm.get("customNumbers")
    if (event.target.checked) {      
      control.setValidators([Validators.required]);   
      this.isCustomNumber = true;
    } else {
      control.setValidators(null);
      this.isCustomNumber = false;
    }
    control.updateValueAndValidity();
  }

  onEditTemplate(row) {
    this.templateId = row.id;
    this.isCustomNumber = row.enable_custom_number;
    this.templateForm.patchValue({
      name: row.name,
      ivr: row.ivr,
      dtmf: row.dtmf_value,
      isPromotional: row.promotional_sms,
      isCustomNumber: row.enable_custom_number,
      customNumbers: row.custom_number,
      content: row.template_contetnt
    })
  }

  onCancelEdit() {
    this.templateId = null;
    this.templateForm.reset();
    this.templateForm.patchValue({ ivr: "", isPromotional: false, isCustomNumber: false });
    this.isCustomNumber = false;
  }

  onDeleteTemplate(id: string) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Deleting this tag will remove it from all contacts',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.smsService.deleteIVRTemplate(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template deleted successfully.", "success");
            this.getTemplateList();
            this.templateForm.reset();
            this.templateForm.patchValue({ ivr: "", isPromotional: false, isCustomNumber: false });
            this.isCustomNumber = false;
          } else {
            Swal.fire("Something went wrong!", "Failed to delete template", "error");
          }
        })
      }
    })
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { LoginService } from "../../login.service";
import { Router } from '@angular/router';
import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';
import { DialerService } from 'src/app/dialer/dialer.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { InboundFeedbackService } from '../inbound-feedback.service';

@Component({
  selector: 'app-add-questions',
  templateUrl: './add-questions.component.html',
  styleUrls: ['./add-questions.component.css']
})
export class AddQuestionsComponent implements OnInit {
  userData: any;
  permissions: any;
  permis: boolean;
  keyword = 'name';
  keyword1 = 'name';
  dialerFileToUpload: File;
  didList:any;
  subUser:any;
  subUserIds:any;
  category:string;
  categories:string = '';
  
  subcat:string;
  subcats:string = '';
  suboption:string='';
  suboptions:string='';
  label:string;
  labels:string = '';
  dlabel:string = '';
  dlabels:string = '';
  categ_list:string = '';
  label_list:string='';
  subUserList:any;
  DIDNumber:number = 0;
  DIDNumberInitial:string = '';
  subUserInitial:string = '';
  UserProfileId:number = 0;
  isAdmin:string = 'false'
  users:any = new Array();
  categoryArray:any = new Array();
  categoryArraysend:any;
  labelArraysend:any;
  labelArray:any = new Array();
  projectName:string='';
  constructor(
    private router: Router,
    private inboundRouteService: InboundRouteService,
    private loginService: LoginService,
    private dialerService: DialerService,
    private notifyService: NotificationService,
    private inboundFeedbackService:InboundFeedbackService,
  ){}

  ngOnInit() {
    this.userData = this.loginService.getUserdata(false);
     this.permissions = this.userData.permissions
      for (let element of this.permissions) {
        console.log(element.codename)
        if(element.codename == "cadmin_t_addQuestions"){
          this.permis = true;
        }
      }
    this.users = []
    this.subUserIds = []
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
    }


  
  selectDID(item) {
    this.DIDNumber = item.id
    

  }


  selectSubUser(item) {
    this.subUser = item.id
    var temp = {'id':item.id, 'name': item.name}
    this.users.push(temp)
    this.subUserIds.push(item.id)
    this.subUserIds = this.subUserIds.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    })
    console.log("ids", this.subUserIds )
  }

  deleteUser(item){
    console.log("uuuu", item.name)
    // this.users.remove(item)
    const index = this.users.indexOf(item, 0);
    if (index > -1) {
      this.users.splice(index, 1);
      this.subUserIds.splice(index, 1);
    }
    
  }

  onChangeSearchDID(val: string) {
    
    this.inboundRouteService.searchDID(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
      }
    )
  }


  onChangeSearchSubUser(val: string) {
    this.dialerService.searchSubUser(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.subUserList = data
        console.log('search did list', this.didList)
      }
    )
  }



  onFocusedDID(e){
    this.inboundRouteService.getDIDList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
      }
    )
  }

  onFocusedSubUser(e){
    this.dialerService.getSubUserList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.subUserList = data
      }
    )
  }
  clearDatas(){
    this.DIDNumber = 0
    
  }

addcategoriess(){
  // ngAfterViewInit(){
    // var modal = document.getElementbyID('page-modal');
    (<HTMLInputElement>document.getElementById('page-modal')).style.display='block';
    this.categories += this.category;
    this.category=''
  // }
} 

addsubcategory(){
    this.subcats += this.subcat+","
    this.subcat=''
   
  // }
} 
  
save_categories(){
    this.categ_list += this.categories+":>"+this.subcats;
    var length = this.categoryArray.push({'category':this.categories,'sub':this.subcats}); 
    console.log("length",this.categoryArray);
     (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
     this.categories='';
     this.subcats='';
   
  // }
} 

addlabeloptions(){
  // ngAfterViewInit(){
    // var modal = document.getElementbyID('page-modal');
    (<HTMLInputElement>document.getElementById('label-modal')).style.display='block';
    this.labels += this.label;
    this.label=''
  // }
} 
close_label_modal(){
     (<HTMLInputElement>document.getElementById('label-modal')).style.display='None';
     this.labels='';
     this.suboptions='';
   
  // }
} 
addsuboptions(){
    this.suboptions += this.suboption+","
    this.suboption=''
   
  // }
} 
save_labels(){
    this.label_list += this.labels+":>"+this.subcats;
    var length = this.labelArray.push({'label':this.labels,'option':this.suboptions}); 
    console.log("length",this.labelArray);
     (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
     this.labels='';
     this.suboptions='';
   
  // }
} 
remove_labels(index)
{
//  this.categoryArray.remove()
this.labelArray.splice(index, 1);
} 
close_modal(){
     (<HTMLInputElement>document.getElementById('page-modal')).style.display='None';
     this.categories='';
     this.subcats='';
   
  // }
} 
remove_categories(index)
{
//  this.categoryArray.remove()
this.categoryArray.splice(index, 1);
} 
  clearDatasSubUser(){
    this.subUser = 0
  }
 adddlabel = function(){
	       	this.dlabels += this.dlabel+","
           this.dlabel=''
        	}


  onUploadDialer(){
    this.categoryArraysend = JSON.stringify( this.categoryArray);
     this.labelArraysend = JSON.stringify( this.labelArray);
    // if(this.DIDNumber ! = 0) {
    //   Swal.fire('Oops..!', 'Please Select DID Number', 'warning')
    // }else {
      console.log("Labssssssss",this.labelArraysend)
      console.log("Labssssssss",this.dlabels)
      this.inboundFeedbackService.AddDidFeedbacks(this.projectName,this.UserProfileId, this.DIDNumber,this.labelArraysend,this.dlabels).subscribe(
        (res:any) => {
          this.projectName = '';
          this.DIDNumber = 0;
          this.categoryArraysend = '';
          this.labelArraysend=''
          console.log("data", res);
          this.subUserIds = [];
          this.categoryArray=[];
          this.notifyService.sendNotification('Done', 'Project Details sent successfully', 'is-success')
        }
      )
    }
  
  }



import { Component, OnInit } from '@angular/core';

import { LoginService } from "../../login.service";
import { SmsService } from 'src/app/sms/sms.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-sms-count',
  templateUrl: './sms-count.component.html',
  styleUrls: ['./sms-count.component.css'],
  providers: [DatePipe]
})
export class SmsCountComponent implements OnInit {
  public userData:any;
  public datas:any;
  public body:any;
  permissions:any;
  date1:string;
  searchData:any;
  current_page:any;
  last_page:any;
  permis = false;
  fromdate:any;
  todate:any;
  reportForm:FormGroup;

  constructor(
    private loginService: LoginService,
    private smsService: SmsService,
    private notifyservice: NotificationService,
    private datePipe: DatePipe,
    ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
    this.permissions = this.userData.permissions
    console.log("======",this.userData,"+++++++++")

      for (let element of this.permissions) {
        if(element.codename == "cadmin_s_smsCount"){
          this.permis = true;
        }

      }


      this.fetchData(1)
      this.reportForm = new FormGroup({
        toDate: new FormControl(''),
        fromDate: new FormControl(''),
        user: new FormControl(0),
      });
  }

  fetchData(pageNumberData){
    if(this.date1 == "Invalid date" || this.date1 == undefined){
      let latest_date =this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.fromdate = latest_date
      this.todate = latest_date
    }
    else{
      this.fromdate = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      this.todate = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    }
  
    // this.body = {"userId":this.userData.id,"from":this.fromdate,"to":this.todate,"search":this.searchData}
    var body = {"userId":this.userData.id,
                "from":this.fromdate,"to":this.todate,
                "search":this.searchData,"groupName":this.userData.usergroup}
    this.smsService.load_sms_count_by_page(body,pageNumberData).subscribe((res:any) =>{
      this.current_page = res.current_page;
      this.datas = res;
      this.last_page=res.total_pages;
      console.log("*******************************************************",this.userData);
  
    }
  
      )
  
  
  
  }
  
  
  fetchByPageNumber(pagenumber){
    this.fetchData(pagenumber)
  }
  
  fetchPreviousPage(){
    this.fetchData(this.current_page - 1)
  
  }
  
  fetchFirstPage(){
    this.fetchData(1)
  
  }
  
  fetchNextPage(){
    this.fetchData(this.current_page + 1)
  
  }
  
  fetchLastPage(){
    this.fetchData(this.last_page)
  }
  
  searchBox(){
    this.fetchData(1)
  }
  
  SearchReport(){
    this.fetchData(1)
  }
  
  
}

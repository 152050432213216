import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SharedService } from "src/global";

@Injectable({
    providedIn: "root"
})

export class LeadsquaredService{
    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ){}

    API_URL = this.sharedService.getGlobalVar()
    headers = new HttpHeaders().set("Content-Type", "application/json")

    getCRMConfig(user_id:string){
        return this.http.get(this.API_URL + "getCRMConfig/" + user_id + "/",{ headers: this.headers })
    }

    addCRMAgents(data:any){
        return this.http.post(this.API_URL + "router/leadSquaredCRM/" , data, { headers: this.headers })
    }

    getCRMAgents(qParams:any[]){
        let params = new HttpParams()
        qParams.forEach(param=>{
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.API_URL + `router/leadSquaredCRM/`, { headers: this.headers, params: params })
    }

    deleteAgent(agentId: string){
        return this.http.delete(this.API_URL + `router/leadSquaredCRM/${agentId}/`, { headers: this.headers })
    }
}

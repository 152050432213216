import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';



@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.css'],
  providers: [DatePipe]
})

export class CreditHistoryComponent implements OnInit {
  datas: any;
  current_page: any;
  last_page: any;
  credithistoryForm: FormGroup;
  myDate = new Date();
  today = new Date();
  tomorrow = new Date(this.today.setDate(this.today.getDate() + 1));
  tdy = new Date(this.myDate.setDate(this.myDate.getDate()));

  constructor(private usermanagementservice: UsermanagementService, private datePipe: DatePipe,
) 
  
  { 
    
  } 

  ngOnInit() {

    this.credithistoryForm = new FormGroup({
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl('')
    })

    this.loadTodayData(1);
  }

  loadTodayData(pageNumberData) {

    if (this.credithistoryForm.value.fromDate && this.credithistoryForm.value.toDate) {
      var from = moment(this.credithistoryForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      var to = moment(this.credithistoryForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }
    else {
      var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
    }
    


    this.usermanagementservice.credit_history(pageNumberData, from, to, this.credithistoryForm.value.search).subscribe(
      (data:any) =>{
        this.datas = data.results
        console.log(this.datas)
        this.current_page = data.current_page
        this.last_page = data.total_pages;
      }
     )
  }


  fetchNumber(i) {
    if (i == 'prev') {
      if (this.current_page > 1) {
        this.loadTodayData(this.current_page - 1);
      }

    }

    else if (i == 'next') {
      if (this.current_page < this.last_page) {
        this.loadTodayData(this.current_page + 1);

      }

    }

    else if (i == 'first') {
      this.loadTodayData(1);

    }

    else if (i == 'last') {
      this.loadTodayData(this.last_page);

    }

    else {

      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last_page) {
        this.loadTodayData(i);

      }
      else {
        Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
      }

    }


  }


}

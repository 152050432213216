import { Component, OnInit } from "@angular/core";

import { LoginService } from "src/app/login.service";
import { UsermanagementService } from "../usermanagement.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { NotificationService } from "src/app/notification/notification.service";
import * as jsPDF from "jspdf";
import { ExcelService } from "src/app/inbound-report/excel.service";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.css"],
})
export class ProductDetailsComponent implements OnInit {
  public clientData: any;
  public allProducts: any = [];
  public allCompanies: any = [];
  company: string = "";
  product: string = "";
  fromdate: string = "";
  todate: string = "";
  current_page: number = 1;
  total_page: any;
  last: number;
  ExcelPdfReportData: any = new Array();
  wordarray: any = new Array();


  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private router: Router,
    private notifyservice: NotificationService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.usermanagementservice.getProducts().subscribe((data: any) => {
      this.allProducts = data;
    });

    this.usermanagementservice.getCompanies().subscribe((data: any) => {
      this.allCompanies = data;
    });

    this.fetchProductData(1);
  }


  /// -------------------    FETCH CLIENT DATA   -----------------------///

  fetchProductData(pageNo) {
    const post_data = {
      company: this.company,
      product: this.product,
      fromdate: this.fromdate,
      todate: this.todate,
      pageNo: pageNo,
    };
    this.usermanagementservice
      .getClientDetails(0, post_data, pageNo)
      .subscribe((data: any) => {
        this.clientData = data.results;
        this.total_page = data["total_pages"];
        this.current_page = data["current_page"];
        this.last = data["total_pages"];
      });

    console.log(this.clientData, "*************************");
  }

  handleProductChange(e) {
    this.product = e;
  }

  handleCompanyChange(e){
    this.company = e;
  }

  Search() {
    this.fetchProductData(1);
  }

  editProduct(id) {
    this.router.navigateByUrl("/userdetail/" + id);
  }

  // ------------- Pagination  ---------------- //
  fetchNumber(i) {
    if (i == "prev") {
      if (this.current_page > 1) {
        this.fetchProductData(this.current_page - 1);
      }
    } else if (i == "next") {
      if (this.current_page < this.last) {
        this.fetchProductData(this.current_page + 1);
      }
    } else if (i == "first") {
      this.fetchProductData(1);
    } else if (i == "last") {
      this.fetchProductData(this.last);
    } else {
      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.fetchProductData(i);
      } else {
        Swal.fire("Oops..!", "Enter Valid Number", "warning");
      }
    }
  }

  downloads() {
    var countSlNo = 1;
    var newarray = [];
    var head = [
      [
        "SlNo",
        "Company",
        "Product",
        "Activation Date",
        "Renewal Date",
        "Status",
      ],
    ];

    this.ExcelPdfReportData = this.clientData;
    for (let element of this.ExcelPdfReportData) {
      console.log(element, "++++++++++++++++++++++");
      newarray.push([
        countSlNo,
        element["username"],
        element["product"],
        element["activationDate"] || "",
        element["renewalDate"],
        element["status"],
      ]);
      countSlNo = countSlNo + 1;
    }
    var pdf = new jsPDF();
    pdf.text("Inbound Report", 11, 8);
    pdf.setFontSize(3);
    pdf.setTextColor(100);
    (pdf as any).autoTable({
      head: head,
      body: newarray,
      theme: "grid",
      styles: { fontSize: 4 },
      tableWidth: "auto",
      columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
      margin: { top: 10 },
    });
    pdf.output("dataurlnewwindow");
    pdf.save("ClientReport" + ".pdf");
  }

  //----------------------   EXCEL  ----------------------- //

  exportAsXLSX(): void {
    var text;

    this.ExcelPdfReportData = this.clientData;
    for (let element of this.ExcelPdfReportData) {
      var objz = {
        Company: element["username"],
        Product: element["product"],
        "Activation Date": element["activationDate"] || "",
        "Renewal Date": element["renewalDate"],
        Status: element["status"],
      };

      this.wordarray.push(objz);
    }
  
    text = "Client Report";
    this.excelService.exportAsExcelFile(this.wordarray, text);
    this.wordarray = [];
}
}

import { Component, OnInit } from '@angular/core';
import { QueuemanagementService } from 'src/app/queuemanagement.service';
import { LoginService } from '../../login.service';
// import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { DIDMgmtService } from 'src/app/didmgmt/didmgmt.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';

@Component({
  selector: 'app-add-auto-call',
  templateUrl: './add-auto-call.component.html',
  styleUrls: ['./add-auto-call.component.css']
})
export class AddAutoCallComponent implements OnInit {

  userData: any;
  datas: any;
  DIDNum : any;
  user: any;
  TempId: number;
  id:number = 0;
  name:string;
  companies = 0;
  companyList : any;
  request_per_sec=0;
  agents_no=0;
  token:any;
	mobile_autocall:boolean=false;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private usermanagementservice:UsermanagementService,
  ) { }

  ngOnInit() { 

    this.id = +this.activeroute.snapshot.paramMap.get('id');

    this.usermanagementservice.CompanyList().subscribe(
      (data:any) =>{
        this.companies = data
      }
      )


    if(this.id != 0){
      this.getAutocalldetails()
    }


   
}



getAutocalldetails = function(){
  var body = {"type":"retrive", "id":this.id}
  this.usermanagementservice.getAutocallDetails(body).subscribe(
    (data:any) => {  
      var autocalldata = data

      console.log("=========autocall client data===", autocalldata)
      this.request_per_sec=autocalldata.api_request_no;
      this.agents_no=autocalldata.agents_no;
      this.mobile_autocall=autocalldata.mobile_autocall;
      this.companyList = autocalldata.company_name;
      this.token = autocalldata.Token
}
)
}


clearForm(){
  this.id = 0
  this.request_per_sec =0;
  this.agents_no =0;
  this.mobile_autocall=false;
  this.companyList=null
}

AddAutocall(){
  this.clearForm()
  this.router.navigateByUrl('/autoCall/addAutoCall/0');
}

AddAutocallClient = function() {

  var body ={}
  if (this.id == 0){
    body =  {
      "type":"create", 
      "request_per_sec":this.request_per_sec,
      "agents_no":this.agents_no,
      "mobile_autocall":this.mobile_autocall,
      "username":this.companyList,
    }
  }
  else{

     body =  {
      "type":"update", 
      "request_per_sec":this.request_per_sec,
      "agents_no":this.agents_no,
      "mobile_autocall":this.mobile_autocall,
      "id":this.id
    }
  }



  this.usermanagementservice.getAutocallDetails(body).subscribe(
    (data:any) => {  

      var data = data
      if(data.status == 0){
        Swal.fire('Error', data.message, 'warning')

      }
      else{
        Swal.fire('Success', data.message, 'success')
        this.router.navigateByUrl('/autoCall/autoCallList')

      }
      
}
)

}



}




import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class BlockedNumbersService {
    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ) { }

    API_URL = this.sharedService.getGlobalVar();
    header = new HttpHeaders().set("Content-Type", "application/json");

    getDids(id: number) {
        return this.http.get(this.API_URL + 'get-did/' + id, { headers: this.header })
    }

    addToBlocklist(data: any) {
        return this.http.post(this.API_URL + "customer-number-black-list/", data, { headers: this.header })
    }

    getBlockedlist(id: number, body: any, pageNo: number = 1) {
        const params = {
            page: pageNo.toString()
        };
        return this.http.post(this.API_URL + 'black-list/' + id + "/", body, { headers: this.header, params })
    }

    bulkUpload(file: any, id: number) {
        const fileHeader = new HttpHeaders({ "Content-Disposition": 'attachement' })
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(this.API_URL + "black-list-bulk-upload/" + id +'/', formData)
    }

    deleteItem(id: any) {
        return this.http.get(this.API_URL + "delete-black-list/" + id + "/", { headers: this.header })
    }


}

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    currentMessage = new BehaviorSubject<any[]>([]);

    constructor(
        private afMessaging: AngularFireMessaging,
        private afAuth: AngularFireAuth,
        private angularFireMessaging: AngularFireMessaging
    ) {

        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                console.log("New message received. ", payload);
            }
        );

        this.afMessaging.tokenChanges.subscribe(
            (token) => {
                console.log('Token changed:', token);
            },
            (error) => {
                console.error('Error changing token', error);
            }
        );
    }


    requestPermission(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.afMessaging.getToken.subscribe(
                (currentToken) => {
                    if (currentToken) {
                        this.afMessaging.deleteToken(currentToken).subscribe(
                            () => {
                                this.getNewToken().then(resolve).catch(reject);
                            },
                            (error) => {
                                console.error('Error deleting existing token', error);
                                reject(error);
                            }
                        );
                    } else {
                        this.getNewToken().then(resolve).catch(reject);
                    }
                },
                (error) => {
                    console.error('Error getting current token', error);
                    reject(error);
                }
            );
        });
    }

    private getNewToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.afMessaging.requestToken.subscribe(
                (newToken) => {
                    console.log('New FCM Token:', newToken);
                    resolve(newToken);
                },
                (error) => {
                    console.error('Error requesting new token', error);
                    reject(error);
                }
            );
        });
    }

}


import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from "./../../global";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient, public sharedService: SharedService) {}

  API_URL = this.sharedService.getGlobalVar();

  getElasticReport(payload, pagination, user_data, download) {
    const headers = new HttpHeaders();
    const body = {
      payload: payload,
      pagination: pagination,
      user: user_data,
      download: download,
    };
    headers.append("Content-Type", "application/json");

    if (download == "download_all_voice") {
      return this.http.post(this.API_URL + "elasticreport/", body, {
        responseType: "blob",
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
      });
    } else {
      headers.append("Content-Type", "application/json");
      return this.http.post(this.API_URL + "elasticreport/", body, {
        headers: headers,
      });
    }
  }

  getcallrecord(url, date) {
    const headers = new HttpHeaders();
    const body = { url: url, date: date };
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "callrecordfile/", body, {
      headers: headers,
    });
  }

  getAgentList(user_id: number, user_type: string) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const body = { user_id: user_id, user_type: user_type };
    return this.http.post(
      this.API_URL + "usermanagement/agentUserList/",
      body,
      { headers: headers }
    );
  }

  submitcomment(comment, call_id, id) {
    const headers = new HttpHeaders();
    const body = {
      comment: comment,
      call_id: call_id,
      id: id,
    };
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "comment/", body, {
      headers: headers,
    });
  }

  getcommentHistory(call_id, id){
    const headers = new HttpHeaders();
    const body = {
      call_id: call_id,
      id: id,
    };

    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "comment-history/", body, {
      headers: headers,
    });
  }

  download() {
    return this.http.get(this.API_URL + "elasticreport", {
      responseType: "blob",
      reportProgress: true,
      observe: "events",
    });
  }
}

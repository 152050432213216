import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  constructor(
    private http: HttpClient,
    public sharedService:SharedService,
  ) { }


  API_URL = this.sharedService.getGlobalVar()

  getCustomField(user_id: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.get(this.API_URL+'usermanagement/create_custom_field/?user_id='+user_id, {headers:headers})
  }

  AddCustomField(body: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.post(this.API_URL+'usermanagement/create_custom_field/', body, {headers:headers})
  }

  DeleteCustomField(body:any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.request('delete',this.API_URL+'usermanagement/create_custom_field/', {headers, body})
  }

}

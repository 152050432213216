export const callStatusData = {
"NOANSWER":"Unanswered",
"FAILED":"Failed to progress",
"ANSWERED":"Answered by destination",
"BUSY":"Destination number is Busy",
"CONGESTION":"Failed due to network Congestion",
"CANCEL":"Canceled by the Caller",
"CHANUNAVAIL":"Destination is Unavailable",
"NUMBER_UNALLOCATED":"Unallocated Phone Number",
"TEMPFAILURE":"Temporary Call Failure",
"REJECTED":"Rejected by Destination",
"UNREACHABLE":"Destination number is Unreachable",
"NUMBER_INVALID":"Invalid Destination Phone Number"
}
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { FormGroup , FormControl} from '@angular/forms';
import { InboundFeedbackService } from '../inbound-feedback.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';


@Component({
  selector: 'app-feed-back-report',
  templateUrl: './feed-back-report.component.html',
  styleUrls: ['./feed-back-report.component.css'],
  providers: [DatePipe]
})
export class FeedBackReportComponent implements OnInit {
  permis = false;
  permissions:any;
  userData:any;
  reportForm:FormGroup;
  voiceRecordData:any = new Array();
  filteredVoiceData: any;
  newarray: any;
  excelService: any;
  date1:any;
  public searchText : any;
  questions : string[]=[];
  dataRes: string;
  DIDNumber: any;
  UserProfileId: any;
  didList: any;
  DIDNumberInitial:string = '';
  myDate = new Date();
  today = new Date();
  tomorrow =  new Date(this.today.setDate(this.today.getDate() + 1));
  tdy =  new Date(this.myDate.setDate(this.myDate.getDate()));
  keyword = 'name';

 
  constructor(
    private loginservice: LoginService,
    private inboundFeedbackService: InboundFeedbackService,
    private datePipe: DatePipe,
    private inboundRouteService: InboundRouteService,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    
    this.UserProfileId = this.userData.id
    
    this.reportForm = new FormGroup({
      toDate: new FormControl(''),
      fromDate: new FormControl(''),
      DIDNumber: new FormControl(''),
      user: new FormControl(0),
      
     
    });
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_t_InboundFeedbackReport"){
        this.permis = true;
    }
  }

      // var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      // var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
      // this.inboundFeedbackService.InboundFeedbackReport(this.userData.id, from, to).subscribe(
      //   (res:any) => {
      //     this.voiceRecordData = this.filteredVoiceData = res;
      //     console.log(res);
      //   //   if( 'questionsFeedback' in res[0]){
        //     var quest = res[0]['questionsFeedback'][0]
        //     console.log(quest)
        //     console.log("qtnsssssss",this.questions)
        //     if(this.questions.length == 0){
        //       for (let element in quest) {
        //         console.log("element",element)                
        //         this.questions.push(element)
        //         this.dataRes = "a";
        //       }
        //   }
        // }
        //   else{
        //     console.log("oipopooooooo")
        //     this.questions = [];
        //     this.dataRes = "b";
        //   }
    //     }
    // )
  }

  exportAsXLSX():void {
    var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    for (let element of this.voiceRecordData) {
      this.newarray.push({'DID':element['did'],'Source':element['callerid'],
      'Date':element['legAstartTime'],'Agent':element['answeredAgent'],
      'Duration':element['duration'],'Connected Duration':element['connectedDuration'],
      'Status':element['callStatus']})
    }
     this.excelService.exportAsExcelFile(this.newarray, "Inbound Report from: "+from+" To: "+to+"");
     this.newarray=[];
 }

  SearchMissedReport(){
    if(this.reportForm.invalid){
      return;
    }else{
      var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      var didNum = this.reportForm.value.DIDNumber
      console.log(moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("MM-DD-YYYY"), "yyyy", this.reportForm.value)
      this.inboundFeedbackService.InboundFeedbackReport(this.userData.id,didNum, from, to).subscribe(
        (res:any) => {
          this.voiceRecordData = this.filteredVoiceData = res;
          console.log(res);
          if( 'questionsFeedback' in res[0]){
            var quest = res[0]['questionsFeedback'][0]
            console.log(quest)
            console.log("qtnsssssss",this.questions)
            if(this.questions.length == 0){
              for (let element in quest) {
                console.log("element",element)                
                this.questions.push(element)
                this.dataRes = "a";
              }
          }
        }
          else{
            console.log("oipopooooooo")
            this.questions = [];
            this.dataRes = "b";
          }
        }
    )
    }
  }

  searchBox(){
    console.log("textttttttttt",this.searchText)
    this.filteredVoiceData = this.voiceRecordData.filter((item)=>{
      console.log("item",item)
      console.log("callerIdddddddddddd",item.callerid)
      console.log("callerIdddddddddddd",item.did)
      console.log("callerIdddddddddddd",item.answeredAgent)
      console.log("search text",this.searchText)
      if(item.answeredAgent == null){
        item.answeredAgent = " ";
      }
      if (item.callerid.includes(this.searchText) || item.did.includes(this.searchText) || item.answeredAgent.includes(this.searchText) ){
        console.log("iteeeeeeem",item)
        return item;
      } 
    })
  }


  selectDID(item) {
    this.DIDNumber = item.id
    

  }

  onChangeSearchDID(val: string) {
    this.inboundRouteService.searchDID(val ,this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
      }
    )
  }
  clearDatas(){
    this.DIDNumber = 0
    
  }
  onFocusedDID(e){
    this.inboundRouteService.getDIDList(this.UserProfileId).subscribe(
      (data:any) =>{
        this.didList = data
      }
    )
  }


  downloads() {
    var from = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    var to = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', "a4");
      var position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight+10);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight+10);
          heightLeft -= pageHeight;
      }
    doc.save("Dailer Report,from "+from+" to "+to+".pdf");  
    });  
}


}

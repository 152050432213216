import { Component, OnInit } from "@angular/core";
import { UsermanagementService } from "../../usermanagement.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-add-user-credits",
  templateUrl: "./add-user-credits.component.html",
  styleUrls: ["./add-user-credits.component.css"],
})
export class AddUserCreditsComponent implements OnInit {
  companyList: string | undefined;
  companies: any;
  DidList: any;
  didnumbers: any;
  planName: any;
  disableSelect = true;
  credit: any;
  ids: any;
  plan: string;
  showCreditInput: boolean;

  constructor(
    private usermanagementservice: UsermanagementService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.ids = params["id"];
      if (this.ids == "new") {
        this.loadCompany();
      } else {
        this.loadDID();
      }
    });
  }

  // ---------------------- PLAN ------------------------ //
  handleChangePlan(plan) {
    console.log("PLAN    :   ", plan);
    this.plan = plan;
    if (this.plan == "limited") {
      this.showCreditInput = true;
    } else {
      this.showCreditInput = false;
    }
    console.log("PLAN 2   :   ", plan);
  }

  loadCompany() {
    this.usermanagementservice.CustomerComapnyList().subscribe((data: any) => {
      this.companies = data;
    });
  }
  loadDID() {
    var datas = {
      didnumber: this.ids,
    };
    this.usermanagementservice.userCreditDid(datas).subscribe((data: any) => {
      console.log("*****************", data, "****************");
      this.companyList = data["company"];
      this.didnumbers = data["didNumber"];
      this.planName = data["plan"];
      if (this.planName !== "1") {
        this.disableSelect = false;
        this.credit = data["credit"];
      }
    });
  }
  getcompanycredit() {
    this.usermanagementservice
      .getExistingDidNumber(this.companyList)
      .subscribe((data: any) => {
        this.DidList = data.data;
      });
  }

  AddPlan() {
    if(this.plan == "unlimited"){
      this.credit = 0
    }
    if (this.companyList == undefined) {
      Swal.fire("Oops..!", "please fill details", "warning");
    } else {
      var data = {
        company: this.companyList,
        creditamount: this.credit,
        plan: this.plan,
      };
      this.usermanagementservice.addUserCredit(data).subscribe((data: any) => {
        if (data["status"] == 1) {
          Swal.fire("Created", "Created Plan", "success");
          this.companyList = "";
          this.credit = "";
        } else {
          Swal.fire("Oops..!", data["message"], "warning");
        }
      });
    }
  }
  UpdatePlan() {
    if (this.companyList == undefined) {
      Swal.fire("Oops..!", "please fill details", "warning");
    } else {
      console.log();
      if (this.plan == "unlimited") {
        this.credit = 0;
      }
      var data = {
        company: this.companyList,
        creditamount: this.credit,
        plan: this.plan,
      };
      this.usermanagementservice
        .updateUserCredit(data)
        .subscribe((data: any) => {
          if (data["status"] == 1) {
            Swal.fire("Updated", "Plan Updated", "success");
          } else {
            Swal.fire("Oops..!", data["message"], "warning");
          }
        });
    }
  }
}

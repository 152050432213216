import { Component, OnInit } from '@angular/core';
import { CustomFieldService } from './custom-field.service';
import { LoginService } from 'src/app/login.service';
import { NotificationService } from 'src/app/notification/notification.service';
import Swal from 'sweetalert2';
import { AddressbookComponent } from '../addressbook.component';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.css']
})
export class CustomFieldComponent implements OnInit {
  showCustomFieldDiv = false
  customFieldData: [] = []
  userData: any
  custom_field_name: any

  constructor(
    private customfieldservice: CustomFieldService,
    private loginservice: LoginService,
    private notifyservice: NotificationService,
    private addressbookcomponent: AddressbookComponent
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }

  toggleCustomField() {
    this.showCustomFieldDiv = !this.showCustomFieldDiv
    if(this.showCustomFieldDiv == true){
      this.getCustomField(this.userData.id)
    }
  }

  getCustomField(user_id: any){
    this.customfieldservice.getCustomField(user_id).subscribe(
      (response:any) => {
        this.customFieldData = response.custom_field_names
      }
    )
  }

  CreateCustomField(){
    const body = {
      user_id: this.userData.id,
      custom_field_name: this.custom_field_name
    }

    this.customfieldservice.AddCustomField(body).subscribe(
      (response:any) => {
        if (response.status === 'success') {
          this.getCustomField(this.userData.id)
          this.custom_field_name = ''
          this.notifyservice.sendNotification('Success', response.message, 'is-success')
          this.addressbookcomponent.fetchCustomFields(this.userData.id)
        } 
        else {
          this.notifyservice.sendNotification('Failed', response.message, 'is-danger')
        }
      }
    )
  }

  DeleteCustomField(custom_field_name:any){
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Deleting this Field will remove data from all Customer contact details, are you sure you want to delete this Custom Field ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const body = {
          user_id: this.userData.id,
          custom_field_name: custom_field_name
        }

        this.customfieldservice.DeleteCustomField(body).subscribe(
          (response:any) => {
            if (response.status === 'success') {
              this.getCustomField(this.userData.id)
              this.notifyservice.sendNotification('Success', response.message, 'is-success')
              this.addressbookcomponent.fetchCustomFields(this.userData.id)
            }
            else {
              this.notifyservice.sendNotification('Failed', response.message, 'is-danger')
            }  
          }
        )
      }
    })
  }
}

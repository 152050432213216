import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class DialerService {

  constructor(
    private http: HttpClient,
    public sharedService: SharedService

  ) { }
  
  API_URL = this.sharedService.getGlobalVar()
  ListDialer(userProfileId:number) {
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');    
    return this.http.get(this.API_URL+'listDialer/'+userProfileId+'/', {headers:headers});
  }



  AddDialer(Proid,usrList, name, userId,DID, subUserIds,category,labeloption,dlabel) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('name', name)
    formData.append('userId', userId)
    formData.append('subuserids',subUserIds)
    formData.append('category', category)
    formData.append('labeloption', labeloption)
    formData.append('dlabel',dlabel)
    formData.append('DID', DID)
    formData.append('Proid', Proid)
    formData.append('usrList', usrList)
    return this.http.post(this.API_URL+'AddDialer/', formData, {headers: headers})
  }

  uploadDialer(dialerFileToUpload, name, userId, DID, subUserIds) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('datas', dialerFileToUpload);
    formData.append('name', name)
    formData.append('userId', userId)
    formData.append('DID', DID)
    return this.http.post(this.API_URL+'UploadDialer/', formData, {headers: headers})
  }


  getSubUserList(userProfileId:number) {
    console.log("get sub user listr")
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'subuserList/'+userProfileId+'/', {headers: headers})
  }


  searchSubUser(val:string,userProfileId:number) {
    console.log("get sub user")
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'subuserList/'+userProfileId+'/?search='+val, {headers: headers})
  }
  dialerList(userProfileId:number, userType: string = "") {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'dialerList/'+userProfileId+'/?usertype='+userType, {headers: headers})
  }

  getdialeruserList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerUserList/'+userProfileId+'/', {headers: headers})
  }

  searchdialeruserList(val:string,userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerUserList/'+userProfileId+'/?search='+val, {headers: headers})
  }
  getdialerprojectList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerProjectList/'+userProfileId+'/', {headers: headers})
  }

  searchdialerprojectList(val:string,userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerProjectList/'+userProfileId+'/?search='+val, {headers: headers})
  }
  getdialercategoryList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerCategoryList/'+userProfileId+'/', {headers: headers})
  }

  searchdialercategoryList(val:string,userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerCategoryList/'+userProfileId+'/?search='+val, {headers: headers})
  }
   getdialersubcategoryList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerSubcategoryList/'+userProfileId+'/', {headers: headers})
  }

  searchdialersubcategoryList(val:string,userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DialerSubcategoryList/'+userProfileId+'/?search='+val, {headers: headers})
  }
  
  onUploadDialerExcel(channelId,dialerFileToUpload, project,category,subcategory, SUBU,UserProfileId,dialRecentAgent,usertype) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('channels',channelId)
    formData.append('datas', dialerFileToUpload);
    formData.append('project', project)
    formData.append('category', category)
    formData.append('subcategory', subcategory)
    formData.append('subuser',SUBU)
    formData.append('comp_id',UserProfileId)
    formData.append('usertype',usertype)

    return this.http.post(this.API_URL+'UploadDialerExcel/', formData, {headers: headers})
  }
  
deleteProject(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'DeleteProject/'+id+'/', {headers: headers})
  }


getdialerprojectAll(userProfileId:number) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(this.API_URL+'DialerProjectAll/'+userProfileId+'/', {headers: headers})
    }

searchdialerprojectAll(val:string,userProfileId:number) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(this.API_URL+'DialerProjectAll/'+userProfileId+'/?search='+val, {headers: headers})
    }

fetchOneProjectDetail(id:number) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(this.API_URL+'fetchOneProjectDetail/'+id+'/', {headers: headers})
    }
}

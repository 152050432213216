import { Component, OnInit } from '@angular/core';

@Component({
	selector: '[app-usermanagement]',
	templateUrl: './usermanagement.component.html',
	styleUrls: ['./usermanagement.component.css']
})
export class UsermanagementComponent implements OnInit {
	public username:string;
	public password:any;
	public description:string;
	public customername:string;
	public mobile:number;
	public email:any;
	public address:any;
	public pulse:any;
	public cpp:any;
	public channels:number;
	public dids:string;

	constructor() { }

	ngOnInit() {
	}

	onSubmit(){

		
	}

}

import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { SharedService } from 'src/global';
@Injectable({
  providedIn: 'root'
})
export class DIDMgmtService {

	constructor(
		private http:HttpClient,
		public sharedService: SharedService

	) { }

	API_URL = this.sharedService.getGlobalVar()

	DidManagement(body){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'DidManagement/',body,{headers:headers})
	}

	DidList(id) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'DIDList/'+id+'/', {headers:headers});
	}

	DidProcess(id) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.delete(this.API_URL+'DidProcess/'+id+'/', {headers:headers});
	}

	Oneditdid(id) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.get(this.API_URL+'didDetail/'+id+'/', {headers:headers});
	}

	EditDid(id,body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.put(this.API_URL+'DidEdit/'+id+"/",body, {headers:headers});
	}

	DIDCompanyList() {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'usermanagement/DIDCompanyList/', {headers:headers});
	}

	VsipCount(id) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'didvsip/get_did_of_vsip/?did_id=' + id, {headers:headers});
	}

	ReplaceVsipDID(body:any) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'didvsip/replace_did_for_vsip/', body, {headers:headers});
	}

}

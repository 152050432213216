import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './../../global';

@Injectable({
	providedIn: 'root'
})
export class IvrService {

	constructor(
		private http:HttpClient,
		public sharedService:SharedService,

	) { }
    API_URL = this.sharedService.getGlobalVar()

	GetIVRList(UserProfileId:number){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'ivrList/'+UserProfileId+'/', {headers:headers})
	}
	GetIVRLists(search,searchCustomer,userid,current_page){
		const body = {"search":search,"searchCustomer":searchCustomer,'userid':userid}
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'ivrLists/?page='+current_page, body, {headers:headers})
	}

	AddIVR(body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'ivrList/0/', body, {headers:headers})
	}

	UpdateIVR(id, body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(this.API_URL+'ivrDetails/'+id+'/', body, {headers:headers})
	}

	GetIVR(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'ivrDetails/'+id+'/', {headers:headers})
	}

	DeleteIVR(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.delete(this.API_URL+'ivrDetails/'+id+'/', {headers:headers})
	}

	searchIVR(val:string, userProfileId:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.get(this.API_URL+'ivrList/'+userProfileId+'/?search='+val, { headers: headers});
	}

	getLevelOneOptionsList(ivrId:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'levelOneOptionList/'+ivrId+'/', {headers:headers});
	}

	AddLevelOne(body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'levelOneOptionList/0/', body, {headers:headers});
	}

	UpdateLevelOne(id, body){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(this.API_URL+'levelOneOptionDetails/'+id+'/', body, {headers:headers});
	}

	getLevelOneOptions(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'levelOneOptionDetails/'+id+'/', {headers:headers});
	}

	DeleteLevelOneOptions(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.delete(this.API_URL+'levelOneOptionDetails/'+id+'/', {headers:headers})
	}

	getLevelTwoOptionsList(levelTwoOptionId:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'levelTwoOptionList/'+levelTwoOptionId+'/', {headers:headers});
	}

	AddLevelTwo(body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'levelTwoOptionList/0/', body, {headers:headers});
	}

	UpdateLevelTwo(id, body){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(this.API_URL+'levelTwoOptionDetails/'+id+'/', body, {headers:headers});
	}

	getLevelTwoOptions(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'levelTwoOptionDetails/'+id+'/', {headers:headers});
	}

	DeleteLevelTwoOptions(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.delete(this.API_URL+'levelTwoOptionDetails/'+id+'/', {headers:headers})
	}

	getLevelThreeOptionsList(levelThreeOptionId:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'levelThreeOptionList/'+levelThreeOptionId+'/', {headers:headers});
	}

	AddLevelThree(body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'levelThreeOptionList/0/', body, {headers:headers});
	}

	UpdateLevelThree(id, body){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(this.API_URL+'levelThreeOptionDetails/'+id+'/', body, {headers:headers});
	}

	getLevelThreeOptions(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'levelThreeOptionDetails/'+id+'/', {headers:headers});
	}

	DeleteLevelThreeOptions(id:number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.delete(this.API_URL+'levelThreeOptionDetails/'+id+'/', {headers:headers})
	}

	SendOnComplete(id:any){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'sendoncomplete/',id,{headers:headers})
	}
}

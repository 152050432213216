// import { Component, EventEmitter, Output } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';  // Import necessary classes
// import { HttpClient } from '@angular/common/http';  // For making HTTP requests
// import { SharedService } from "src/global";
// import Swal from 'sweetalert2';
// @Component({
//   selector: 'app-update-user-password',
//   templateUrl: './update-user-password.component.html',
//   styleUrls: ['./update-user-password.component.css']
// })
// export class UpdateUserPasswordComponent {
//   @Output() closeModal = new EventEmitter<void>();  // Event emitter to close the modal
//   resetPasswordForm: FormGroup;  // Define the form group
//   errorMessage: string = '';  // To store error messages
//   constructor(private fb: FormBuilder, private http: HttpClient, public sharedService: SharedService,) {
//     // Initialize the form with validation
//     this.resetPasswordForm = this.fb.group({
//       current_password: ['', [Validators.required]],  // Current password field
//       new_password: ['', [Validators.required]],  // New password with validation
//       confirm_password: ['', [Validators.required]]  // Confirm password field
//     }, { validators: this.passwordsMatchValidator });  // Add custom validator for matching passwords
//   }
//   API_URL = this.sharedService.getGlobalVar(); // Replace with your actual API URL
//   // Custom validator for checking if new password and confirm password match
//   passwordsMatchValidator(form: FormGroup) {
//     const newPassword = form.get('new_password')!.value;  // Ensure control exists using '!'
//     const confirmPassword = form.get('confirm_password')!.value;
//     return newPassword === confirmPassword ? null : { mismatch: true };
//   }
//   onSubmit() {
//     if (this.resetPasswordForm.valid) {
//       const formValues = this.resetPasswordForm.value;
//       // Validate passwords
//       const passwordError = this.passwordsMatchValidator(this.resetPasswordForm);
//       if (passwordError) {
//         Swal.fire({
//           title: 'Error',
//           text: passwordError.mismatch ? 'Passwords do not match.' : 'Password validation error.'
//         });
//         return;
//       }
//       const { current_password, new_password, confirm_password } = formValues;
//       // Get ID and user type from local storage
//       const id = localStorage.getItem('id') || null;
//       const usertype = localStorage.getItem('usertype') || null;
//       const payload = {
//         id,
//         usertype,
//         current_password,
//         new_password,
//         confirm_password
//       };
//       // Make the HTTP POST request to update the password
//       this.http.post<any>(this.API_URL + 'update-password/', payload).subscribe({
//         next: (response) => {
//           // Handle success response
//           Swal.fire({
//             title: 'Success',
//             text: response.message || 'Password reset successful!'
//           });
//           // Close the modal (if applicable)
//           this.closeModal.emit();
//         },
//         error: (error) => {
//           // Handle error response
//           Swal.fire({
//             title: 'Error',
//             text: error.error.message || 'Something went wrong!'
//           });
//         }
//       });
//     } else {
//       Swal.fire({
//         title: 'Validation Error',
//         text: 'Please fill out all fields correctly.'
//       });
//     }
//   }
// }








import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';  // Import necessary classes
import { HttpClient } from '@angular/common/http';  // For making HTTP requests
import { SharedService } from "src/global";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-user-password',
  templateUrl: './update-user-password.component.html',
  styleUrls: ['./update-user-password.component.css']
})
export class UpdateUserPasswordComponent {
  resetPasswordForm: FormGroup;  // Define the form group
  errorMessage: string = '';  // To store error messages

  @Output() closeModal = new EventEmitter<void>();
  @Input() showsetting: boolean = false;
  constructor(private fb: FormBuilder, private http: HttpClient, public sharedService: SharedService) {
    // Initialize the form with validation
    this.resetPasswordForm = this.fb.group({
      current_password: ['', Validators.required],  // Current password field
      new_password: ['', Validators.required],  // New password with validation
      confirm_password: ['', Validators.required]  // Confirm password field
    }, { validators: this.passwordsMatchValidator });  // Add custom validator for matching passwords
  }

  API_URL = this.sharedService.getGlobalVar(); // Replace with your actual API URL

  // Custom validator for checking if new password and confirm password match
  passwordsMatchValidator(form: FormGroup) {
    const newPassword = form.get('new_password')!.value;  // Ensure control exists using '!'
    const confirmPassword = form.get('confirm_password')!.value;
    return newPassword === confirmPassword ? null : { mismatch: true };
  }
  cancel() {
    this.closeModal.emit();
  }
  // Show specific error messages for required fields
  showFieldErrors() {
    const formControls = this.resetPasswordForm.controls;
    if (formControls['current_password'].hasError('required')) {
      return 'Current password is required.';
    }
    if (formControls['new_password'].hasError('required')) {
      return 'New password is required.';
    }
    if (formControls['confirm_password'].hasError('required')) {
      return 'Confirm password is required.';
    }
    if (this.resetPasswordForm.hasError('mismatch')) {
      return 'New passwords do not match.';
    }
    return '';
  }



  onSubmit() {
    if (this.resetPasswordForm.valid) {
      const formValues = this.resetPasswordForm.value;

      const { current_password, new_password, confirm_password } = formValues;
      const userData = JSON.parse(localStorage.getItem('userdata') || '{}');
      const id = userData.id || null;
      const usertype = userData.usertype || null;

      const payload = { id, usertype, current_password, new_password, confirm_password };

      this.http.post<any>(this.API_URL + 'update-password/', payload).subscribe({
        next: (response) => {
          if (response.status === '1') {
            this.closeModal.emit();
            Swal.fire({
              title: 'Success',
              text: response.message || 'Password reset successful!',
            });
          } else {
            this.errorMessage = response.error || 'Password reset failed.';
          }
        },
        error: (error) => {
          this.errorMessage = error.error.message || 'Something went wrong!';
          // Swal.fire({
          //   title: 'Error',
          //   text: this.errorMessage,
          // });
        }
      });
    } else {
      const validationError = this.showFieldErrors();
      this.errorMessage = validationError || 'Please fill out all fields correctly.';
      // Swal.fire({
      //   title: 'Validation Error',
      //   text: this.errorMessage,
      // });
    }
  }

}

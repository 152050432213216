import { Component, OnInit,Input, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../login.service';

@Component({
	selector: '[app-submenu]',
	templateUrl: './submenu.component.html',
	styleUrls: ['./submenu.component.css']
})
export class SubmenuComponent implements OnInit {
	@Input() menus:any;
	userData:any;
	isAdmin:string;
	permissions:any;

	@ViewChild('tabs') myDiv:ElementRef;
  	ngAfterViewInit(){
		console.log(this.myDiv.nativeElement.children);
		let children = this.myDiv.nativeElement.getElementsByTagName("li");
		console.log("chiiiiiiiii",children)
		children[0].click()
  }	

	constructor(
		private loginservice: LoginService,
	) { }

	ngOnInit() {
		this.isAdmin = localStorage.getItem('isAdmin')
		this.userData = this.loginservice.getUserdata(true);
		this.permissions = this.userData.permissions
		console.log("perrrrrrrrrrr",this.permissions)
		console.log("menusssssss",this.menus)
	}

}

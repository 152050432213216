import {Pipe, PipeTransform} from '@angular/core';
// import { isString } from '../utils/utils';
@Pipe({
  name: 'split',
})

export class SplitPipe implements PipeTransform {
  transform(input: any, separator: string = '.gsm', limit?: number): any {
    console.log(input.replace(separator, '')+'.wav')
    return input.replace(separator, '')+'.wav';
  }
}
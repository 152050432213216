import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from '../usermanagement.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: '[app-subuser-group-list]',
  templateUrl: './subuser-group-list.component.html',
  styleUrls: ['./subuser-group-list.component.css']
})
export class UserGroupListComponent implements OnInit {
  public userData: any;
  public usergroup:any;

  constructor(
    private loginservice: LoginService,
    private usermanagementservice:UsermanagementService,
    private router: Router,
    private notifyservice: NotificationService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    if(this.userData.group == 'superadmin'){
      this.usermanagementservice.getGroupList(0).subscribe(
        (data:any) => {
          this.usergroup = data;
        }
      )
    }else{
      this.usermanagementservice.getGroupList(this.userData.id).subscribe(
        (data:any) => {
          this.usergroup = data;
        }
      )
    }
  }

  AddUserGroup(){
    localStorage.setItem("customer", null);
    this.router.navigateByUrl('/usergroup/0');
  }

  editUserProfile(id){
    // this.usermanagementservice.getUserProfile(id).subscribe(
    //   (data:any) => {

            // this.router.navigateByUrl('/userdetail/'+id);
            this.router.navigateByUrl('/usergroupedit/'+id);
         
      // }
    // )
  }

  deleteUserProfile(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The UserProfile Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.usermanagementservice.deleteUserGroupData(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'UserProfile delete successfully', 'is-success')
            if(this.userData.group == 'superadmin'){
              this.usermanagementservice.getGroupList(0).subscribe(
                (data:any) => {
                  this.usergroup = data;
                }
              )
            }else{
              this.usermanagementservice.getGroupList(this.userData.id).subscribe(
                (data:any) => {
                  this.usergroup = data;
                }
              )
            }
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'UserProfile deleting has been cancelled', 'is-danger')
      }
    })
  }
  

}

import { Component, OnInit } from '@angular/core'
import { LoginService } from "../../login.service"
import { VoicemailService } from '../voicemail.service'
import Swal from 'sweetalert2'
import { NotificationService } from 'src/app/notification/notification.service'

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})

export class TemplateListComponent implements OnInit {
  userData:any
  templateList: any = new Array()

  constructor(
    private loginservice: LoginService,
    private voicemailservice: VoicemailService,
    private notifyservice: NotificationService,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true)
    this.loadTemplateList()
    
  }

  loadTemplateList = () => this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe((res: any) => this.templateList = res)



	ShowErrorMsg(msg){
		this.notifyservice.sendNotification('VoiceMail Error', msg, 'is-danger')
	}

  onDelete(template_id: number) {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this voicemail template ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.voicemailservice.deleteVoicemailTemplate(template_id, this.userData.id).subscribe(
          () => {
            this.loadTemplateList();
            this.notifyservice.sendNotification('Deleted!', 'Voicemail template has been deleted.', 'is-success')
          },
          (error: any) => {
            console.error('Error deleting voicemail template:', error)
            this.notifyservice.sendNotification('Error', 'An error occurred while deleting the template. Please try again.', 'is-info')
          }
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.notifyservice.sendNotification('Cancelled', 'Voicemail template deletion was cancelled.', 'is-danger')
      }
    })
  }
  
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IvrService } from '../ivr.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';
import { LoginService } from 'src/app/login.service';
import { QueuemanagementService } from 'src/app/queuemanagement.service';

@Component({
  selector: '[app-level-two]',
  templateUrl: './level-two.component.html',
  styleUrls: ['./level-two.component.css']
})
export class LevelTwoComponent implements OnInit {
  public IVRTwoForm: FormGroup
  public voiceFileData:any;
  voiceLabel: string = 'Voice File';
  levelTwoDisplay:boolean = false;
  @Input() optionLabel:string;
  @Input() levelOneId:number;
  @Input() optionValue:any;
  @Output() parentEmitter2 = new EventEmitter<any>()
  LevelThreeCount:any = new Array();
  LevelThreeData:any;
  LevelTwoId:number = 0
  optionThreeValue:any;
  LevelNum:number = 0;
  parentLevel:any;
  @Input() optionNumber:any;
  optionNum:any = new Array();
  optionNumUpdate:number;
  voicelist:any = new Array();
  voiceFileTwoInitial:any;
  destinationList:any = new Array();
  destinationInitial:any;
  keyword:string = 'name';
  @Input() userID:number;

  constructor(
    private ivrservice: IvrService,
    private notifyservice: NotificationService,
    private voicerecodrservice: VoiceRecordService,
    private loginservice: LoginService,
    private queueservice: QueuemanagementService

  ) { }

  ngOnInit() {
    this.IVRTwoForm = new FormGroup({
      number: new FormControl('', Validators.required),
      ivrlevel: new FormControl(''),
      voicefile: new FormControl(''),
      Destination: new FormControl(''),
      sublevels: new FormControl(0),
      enablepreviousmenu:new FormControl(0)
    })
    if (this.optionValue) {

      console.log("=======================OptionnValur======================")

      console.log(this.optionValue)


      this.voicerecodrservice.getVoiceRecordList(this.userID,0).subscribe(
        (data:any) =>{
          this.voicelist = data
          for (let voiceData of this.voicelist){
            if(voiceData.fileUrl == this.optionValue.voicefile) {
              this.voiceFileTwoInitial = voiceData.name
            }
          }
          console.log('aaaa', this.voiceFileTwoInitial)
        }
      )
      this.IVRTwoForm.setValue({
        number: this.optionValue.number,
        ivrlevel: this.optionValue.ivrlevel,
        voicefile: this.optionValue.voicefile,
        Destination: this.optionValue.Destination,
        sublevels: this.optionValue.sublevels,
        enablepreviousmenu:this.optionValue.enablepreviousmenu
      })

      this.destinationInitial = this.optionValue.Destination
      this.LevelTwoId = this.optionValue.id
      this.LevelNum = this.optionValue.sublevels
      this.optionNumUpdate = parseInt(this.optionValue.number)
      this.ivrservice.getLevelThreeOptionsList(this.LevelTwoId).subscribe(
        (data:any) => {
          this.LevelThreeData = data
          if(this.LevelThreeData) {
            this.optionNum = []
            for (let i = 0; i < this.LevelNum; i++) {
              if (this.LevelThreeData.length >= i){
                this.optionThreeValue = this.LevelThreeData[i]
                this.optionNum.push(this.optionThreeValue.number)
              }else{
                this.optionThreeValue = undefined
              }
              this.LevelThreeCount.push({
                'orderId': 'LevelThree'+ (i+ 1).toString(),
                'optionLabel': 'OPTION '+ (i+ 1).toString(),
                'optionValue': this.optionThreeValue
              })
            }
          }else {
            for (let i = 1; i <= this.LevelNum; i++) {
              this.LevelThreeCount.push({
                'orderId': 'LevelThree'+i.toString(),
                'optionLabel': 'OPTION '+i.toString(),
                'optionValue': undefined
              })
            }
          }
        }
      )
    }
    if(this.levelOneId){
      this.ivrservice.getLevelOneOptions(this.levelOneId).subscribe(
        (data:any) => {
            this.parentLevel = data
        }
      )
    }
  }

  voiceFilePath(data) {
    this.voiceFileData = data
  }

  // Department Autocompleted Functions

  selectQueue(item) {
    this.IVRTwoForm.value.Destination = item.name
  }
  
  onChangeSearchQueue(val: string) {
    this.queueservice.searchQueue(val ,this.userID).subscribe(
      (data:any) =>{
        this.destinationList = data
        console.log('search queue list', this.destinationList)
      }
    )
  }
  
  onFocusedQueue(e){
    this.queueservice.getQueues(this.userID).subscribe(
      (data:any) =>{
        this.destinationList = data
        console.log('queue focused list', this.destinationList)
      }
    )
  }

  onClearDestination() {
    this.IVRTwoForm.value.Destination = ''
  }

  get f() { return this.IVRTwoForm.controls; }

  onAddLevelTwo() {

    if(this.voiceFileData != undefined &&this.IVRTwoForm.value.voicefile != this.voiceFileData){
      this.IVRTwoForm.value.voicefile = this.voiceFileData
    }
    if(this.IVRTwoForm.value.voicefile != '' && this.IVRTwoForm.value.Destination != '' && this.IVRTwoForm.value.enablepreviousmenu){
      Swal.fire('Oops..!', 'Voice file, Department and Enable Previous Menu are not allowed simultaneosly', 'error')
    }else if(this.IVRTwoForm.value.voicefile == '' && this.IVRTwoForm.value.Destination == '' && this.IVRTwoForm.value.enablepreviousmenu == false){
      Swal.fire('Oops..!', 'Please Select Voice file,Department or Previous Menu', 'error')
    }else if(this.IVRTwoForm.value.voicefile == null && this.IVRTwoForm.value.Destination == ''&& this.IVRTwoForm.value.enablepreviousmenu == false){
      Swal.fire('Oops..!', 'Please Select Voice file or Department', 'error')
    }else{
      if(this.LevelTwoId != 0) {
        if (this.optionNumUpdate != this.IVRTwoForm.value.number){
          if(this.optionNumber.indexOf(parseInt(this.IVRTwoForm.value.number)) != -1){
            Swal.fire('Duplicate', 'Already Exists Option Number', 'warning')
          }else{
            this.IVRTwoForm.value.ivrlevel = this.levelOneId
            this.IVRTwoForm.value.voicefile = this.voiceFileData
            this.ivrservice.UpdateLevelTwo(this.LevelTwoId, this.IVRTwoForm.value).subscribe(
              (res:any) => {
                if(res && res['id']){
                  this.LevelTwoId = res['id']
                  this.LevelNum = res['sublevels']
                  this.LevelThreeCount = []
                  this.optionNumber.push(res.number)
                  this.optionNumber.splice(this.optionNumber.indexOf(this.optionNumUpdate), 1)
                  this.optionNumUpdate = res.number
                  this.notifyservice.sendNotification('Updated', 'Level 2 option update successfully', 'is-success')
                  this.ivrservice.getLevelThreeOptionsList(this.LevelTwoId).subscribe(
                    (data:any) => {
                      this.LevelThreeData = data
                      if(this.LevelThreeData) {
                        for (let i = 0; i < this.LevelNum; i++) {
                          if (this.LevelThreeData.length >= i){
                            this.optionThreeValue = this.LevelThreeData[i]
                          }else{
                            this.optionThreeValue = undefined
                          }
                          this.LevelThreeCount.push({
                            'orderId': 'LevelTwo'+ (i+ 1).toString(),
                            'optionLabel': 'OPTION '+ (i+ 1).toString(),
                            'optionValue': this.optionThreeValue
                          })
                        }
                      }else {
                        for (let i = 1; i <= this.LevelNum; i++) {
                          this.LevelThreeCount.push({
                            'orderId': 'LevelTwo'+i.toString(),
                            'optionLabel': 'OPTION '+i.toString(),
                            'optionValue': undefined
                          })
                        }
                      }
                    }
                  )
                }
              }
            )
          }
        }else {
          this.IVRTwoForm.value.ivrlevel = this.levelOneId
          this.IVRTwoForm.value.voicefile = this.voiceFileData
          this.ivrservice.UpdateLevelTwo(this.LevelTwoId, this.IVRTwoForm.value).subscribe(
            (res:any) => {
              if(res && res['id']){
                this.LevelTwoId = res['id']
                this.LevelNum = res['sublevels']
                this.LevelThreeCount = []
                this.optionNumUpdate = res.number
                this.notifyservice.sendNotification('Updated', 'Level 2 option update successfully', 'is-success')
                this.ivrservice.getLevelThreeOptionsList(this.LevelTwoId).subscribe(
                  (data:any) => {
                    this.LevelThreeData = data
                    if(this.LevelThreeData) {
                      for (let i = 0; i < this.LevelNum; i++) {
                        if (this.LevelThreeData.length >= i){
                          this.optionThreeValue = this.LevelThreeData[i]
                        }else{
                          this.optionThreeValue = undefined
                        }
                        this.LevelThreeCount.push({
                          'orderId': 'LevelTwo'+ (i+ 1).toString(),
                          'optionLabel': 'OPTION '+ (i+ 1).toString(),
                          'optionValue': this.optionThreeValue
                        })
                      }
                    }else {
                      for (let i = 1; i <= this.LevelNum; i++) {
                        this.LevelThreeCount.push({
                          'orderId': 'LevelTwo'+i.toString(),
                          'optionLabel': 'OPTION '+i.toString(),
                          'optionValue': undefined
                        })
                      }
                    }
                  }
                )
              }
            }
          )
        }
      }else {
        if(this.optionNumber.indexOf(parseInt(this.IVRTwoForm.value.number)) != -1){
          Swal.fire('Duplicate', 'Already Exists Option Number', 'warning')
        }else{
          if (this.IVRTwoForm.invalid) {
            return;
          }else{
            this.IVRTwoForm.value.ivrlevel = this.levelOneId
            this.IVRTwoForm.value.voicefile = this.voiceFileData
            this.ivrservice.AddLevelTwo(this.IVRTwoForm.value).subscribe(
              (res:any) => {
                if(res && res['id']){
                  this.optionNumber.push(res.number)
                  this.optionNumUpdate = res.number
                  this.notifyservice.sendNotification('Created', 'Level 2 option create successfully', 'is-success')
                  this.parentLevel.sublevels = this.parentLevel.sublevels + 1
                  this.ivrservice.UpdateLevelOne(this.levelOneId, this.parentLevel).subscribe(
                    (res:any) => {
                      console.log('increase option', res)
                    })
                  this.LevelTwoId = res['id']
                  this.LevelNum = res['sublevels']
                  this.ivrservice.getLevelThreeOptionsList(this.LevelTwoId).subscribe(
                    (data:any) => {
                      this.LevelThreeData = data
                      if(this.LevelThreeData) {
                        this.optionNum = []
                        for (let i = 0; i < this.LevelNum; i++) {
                          if (this.LevelThreeData.length >= i){
                            this.optionThreeValue = this.LevelThreeData[i]
                            this.optionNum.push(this.optionThreeValue.number)
                          }else{
                            this.optionThreeValue = undefined
                          }
                          this.LevelThreeCount.push({
                            'orderId': 'LevelThree'+ (i+ 1).toString(),
                            'optionLabel': 'Option '+ (i+ 1).toString(),
                            'optionValue': this.optionThreeValue
                          })
                        }
                      }else {
                        for (let i = 1; i <= this.LevelNum; i++) {
                          this.LevelThreeCount.push({
                            'orderId': 'LevelThree'+(i+ 1).toString(),
                            'optionLabel': 'Option '+(i+ 1).toString(),
                            'optionValue': undefined
                          })
                        }
                      }
                    }
                  )
                }
              }
            )
          }
        }
      }
    }
  }
  subDisplayBtn() {
    this.ivrservice.getLevelThreeOptionsList(this.LevelTwoId).subscribe(
      (data:any) => {
        this.LevelThreeData = data
        this.LevelThreeCount = []
        if(this.LevelThreeData.length > 0) {
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelThreeData.length >= i){
              this.optionThreeValue = this.LevelThreeData[i]
            }else{
              this.optionThreeValue = undefined
            }
            this.LevelThreeCount.push({
              'orderId': 'LevelThree'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionThreeValue
            })
          }
        }else {
          for (let i = 1; i <= this.LevelNum; i++) {
            this.LevelThreeCount.push({
              'orderId': 'LevelThree'+i.toString(),
              'optionLabel': 'OPTION '+i.toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
    this.levelTwoDisplay = !this.levelTwoDisplay
  }

  onAddOption() {
    this.levelTwoDisplay = true
    this.ivrservice.getLevelThreeOptionsList(this.LevelTwoId).subscribe(
      (data:any) => {
        this.LevelThreeData = data
        this.LevelNum = this.LevelNum + 1
        this.LevelThreeCount = []
        if(this.LevelThreeData) {
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelThreeData.length >= i){
              this.optionThreeValue = this.LevelThreeData[i]
            }else{
              this.optionThreeValue = undefined
            }
            this.LevelThreeCount.push({
              'orderId': 'LevelThree'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionThreeValue
            })
          }
        }else {
          for (let i = 1; i <= this.LevelNum; i++) {
            this.LevelThreeCount.push({
              'orderId': 'LevelThree'+i.toString(),
              'optionLabel': 'OPTION '+i.toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
  }

  onDeleteLevelTwo(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Level 2 Option Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.ivrservice.DeleteLevelTwoOptions(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'Level 2 option delete successfully', 'is-success')
            this.ivrservice.getLevelOneOptions(this.levelOneId).subscribe(
              (data:any) => {
                this.parentLevel = data
                this.parentLevel.sublevels = this.parentLevel.sublevels - 1
                this.ivrservice.UpdateLevelOne(this.levelOneId, this.parentLevel).subscribe(
                  (res:any) => {
                    console.log('decrease............', res)
                    this.parentEmitter2.emit(res);
                  }
                )
              }
            )
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Level 2 option deleting has been cancelled', 'is-danger')
      }
    });
  }

  parentEmitterData(response) {
    this.ivrservice.getLevelThreeOptionsList(response.id).subscribe(
      (data:any) => {
        this.LevelThreeData = data
        this.LevelNum = response.sublevels
        this.LevelThreeCount = []
        if(this.LevelThreeData) {
          this.optionNum = []
          for (let i = 0; i < this.LevelNum; i++) {
            if (this.LevelThreeData.length >= i){
              this.optionThreeValue = this.LevelThreeData[i]
              this.optionNum.push(this.optionThreeValue.number)
            }else{
              this.optionThreeValue = undefined
            }
            this.LevelThreeCount.push({
              'orderId': 'LevelThree'+ (i+ 1).toString(),
              'optionLabel': 'OPTION '+ (i+ 1).toString(),
              'optionValue': this.optionThreeValue
            })
          }
        }else {
          for (let i = 1; i <= this.LevelNum; i++) {
            this.LevelThreeCount.push({
              'orderId': 'LevelThree'+i.toString(),
              'optionLabel': 'OPTION '+i.toString(),
              'optionValue': undefined
            })
          }
        }
      }
    )
  }
}

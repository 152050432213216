import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {
  public menus:any
  constructor() { }

  ngOnInit() {

    this.menus = [
      {"link":"/sms/sms-list","name":"List sms","perm":"cadmin_s_smsList"}, 
      {"link":"/sms/add-sms", "name":"Add Sms","perm":"cadmin_s_addSms"}, 
      { "link": "/sms/templates/new", "name": "Templates", "perm": "cadmin_s_templates" },  
      { "link": "/sms/ivr-templates/new", "name": "IVR Templates", "perm": "cadmin_s_templates" },  
			{"link":"/sms/view-report", "name":"View Report","perm":"cadmin_s_viewReport"},
      {"link":"/sms/sms-count", "name":"SMS Count","perm":"cadmin_s_smsCount"},
		]
  }

}

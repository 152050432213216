import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-add-channelusage',
  templateUrl: './add-channelusage.component.html',
  styleUrls: ['./add-channelusage.component.css']
})
export class AddChannelusageComponent implements OnInit {
  permis = false;
  userData: any;
  permissions: any;
  channels: any;
  fullData: any;

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "add-channelUsage"){
        this.permis = true;
    }
  }
  this.usermanagementservice.lastChannel().subscribe(
    (res:any) => {
      this.fullData = res["data"]
      
      console.log("oiiiiiiiiiiiiiiiii")
  }
  )
  }

  OnSubmit(){

    console.log("dataaaaa",this.channels)
    var x = this.channels
    this.usermanagementservice.AddChannels(x).subscribe(
      (data:any) => {     
        Swal.fire('Created', 'Credit balance added', 'success')
        console.log("response is ",data) 
        this.usermanagementservice.lastChannel().subscribe(
          (res:any) => {
            this.fullData = res["data"]
            
            console.log("oiiiiiiiiiiiiiiiii")
        }
        ) 
                }
    )
  }
  
  

}

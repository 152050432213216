import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { SharedService } from './../../global';

@Injectable({
  providedIn: 'root'
})
export class InboundFeedbackService {

  constructor(
    private http:HttpClient,
    public sharedService:SharedService,

  ) { }
  API_URL = this.sharedService.getGlobalVar()

  InboundFeedbackUserCreate(body){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'InboundFeedbackUserCreate/',body,{headers:headers});
  }
  
  userExistTest(mobile){
    console.log("bodyyyyy",mobile)
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'userExistTest/'+mobile+'/',{headers:headers});
  }
  
  feedBackUserList(id){
    console.log("bodyyyyy",id)
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.get(this.API_URL+'feedBackUserList/'+id+'/',{headers:headers});
  }
  
  AddDidFeedbacks( name,userId, DID,labeloption,dlabel) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('name', name)
    formData.append('userId', userId)
    formData.append('DID', DID)
    formData.append('labeloption', labeloption)
    formData.append('dlabel',dlabel)
    return this.http.post(this.API_URL+'addQuestionsInboundFeedback/', formData, {headers: headers})
  }

  InboundFeedbackReport(userProfileId:number,didNum, fromDate, toDate) {
    console.log("usrrrrrrrrrrrrr",didNum["name"])
    const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');    
    return this.http.get(this.API_URL+'InboundFeedbackReport/'+userProfileId+'/?from='+fromDate +'&to='+toDate+'&didNum='+didNum["name"], {headers:headers});
  }
  QuestionList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'QuestionList/'+userProfileId+'/', {headers: headers})
  }


  userExistTestUsrMngmnt(userName){
    const headers = new HttpHeaders();
    const body = {"userName":userName}
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'userExistTestUsrMngmnt/',body,{headers:headers});
  }
}

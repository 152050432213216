import { Injectable, EventEmitter } from "@angular/core";
import { HttpHeaders,HttpParams } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "../global";
import { SharedService } from "../global";
import * as CryptoJS from "crypto-js";

import { AngularFireAuth } from '@angular/fire/auth'; // Import AngularFireAuth
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment'; // Replace with your environment file path

import { catchError, map, switchMap, take } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { Body } from "@angular/http/src/body";



@Injectable({
  providedIn: "root",
})
export class LoginService {
  loginevent = new EventEmitter();
  userDataEvent = new EventEmitter();
  tokenKey: String = "access_token";
  AuthtokenKey: String = "auth_token";
  refreshTokenKey: String = "refresh";
  userToken: string | null = null;



  constructor(private http: HttpClient, public sharedService: SharedService, private afAuth: AngularFireAuth) {
    firebase.auth().onAuthStateChanged((user) => {

      if (user) {

        user.getIdToken(true).then((token) => {

          this.userToken = token;

        });

      } else {

        this.userToken = null;

      }

    });
  }

  API_URL = this.sharedService.getGlobalVar();



  doLogin(body) {
    console.log("==============sdfsdf", this.sharedService.getGlobalVar());
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.sharedService.getGlobalVar() + "usermanagement/login/",
      body,
      { headers: headers }
    );
  }

  // Forgot Password //
  doForgotPassword(email:string,host:string,username:string) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      API_URL + "usermanagement/forgot-password/",
      { 'email': email, 'host':host, 'username':username },
      { headers: headers }
    );
  }

  getTokenUid(uid, token) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      API_URL + "usermanagement/reset/" + uid + "/" + token + "/",
      { headers: headers }
    );
  }

  changePassword(username, p1, p2,uid,token) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      API_URL + "usermanagement/change-password/",
      { 'username': username, 'password1': p1, 'password2': p2,'uid':uid,'token':token },
      { headers: headers }
    );
  }

  doLogout(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    this.clearToken();
    return this.http.post(
      this.sharedService.getGlobalVar() +  "usermanagement/logout/",
      body,
      { headers: headers }
    );
  }

  CheckServer(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(API_URL + "usermanagement/getServer/", body, {
      headers: headers,
    });
  }

  impersonateLogin(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.sharedService.getGlobalVar() + "usermanagement/impersonateLogin/",
      body,
      { headers: headers }
    );
  }

  setUserdata(data) {
    localStorage.setItem("userdata", JSON.stringify(data));
  }

  getStoredNotifications(): any[] {

    const storedNotifications = localStorage.getItem('notifications');

    return storedNotifications ? JSON.parse(storedNotifications) : [];

  }

  getUserdata(status) {
    var userdatas = JSON.parse(localStorage.getItem("userdata"));
    return userdatas;
  }

  getLoginStatus() {
    var loginstate = JSON.parse(localStorage.getItem("isLogged"));
    this.loginevent.emit(loginstate);
    return loginstate;
  }

  setLoginStatus(i) {
    i = JSON.stringify(i);
    localStorage.setItem("isLogged", i);
  }

  getCustomerList() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.sharedService.getGlobalVar() + "customerList/", {
      headers: headers,
    });
  }

  getCustomer(id: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.sharedService.getGlobalVar() + "customerDetails/?pk=" + id,
      { headers: headers }
    );
  }

  searchCustomerList(val: string) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.sharedService.getGlobalVar() + "customerList/?search=" + val,
      { headers: headers }
    );
  }

  setCustomer(data) {
    localStorage.setItem("customer", JSON.stringify(data));
  }

  getCallRecords(id: number, usertype: string, call_type: any, date: any) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype, call_type: call_type, date: date };
    headers.append("Content-Type", "application/json");

    return this.http.post(
      this.sharedService.getGlobalVar() + "dashboard_data/" + id + "/",
      body,
      { headers: headers }
    );
  }

  getCustomerData(id: number, usertype: string, date: any) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype, date: date };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.sharedService.getGlobalVar() + "customer_data/" + id + "/",
      body,
      {
        headers: headers,
      }
    );
  }

  getCallRecords2(id: number, usertype: string) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.sharedService.getGlobalVar() +
        "callRecordTodayDashboard/" +
        id +
        "/",
      body,
      { headers: headers }
    );
  }

  getUserDetails(id: number, usertype: string) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype };
    headers.append("Content-Type", "application/json");

    return this.http.post(
      this.sharedService.getGlobalVar() + "userDetailsDashboard/" + id + "/",
      body,
      { headers: headers }
    );
  }

  //  Get Renewal Details //
  getRenewalData(id: number, usertype: string) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype };
    headers.append("Content-Type", "application/json");

    return this.http.post(
      this.sharedService.getGlobalVar() + "renewalDetailsDashboard/" + id + "/",
      body,
      { headers: headers }
    );
  }

  getCallRecordsRightSidebar(id: number, usertype: string) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype };
    headers.append("Content-Type", "application/json");

    return this.http.post(
      this.sharedService.getGlobalVar() +
        "callRecordTodayRightSideBar/" +
        id +
        "/",
      body,
      { headers: headers }
    );
  }

  getUserData(id: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.sharedService.getGlobalVar() + "userprofileData/" + id + "/",
      { headers: headers }
    );
  }

  ///////////////// PAGINATION ////////////////
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  ///////////////////PAGINation end /////////////

  getUserLog(id: number, usertype: string) {
    const headers = new HttpHeaders();
    const body = { usertype: usertype };
    headers.append("Content-Type", "application/json");

    return this.http.post(
      this.sharedService.getGlobalVar() +
        "callRecordTodayRightSideBar/" +
        id +
        "/",
      body,
      { headers: headers }
    );
  }

  // get_auth_token(data) {
  //   const authString = `${data.username}:${data.password}`;
  //   const base64AuthHeader = btoa(authString);
  //   const headers = new HttpHeaders({
  //     Authorization: `Basic ${base64AuthHeader}`,
  //   });
  //   this.http
  //     .get(this.sharedService.getGlobalVar() + "api/get_auth_token/", {
  //       headers: headers,
  //     })
  //     .subscribe((res: any) => {
  //       if (res.status == "success") {
  //         const token = CryptoJS.AES.encrypt(
  //           res.token,
  //           "enig93maticA"
  //         ).toString();
  //         document.cookie = `${this.tokenKey}=${token};path=/`;
  //       } else {
  //         console.log("================== Error in getting user token");
  //       }
  //     });
  // }

  get_access_token(data) {
    // this.resetLogoutFlag();
    const authString = `${data.username}:${data.password}`;
    const base64AuthHeader = btoa(authString);
    // const headers = new HttpHeaders({
      // Authorization: `Basic ${base64AuthHeader}`,
      
    // });
    const headers = new HttpHeaders()

    headers.append("Content-Type", "application/json");
    const body = { "username": data["username"], "password": data["password"] };
    


    this.http
      .post(
        this.sharedService.getGlobalVar() + "api/token/", 
        body,
        { headers: headers }
      )
      .subscribe((res: any) => {
        if (res.refresh) {
          const refreshtoken = CryptoJS.AES.encrypt(
            res.refresh,
            "enig93maticA"
          ).toString();
          const tokenPayload = JSON.parse(atob(res.access.split('.')[1]));
          const expiresIn = tokenPayload.exp ? new Date(tokenPayload.exp * 1000) : new Date(Date.now() + 120 * 60 * 1000); 
          // document.cookie = `${this.refreshTokenKey}=${refreshtoken};path=/`;
          document.cookie = `${this.refreshTokenKey}=${refreshtoken};path=/;expires=${expiresIn.toUTCString()}`;
        } else {
          console.log("================== Error in getting user token");
        }
        if (res.access) {
          const token = CryptoJS.AES.encrypt(
            res.access,
            "enig93maticA"
          ).toString();
          const tokenPayload = JSON.parse(atob(res.access.split('.')[1]));
          const expiresIn = tokenPayload.exp ? new Date(tokenPayload.exp * 1000) : new Date(Date.now() + 120 * 60 * 1000); 
          document.cookie = `${this.tokenKey}=${token};path=/;expires=${expiresIn.toUTCString()}`;
          // document.cookie = `${this.tokenKey}=${token};path=/`;
        } 
      });
    
  }
  resetLogoutFlag() {
    sessionStorage.removeItem('showLogoutAlert');
    localStorage.removeItem('loggedOut');
    return;
  }
  // get_accesstoken_refresh(): string {
  get_accesstoken_refresh(): Observable<string | null> {
    
   

    console.log("--get_accesstoken_refresh---")
    const headers = new HttpHeaders()

    headers.append("Content-Type", "application/json");

    const refreshToken = this.getRefreshToken()
    
    const body = { "refresh": refreshToken };
    

    return this.http
      .post(this.sharedService.getGlobalVar() + "api/token/refresh/", body, { headers })
      .pipe(
        map((res: any) => {
          if (res.access) {
            const token = CryptoJS.AES.encrypt(res.access, "enig93maticA").toString();
            document.cookie = `${this.tokenKey}=${token};path=/;expires=${new Date(Date.now() + 120 * 60 * 1000).toUTCString()}`;
            return res.access; 
          } else {
            console.log("================== Error in getting user token");
            return null;
          }
        }),
        catchError((error) => {
          console.error("Error refreshing token", error);
          return of(null); 
        })
      );
  }


  getFirebaseToken(): Observable<string> {

    return this.afAuth.authState.pipe(

      take(1), // Take the first emitted value and complete

      switchMap(user => {

        if (user) {

          return from(user.getIdToken());

        } else {

          throw new Error('No user is currently logged in');

        }

      })

    );

  }

  sendFirebaseToken(token) {

    return this.http.post("api/get_auth_token/", { firebaseToken: token });

  }

  // Get token from cookie
  getToken(): string {
    const name = `${this.tokenKey}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (const cookie of cookieArray) {
      let c = cookie;
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const enc_token = c.substring(name.length, c.length);
        const token = CryptoJS.AES.decrypt(enc_token, "enig93maticA").toString(
          CryptoJS.enc.Utf8
        );
        return token;
      }
    }
    return "";
  }
  getRefreshToken(): string {
    const name = `${this.refreshTokenKey}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (const cookie of cookieArray) {
      let c = cookie;
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const enc_token = c.substring(name.length, c.length);
        const token = CryptoJS.AES.decrypt(enc_token, "enig93maticA").toString(
          CryptoJS.enc.Utf8
        );
        return token;
      }
    }
    return "";
  }



  getMessages() {



  }



  flushtoken(payload) {

    const deviceType = '3'; // or get the actual device type if available

    const headers = new HttpHeaders()

    headers.append("Content-Type", "application/json");



    return this.http.post(this.API_URL + 'pushNotifications/remove-firebase-token/', payload, { headers: headers });


  }

  getPushMessages(userid: string, device_type: string, group: string, email: string, phone: string, name: string) {

    const headers = new HttpHeaders()

    headers.append("Content-Type", "application/json");

    let params = new HttpParams()

      .set('userid', userid)

      .set('device_type', device_type)

      .set('group', group)

      .set('email', email)

      .set('phone', phone)

      .set('name', name);



    return this.http.get(this.API_URL + 'login-user-push-messages/', { headers: headers, params: params })

  }

  removeFirebaseToken(logoutData: any) {

    const headers = new HttpHeaders()

    headers.append("Content-Type", "application/json");

    return this.http.post(this.API_URL + 'remove-firebase-token/', logoutData, { headers: headers })

  }

  blackListJWTToken(refresh: any) {

    const headers = new HttpHeaders()

    headers.append("Content-Type", "application/json");

    return this.http.post(this.API_URL + 'api/logout/', refresh, { headers: headers })

  }

  updateMessage(messageId: number, readStatus) {

    const headers = new HttpHeaders();

    const body = {

      read_status: readStatus

    }

    return this.http.patch(this.API_URL + "pushmessages-readstatus-update/" + messageId + '/', body, { headers: headers })



  }

  updateAllMessages(ids: number[]) {

    const headers = new HttpHeaders();

    return this.http.post(this.API_URL + "clear-pushmessages-list/", { ids }, { headers: headers })

  }



  


  clearToken(): void {
  // Remove token from cookie
    document.cookie = `${this.tokenKey}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    document.cookie = `${this.AuthtokenKey}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    document.cookie = `${this.refreshTokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addproduct-toclient',
  templateUrl: './addproduct-toclient.component.html',
  styleUrls: ['./addproduct-toclient.component.css']
})
export class AddproductToclientComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

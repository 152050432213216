import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})

export class merittoagents{

    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ) { }
    
    API_URL = this.sharedService.getGlobalVar();
    headers = new HttpHeaders().set("Content-Type", "application/json")
    

    addMerittoagents(data: any) {
        return this.http.post(this.API_URL + "router/merittoagent/",data,{headers:this.headers})
    }

    getMerittoagentList() {
        return this.http.get(this.API_URL + 'router/merittoagent/',{headers:this.headers})
    }

    deletegagent(id:number) {
        return this.http.delete(this.API_URL + `router/merittoagent/${id}/`,{headers:this.headers})
    }
}
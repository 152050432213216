import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { BroadcastService } from "./voicebroadcast.service";
import swal from 'sweetalert2';
import { Validators,FormBuilder } from "@angular/forms";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LoginService} from "../login.service";
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';

import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';

@Component({
	selector: 'voicebroadcast',
	templateUrl: './voicebroadcast.component.html',
	styleUrls: ['./voicebroadcast.component.css']
})

export class VoicebroadcastComponent implements OnInit {
	public vdata:any;
	 VoicebroacastForm: FormGroup;
	 data1 = [
		{
		  id: 1,
		  name: 'Usa'
		},
		{
		  id: 2,
		  name: 'England'
		}
	 ];
     fileData: File = null;
	 id: number;
	 date1:string;
	 voiceFileLabel:string = 'Voice File';
	 voiceFileInitial:any;
	 voiceFileData:string = '';
	 UserProfileId:number = 0;

	DIDNO: number;
	didList: any;
	destination: string;
	destinationType: string;
	musicOnHold: string;
	//callerId: string;
	description: string;
	DIDInitial: any;
	call_id : any;
	defaultOption : any;
	broadcastID : any;

	 TimeConditionData: any;
	 Num:number = 0;
	 TimeCount:any = new Array();
	 TimeConditionValue:any;

	 voicelist:any;
	 musicOnHoldInitial:any;
	 public newarray:any =[{
		Name: '',
		Number: '',
		}];
	 
    constructor(private broadcastservice: BroadcastService,
	            private loginservice: LoginService,
                private http: HttpClient,
				private activeroute:ActivatedRoute,

				private inboundservice: InboundRouteService,

				private route:Router,
				private voicerecodrservice: VoiceRecordService)

               { }
    selectedFile:File=null;
	selectedNumbers:File=null;

   onFileChanged(event) {

       this.selectedFile= event.target.files[0]
    }
   
   onNumbersChanged(event) {

       this.selectedNumbers= event.target.files[0]
    }
	voiceFilePath(data) {
	
		this.voiceFileData = data
  }

  
		
   ngOnInit() {
		    this.VoicebroacastForm = new FormGroup({
			'listname': new FormControl('',),
			'vblist': new FormControl('', ),
			'voicefile': new FormControl('', ),
			'is_active': new FormControl('', ),
			'call_id': new FormControl('', ),
			'fromDate': new FormControl('', ),
	
			
		});

		var user  = this.loginservice.getUserdata(false); 
    	this.inboundservice.getInboundDIDList(user.id,"notQueue","all").subscribe(
        (data:any) =>{
            this.didList = data
        }
        )
        
	 this.id = +this.activeroute.snapshot.paramMap.get('id');
    if (this.id != 0) {
      this.broadcastservice.getVoiceBroadcast(this.id).subscribe(
        (data:any) => {
          console.log('return', data)
		  this.vdata=data;
		  this.musicOnHold = data.voicefileurl
		  console.log(this.UserProfileId)

		  this.voicerecodrservice.getVoiceRecordList(this.UserProfileId,0).subscribe(
				(data:any) =>{
				this.voicelist = data

				for (let voiceData of this.voicelist){
					if(voiceData.fileUrl == this.musicOnHold) {
					this.voiceFileInitial = voiceData.name
					}
				}
				}
			)

          this.VoicebroacastForm.setValue({
            listname: data.listname,
            call_id: data.caller_id,
			is_active:data.last_synced_status,
		    vblist: data.broadcast_list,
            voicefile: data.voicefile,
			fromDate:data.scheduled_date,
          });
        }
      )
    }
	}

 	onSubmit()
	   {
		
		var user  = this.loginservice.getUserdata(false); 
		this.VoicebroacastForm.value.voicefile=this.voiceFileData;
		var data = this.VoicebroacastForm.value;

		console.log("formdata",data);
		var date = moment(this.VoicebroacastForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
		let formData = new FormData(); 
        formData.append('listname', data.listname);
		formData.append('file',  this.voiceFileData);
		formData.append('numbers',this.selectedNumbers);
		formData.append('call_id',data.call_id);
		formData.append('id',user.id)
		formData.append('fromDate',data.fromDate)
		formData.append('voicefileurl',this.voiceFileData)
		this.broadcastservice.broadcastformSend(formData)
				.subscribe(data => {
					             console.log(data);
				                 },
				          error => console.log("error",error)
			              )
		this.broadcastservice.voicedata.subscribe(
			data => {
				if (data.status==1)
				{
					swal.fire("voice broadcast created")
					 this.route.navigateByUrl('/voicebroadcastlist')
				}
				else if (data.status==0)
				{
					swal.fire("Error Please retry")
				}
				else if (data.status==2)
				{
					swal.fire("Already exists same Listname")
				}
			}
		)
	}

          onUpdate() {		
				var data = this.VoicebroacastForm.value;
				console.log("formdatassssss",data);
				let formData = new FormData();
				formData.append('listname', data.listname);
				formData.append('file',  this.selectedFile);
				formData.append('numbers',"shareef");
				//formData.append('caller_id',data.caller_id);

				formData.append('caller_id',data.call_id);
				formData.append('voicefileurl',this.voiceFileData);
				console.log("formData",formData);

				this.broadcastservice.updateVoicebroadcast(this.id,formData)
						.subscribe(data => {
										console.log(data);
										},
								error => console.log("error",error)
								)
				swal.fire("voice broadcast updated")
				this.broadcastservice.voicedata.subscribe(
					data => {
						if (data.id)
						{
							swal.fire("voice broadcast updated")
						}
						console.log("come on ",data);
					}
				)
        }

		onAddSet() {
			this.broadcastservice.getTimeField(this.id).subscribe(

			  (data:any) => {
				
				console.log(data)
				this.TimeConditionData = data
				this.Num = this.Num + 1
				this.TimeCount = []
				if(this.TimeConditionData) {
				  for (let i = 0; i < this.Num; i++) {
					if (this.TimeConditionData.length >= i){
					  this.TimeConditionValue = this.TimeConditionData[i]
					}else{
					  this.TimeConditionValue = undefined
					}
					this.TimeCount.push({
					  'orderId': 'TimeCondition'+ (i+ 1).toString(),
					  'TimeConditionValue': this.TimeConditionValue
					})
				  }
				}else {
				  for (let i = 0; i < this.Num; i++) {
					this.TimeCount.push({
					  'orderId': 'LevelOne'+ (i+ 1).toString(),
					  'TimeConditionValue': undefined
					})
				  }
				}
			  }
			)
		  }

		  onFlush()
		  {
		   var user  = this.loginservice.getUserdata(false);   
		   var data = this.VoicebroacastForm.value;
		   console.log("formdata",data);
		   let formData = new FormData(); 

		   formData.append('listname', data.listname);
		   formData.append('file',  this.voiceFileData);
		   formData.append('numbers',this.selectedNumbers);
		   formData.append('call_id',data.call_id);
		   formData.append('id',user.id)
		   formData.append('voicefileurl',this.voiceFileData)
		   this.broadcastservice.voicebroadcastflush(formData)
				   .subscribe(data => {
									console.log(data);
									},
							 error => console.log("error",error)
							 )
		   this.broadcastservice.voicedata.subscribe(
			   data => {
				   if (data.status==1)
				   {
					   swal.fire("voice broadcast flushed")
						this.route.navigateByUrl('/voicebroadcastlist')
				   }
				   else if (data.status==0)
				   {
					   swal.fire("Error Please retry")
				   }
				   else if (data.status==2)
					{
						swal.fire("Already exists same Listname")
					}
			   }
		   )
	   }

	   sampleBroadcastSheet():void {
		this.broadcastservice.downloadsamplesheet(this.newarray, "VoiceBroadCast");
	   }

	}


	//  private setCurrentUser(data) {
    //     localStorage.setItem("user", JSON.stringify(data));
    // }
	// getCurrentUser() {
	// 	var user = JSON.parse(localStorage.getItem("user"));
	// 	return user;
	// }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(
    private http: HttpClient,
    public sharedService:SharedService,

  ) { }

  API_URL = this.sharedService.getGlobalVar()


  uploadfiles(form, id){
    let formData = new FormData();
    formData.append('file', form);
    formData.append('userProfileId', id);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(this.API_URL + 'uploadFile/', formData, { headers: headers })
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CallSummaryReportService } from './report.service';




@Component({
  selector: 'app-call-summary',
  templateUrl: './call-summary.component.html',
  styleUrls: ['./call-summary.component.css']
})
export class CallSummaryComponent implements OnInit {

  @Input() showModal: boolean = false;
  @Input() callId: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  reportData = [];
  userData:any = JSON.parse(localStorage.getItem("userdata"));
  render: boolean = false;


  constructor(
    private reportService: CallSummaryReportService
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes.showModal && changes.showModal.currentValue == true) {
      this.reportService.getCallSummaryReport(this.callId, this.userData.id).
        subscribe((res: any) => {
          if (res["status"] == "1") {
            this.reportData = res["data"];
            this.render = true;
          }
          else {
            this.reportData = [];
            this.render = true
          }

        })

    }
  }

  onCloseModal(): void {
    this.closeModal.emit();
    this.reportData = [];
    this.render = false;
  }

}

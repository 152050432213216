import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-management',
  templateUrl: './credit-management.component.html',
  styleUrls: ['./credit-management.component.css']
})
export class CreditManagementComponent implements OnInit {
  menus: { "link": string; "name": string; "perm": string}[];

  constructor() { }

  ngOnInit() {
    this.menus = [
      {"link":"/credit-management/credit-list", "name":"Credit list","perm":"cadmin_s_creditList"},
      {"link":"/credit-management/add-credit","name":"Add credit","perm":"cadmin_s_addCredit"},
      {"link":"/credit-management/add-incomingCredit","name":"Add Incoming Credit","perm":"cadmin_s_addIncomingCredit"},
      {"link":"/credit-management/add-channelUsage","name":"Add Channel Usage","perm":"cadmin_s_addchannelUsage"},
      {"link":"/credit-management/channelUsageHistory","name":"Channel Usage History","perm":"cadmin_s_channelUsageHistory"},
      {"link":"/credit-management/timeDuration","name":"Total Time/Pulse Duration","perm":"cadmin_t_timeDuration"}
		]
  }
  }
  

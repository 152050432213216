import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-ivr]',
  templateUrl: './ivr.component.html',
  styleUrls: ['./ivr.component.css']
})
export class IvrComponent implements OnInit {
  public menus:any

  constructor() { }

  ngOnInit() {
    this.menus = [
			{"link":"/ivr/ivrList", "name":"IVR"},
		]
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login.service';
import { DialerService } from 'src/app/dialer/dialer.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
@Component({
  selector: 'app-dialer-list',
  templateUrl: './dialer-list.component.html',
  styleUrls: ['./dialer-list.component.css']
})
export class DialerListComponent implements OnInit {
  dialerData:any = new Array();
  extensionFileToUpload: File;
  isAdmin:any;
  userData:any;
  UserProfileId:number;
  userType:any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private dialerService: DialerService,
    private notifyservice: NotificationService
  ) { }

  ngOnInit() {
    this.dialerData = []
    this.userType=localStorage.getItem('userType')

    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
      this.UserProfileId = this.userData.id
    
 
    this.dialerService.dialerList(this.UserProfileId, this.userType).subscribe(
        (res:any) => {
          console.log("data", res)
          this.dialerData = res
        }
    )
  }


     ProjectDelete(id)
   {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'This Project will be Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.dialerService.deleteProject(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'Project delete successfully', 'is-success')
            this.dialerService.dialerList(this.UserProfileId).subscribe(
               (res:any) => {
          console.log("data", res)
          this.dialerData = res
      
        }
    )
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Project deleting has been cancelled', 'is-danger')
      }
    })
  }


  EditProject(id){
		this.router.navigateByUrl('/projectDetails/'+id);
		
	}
  

}

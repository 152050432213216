import { Component, OnInit } from "@angular/core";
import { InboundRouteService } from "src/app/inbound-route/inbound-route.service";
import { UsermanagementService } from "../../usermanagement.service";
import { UsermanagementService as AgentUserManagementService } from "src/app/usermanagement/usermanagement.service";
import { LoginService } from "src/app/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ExtensionService } from "src/app/extension/extension.service";
import Swal from "sweetalert2";

import { FormsModule } from "@angular/forms";
import { log } from "console";

@Component({
  selector: "app-add-virtual-sip",
  templateUrl: "./add-virtual-sip.component.html",
  styleUrls: ["./add-virtual-sip.component.css"],
})
export class AddVIrtualSipComponent implements OnInit {
  userData: any;
  agentUsersList: any[] = [];
  DIDInitial: string = "";
  DIDNumber: number = 0;
  DIDNumberInitial: string = "";
  agentUserInitial: string = "";
  DIDNO: any;
  agentUser: string;
  callerDIDNumber: any;
  destination: string = "";
  id: number = 0;

  extensionId: number = 0;
  didList: any;

  keyword = "name";
  musicOnHold: string = "";
  invalidExtension: string = "";
  userInfo: number = 0;
  extensionFile: any;
  uploadView: boolean = true;
  isContactAdmin: boolean = false;
  extensionNumber: string = "";
  description: string = "";

  extensionFileToUpload: File;
  extensionData: {};
  uploadData: any;
  extensionMemberData: any;
  musicOnHoldLabel: string = "Music on hold";
  invalidExtensionLabel: string = "Invalid extension";
  musicOnHoldInitial: any;
  invalidExtensionInitial: any;
  voicelist: any = new Array();
  isAdmin: string = "false";
  UserProfileId: number = 0;
  customerInitial: any;
  customerList: any = new Array();
  isEdit: boolean = true;
  dialRecentAgent: string = "0";
  phoneNumber: string = "";
  optionSelected: string = "OUTGOING";

  permissions: any;
  permis: boolean;
  virtualUserData: {};
  Password: any;
  virtualUserId: any;
  allowedIPAddress: any;
  userName: any;
  contactcenter = false;
  passwordRegex: any;
  passwordValidator: boolean;
  sendResponseToexternalAPI: boolean;
  enable_callback: boolean;
  callback_mode: string = "0"

  constructor(
    private inboundRouteService: InboundRouteService,
    private usermanagementservice: UsermanagementService,
    private agentUserManagementService: AgentUserManagementService,
    private loginService: LoginService,
    private activeroute: ActivatedRoute,
    private extensionService: ExtensionService,
    private router: Router
  ) {}
  options = ["INCOMING", "OUTGOING", "INCOMING&OUTGOING"];
  ngOnInit() {
    this.isAdmin = localStorage.getItem("isAdmin");
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions;
    this.UserProfileId = this.userData.id;
    this.Password = this.usermanagementservice.generate_password(12, true, true, true, true, "@", true)

    for (let element of this.permissions) {
      if (element.codename == "cadmin_q_contactcentre") {
        this.isContactAdmin = true;
      }
    }

    this.id = +this.activeroute.snapshot.paramMap.get("id");

    this.inboundRouteService
      .fetchOneSipDetail(this.id)
      .subscribe((data: any) => {
        this.userName = data["data"][0].userName;
        this.DIDInitial = data["data"][0].outboundCallerID;
        this.Password = data["data"][0].userSecret;
        this.allowedIPAddress = data["data"][0].allowedIPAddress;
        this.contactcenter = data["data"][0].contactcenter;
        this.optionSelected = data["data"][0].call_options;
        this.phoneNumber = data["data"][0].phone_number;
        this.agentUserInitial = data["data"][0].agentUserName;
        this.sendResponseToexternalAPI = data["data"][0].sendResponseToexternalAPI;
        this.enable_callback = data["data"][0].enable_callback
        this.callback_mode = data["data"][0].callback_mode
      });

    this.getAgentUsers();
  }

  onOptionsSelected(event) {
    this.optionSelected = event; //option value will be sent as event
  }

  selectDID(item) {
    this.DIDNumber = item.id;
  }

  selectAgent(item){
    this.agentUser = item.id;
  }

  onClearDID() {
    this.DIDNO = 0;
  }

  onChangeSearchDID(val: string) {
    this.inboundRouteService
      .searchDIDbById(val, this.UserProfileId)
      .subscribe((data: any) => {
        this.didList = data;
      });
  }

  onFocusedDID(e) {
    this.inboundRouteService
      .getDIDList(this.UserProfileId)
      .subscribe((data: any) => {
        this.didList = data;
      });
  }

  validatePassword(): boolean {
    this.passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[^\s]).{8,}$/;
    return this.passwordRegex.test(this.Password);
  }

  onPasswordChange(){
    if(this.validatePassword() == true){
      this.passwordValidator = false
    }else{
      this.passwordValidator = true;
    }
  }

  onSubmit() {
    if (!this.contactcenter) {
      this.optionSelected = "";
    }

    if (!this.enable_callback){
      this.callback_mode = '0'
    }

    this.virtualUserData = {
      didNumber: this.DIDInitial,
      userName: this.userName,
      Password: this.Password,
      allowedIPAddress: this.allowedIPAddress,
      userprofileId: this.UserProfileId,
      id: this.id,
      contactcenter: this.contactcenter,
      calloption: this.optionSelected,
      phoneNumber: this.phoneNumber,
      agentUser: this.agentUser,
      sendResponseToexternalAPI: this.sendResponseToexternalAPI,
      enable_callback: this.enable_callback,
      callback_mode: this.callback_mode
    };

    if (this.validatePassword()) {
      if (this.id != 0) {
        this.extensionService
          .addVirtualSipData(this.virtualUserData)
          .subscribe((res: any) => {
            if (res["status"] == "1") {
              Swal.fire("Created", "VirtualSip Data Updated", "success");
              this.router.navigate(["virtualSip/virtualSipList"]);
            } 
            
            else if (res["status"] == "11") {
              Swal.fire(res["message"]);
            } 
            
            else if (res["status"] == "2"){
              Swal.fire("Error", "Please create an Inbound Route with this DID", "warning");
            }

            else {
              Swal.fire(
                "Oops..!",
                "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character with 7 letter",
                "warning"
              );
            }
          });
      } else {
        this.extensionService
          .addVirtualSipData(this.virtualUserData)
          .subscribe((res: any) => {

            if (res["status"] == "1") {
              Swal.fire("Created", "VirtualSip Data added", "success");
              this.router.navigate(["virtualSip/virtualSipList"]);
            } 
            
            else if (res["status"] == "11") {
              Swal.fire(res["message"]);
            } 
            
            else if (res["status"] == "2"){
              Swal.fire("Error", "Please create an Inbound Route with this DID", "warning");
            }

            else {
              Swal.fire(
                "Oops..!",
                "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character with 7 letter",
                "warning"
              );
            }
          });
      }
    }else{
      this.passwordValidator = true;
    }
  }

  getAgentUsers(){
    this.agentUserManagementService.getAgentUsers(this.UserProfileId).subscribe((res:any)=>{
      if (res.status === "1"){
        this.agentUsersList = res.data
      }
      
    })
  }

  onPasswordRefresh() {
    this.Password = this.usermanagementservice.generate_password(15, true, true, true, true, '@', true)    
  }


}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';




@Component({
  selector: 'app-filter-details',
  templateUrl: './filter-details.component.html',
  styleUrls: ['./filter-details.component.css']
})
export class FilterDetailsComponent implements OnInit {

  @Input() showFilterDetailsModal: boolean = false;
  @Input() showModal: boolean = false;
  @Input() FilterData: any;
  @Input() FilterName: string = '';
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  // userData:any = JSON.parse(localStorage.getItem("userdata"));
  render: boolean = false;



  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    console.log("this:::>>>>>", this)
    this.render = true;
  }

  onCloseModal(): void {
    this.closeModal.emit();
    // this.FilterData = [];
    this.render = false;
  }

}

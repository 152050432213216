import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { SharedService } from 'src/global'


@Injectable({
    providedIn: 'root'
})

export class CallTransferService {
    constructor(
        private http:HttpClient,
        public sharedService:SharedService
    ) { }

    API_URL = this.sharedService.getGlobalVar()

    createCallTransferTemplate(callTransferForm: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.post(this.API_URL+'router/calltransfer/',callTransferForm, {headers:headers})
    }

    getCallTransferTemplate(id:number){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.get(this.API_URL+'router/calltransfer/?user_id=' + id, {headers:headers})
    }

    deleteCallTransferTemplate(template_id:number){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.request('delete',this.API_URL+'router/calltransfer/' + template_id + '/', {headers:headers})
    }

    updateCallTransferTemplate(callTransferForm: any, template_id:any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.put(this.API_URL+'router/calltransfer/' + template_id + '/',callTransferForm, {headers:headers})
    }

    deleteShortcode(shortcodeIdToDelete: any){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        return this.http.request('delete',this.API_URL+'calltransfer/delete_shortcode/' + shortcodeIdToDelete + '/', {headers:headers})
    }
}
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationEmmiter: EventEmitter<any> = new EventEmitter();
  notifyData:any;
  constructor() { }

  sendNotification(title:string, message:string, types:string) {
      this.notifyData = {
        'title': title,
        'message': message,
        'type': types
      }
      this.notificationEmmiter.emit(this.notifyData);
  }
}

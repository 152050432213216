import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';
import { UsermanagementService } from "../../usermanagement.service";
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-channel-usage-history',
  templateUrl: './channel-usage-history.component.html',
  styleUrls: ['./channel-usage-history.component.css'],
  providers: [DatePipe]
})
export class ChannelUsageHistoryComponent implements OnInit {
  userData: any;
  permissions: any;
  permis = false;
  fullData: any;
  current_page: number=1;
  total_page:any;
  last: number;
  goPage: number;
  fromdate: string='';
  todate: string='';
  searchText: string='';


  constructor(
    private loginservice: LoginService,
    private usermanagementservice:UsermanagementService,
    private datePipe: DatePipe,

  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.FetchChannelUsageData(1)
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_s_channelUsageHistory"){
        this.permis = true;
      }
    }
  }

FetchChannelUsageData(pageNo){

  var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
  var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

  this.usermanagementservice.fetchData(this.userData.id, from, to,this.searchText,pageNo).subscribe(
    (res:any) => {
      console.log("=======",res,"======")
      this.fullData = res["results"]
      this.total_page =  res['total_pages']
      this.current_page = res['current_page']
      this.last = res['total_pages']
  })

}

searchBox(){
  this.FetchChannelUsageData(1)
}

fetchNumber(i) {
  if(i == 'prev'){
    if (this.current_page > 1){
      this.FetchChannelUsageData(this.current_page-1);
    }
  }

  else if (i == 'next'){
    if (this.current_page < this.last){
      this.FetchChannelUsageData(this.current_page+1);
    }
   
  }

  else if(i == 'first'){
    this.FetchChannelUsageData(1);
  }

  else if(i == 'last'){
    this.FetchChannelUsageData(this.last);
  }

  else{

    if (parseInt(i)=== parseInt(i, 10) && i > 0 && i <=  this.last){
      this.FetchChannelUsageData(i);
    }
    else{
      Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
    }

  }

}


}

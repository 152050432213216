import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.css']
})
export class VoicemailComponent implements OnInit {
  public menus:any

  constructor() { }

  ngOnInit() {

  
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
	selector: '[app-reports]',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
	public menus:any

	constructor() { }

	ngOnInit() {
		this.menus = [
			{"link":"/reports/report", "name":"Inbound Report","perm":"cadmin_o_inboundreport"},
			{"link":"/reports/dialer_report","name":"Dialer Report","perm":"cadmin_q_dialerReport"},
			{"link":"/reports/missedCallReport","name":"Missed Call Report","perm":"cadmin_p_missedcallreport"},
			{"link":"/reports/VBReport","name":"VB Report","perm":"cadmin_q_VBReport"},
			{"link":"/reports/VirtualsipReport","name":"Virtualsip Report","prem":"cadmin_q_virtualSip"},
			{"link":"/reports/CallbackReport","name":"Callback Report","prem":"cadmin_c_callbackreport"}
		]
	}

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../login.service';

@Component({
  selector: 'app-user-group',
  templateUrl: './user_group_autocomplete.component.html',
  styleUrls: ['./user_group_autocomplete.component.css']
})
export class SubuserGroupComponent implements OnInit {
  customerList:any = new Array();
  keyword:string = "name";  
  @Input() customerLabel: string
  @Input() customerValue: string;
  @Input() customerValueInitial:string;
  @Output() customerEmitter:any = new EventEmitter();
  userData:any;

  constructor(
    private loginservice: LoginService
  ) { }

  ngOnInit() {
    this.loginservice.getCustomerList().subscribe(
			(res:any) => {
        this.customerList = res
        console.log('initial customer list', this.customerList)        
			}
		)
  }

  selectUser(item) {
		this.customerEmitter.emit(item.id)
	}

  onFocusedUser(){
		this.loginservice.getCustomerList().subscribe(
			(res:any) => {
				this.customerList = res
			}
		)
	}

	onChangeSearchUser(val:string) {
		this.loginservice.searchCustomerList(val).subscribe(
			(res:any) => {
				this.customerList = res
			}
		)
  }
  
  onClearUser() {
		this.customerEmitter.emit(0)
	}


}

import { Component, OnInit } from '@angular/core';
import { LoginService } from "../../login.service"
import Swal from 'sweetalert2'
import { NotificationService } from 'src/app/notification/notification.service'
import { CallTransferService } from '../call-transfer.service';



@Component({
  selector: 'app-call-transfer-templates-list',
  templateUrl: './call-transfer-templates-list.component.html',
  styleUrls: ['./call-transfer-templates-list.component.css']
})
export class CallTransferTemplatesListComponent implements OnInit {
  templateList: any = new Array()
  userData: any

  constructor(
    private loginservice: LoginService,
    private calltransferservice: CallTransferService,
    private notifyservice: NotificationService,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true)
    this.loadTemplateList()
  }

  loadTemplateList = () => this.calltransferservice.getCallTransferTemplate(this.userData.id).subscribe((res: any) => this.templateList = res)

  onDelete(template_id: number) {
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this Call transfer template ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.calltransferservice.deleteCallTransferTemplate(template_id).subscribe(
          () => {
            this.loadTemplateList();
            this.notifyservice.sendNotification('Deleted!', 'Call transfer template has been deleted.', 'is-success')
          },
          (error: any) => {
            console.error('Error deleting voicemail template:', error)
            this.notifyservice.sendNotification('Error', 'An error occurred while deleting the template. Please try again.', 'is-info')
          }
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.notifyservice.sendNotification('Cancelled', 'Call transfer template deletion was cancelled.', 'is-danger')
      }
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login.service';
import { InboundFeedbackService } from '../inbound-feedback.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css']
})
export class QuestionListComponent implements OnInit {
  dialerData:any = new Array();
  extensionFileToUpload: File;
  isAdmin:any;
  userData:any;
  UserProfileId:number;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private inboundFeedbackService: InboundFeedbackService,
     private notifyservice: NotificationService
  ) { } 

  ngOnInit() {
    console.log("oiiiiiiiiiiii")
    this.dialerData = []
    this.isAdmin = localStorage.getItem('isAdmin')
    console.log(this.isAdmin)
    this.userData = this.loginService.getUserdata(false);
    console.log(this.userData.id)
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
 


    this.inboundFeedbackService.QuestionList(this.UserProfileId).subscribe(
        (res:any) => {
          console.log("data", res['data'])
          this.dialerData = res['data']
      
        }
    )
  }

}

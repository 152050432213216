import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  private showResetPasswordSubject = new BehaviorSubject<boolean>(true);

  getShowResetPassword() {
    return this.showResetPasswordSubject.asObservable();
  }

  setShowResetPassword(value: boolean) {
    this.showResetPasswordSubject.next(value);
  }
}

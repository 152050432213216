import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { DIDMgmtService } from 'src/app/didmgmt/didmgmt.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { UsermanagementService } from '../usermanagement/usermanagement.service';

declare var $: any;

@Component({
  selector: 'app-didmgmt',
  templateUrl: './didmgmt.component.html',
  styleUrls: ['./didmgmt.component.css']
})
export class DidmgmtComponent implements OnInit, AfterViewInit {
  userData: any;
  datas: any;
  DIDNum : any;
  user: any;
  TempId: number;
  id:number | null = 0;
  name:string;
  companies = 0;
  companyList : any;
  channelId : any;
	ChannelIdArray = new Array();
  DIDPopUp: boolean = false
  DIDList: [] = []
  selectedDID: any
  old_DID_id: any
  autoCallEnabled: boolean = false;
  dataTable: any;

  constructor(
    private loginservice: LoginService,
    private didMgmtService: DIDMgmtService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private usermanagementservice:UsermanagementService,
  ) { }

  ngOnInit() { 

    this.usermanagementservice.getChannelIds().subscribe(
			(data:any) =>{
        this.ChannelIdArray = data
			}
    )

    this.userData = this.loginservice.getUserdata(true);
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    if (this.id != 0) {
      this.usermanagementservice.getDid(this.id).subscribe(
        (data: any) => {
          this.DIDNum = data[0].name
          this.channelId = data[0].channel_id__channel_number
          this.companyList = data[0].user__company__name
          this.autoCallEnabled = data[0].autocall_enabled
        });
    }
    
    this.usermanagementservice.CompanyList().subscribe(
      (data:any) =>{
        this.companies = data
      }
    )
   
  }

  ngAfterViewInit(): void {
    this.initializeDatatable();
    this.updateDataTable();
  }


  initializeDatatable() {
    this.dataTable = $('#didTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'name', title: 'DID Number' },
        { data: 'user__company__name', title: 'Company Name' },
        { data: 'autocallEnabled', title: 'Autocall Enabled' },
        { data: 'action', title: 'Action' },
      ]
    });
  }


  updateDataTable() {
    this.didMgmtService.DIDCompanyList().subscribe((data: any) => {
      this.datas = data
      this.datas = data.map(item => ({
        ...item,
        autocallEnabled: `<input type="checkbox" ${item.autocall_enabled? "checked" : ""} disabled>`,
        action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
      }));

      this.dataTable.clear();

      this.dataTable.rows.add(this.datas);
      this.dataTable.draw();
    })


    $('#didTable tbody').on('click', '#btn-delete', (event: any) => {

      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.OndeleteDID(row.id);
      }
    });

    $('#didTable tbody').on('click', '#btn-edit', (event: any) => {

      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.OnEditDID(row.id);
      }
    });


  }




  AddDid = function() {
    this.submitted = true;
    this.user = this.loginservice.getUserdata(true);
    this.usermanagementservice.getCompanyUser(this.companyList).subscribe(
      (data:any) => {  
        var userId = data.data;
        this.UserData = {
          'name': this.DIDNum,
          'channelId': this.channelId,
          'channel_id':parseInt(this.channelId),
          'user': userId, 
          'autocall_enabled': this.autoCallEnabled
        }
        for(var val of this.datas){
          if(val.name.includes(this.DIDNum)) {
          Swal.fire('Error','This DID Already Exists')
        }}

        this.didMgmtService.DidManagement(this.UserData).subscribe(
          (res:any) => { 
            Swal.fire('Created', 'DID Successfully Created', 'success')
            this.updateDataTable();
            this.didMgmtService.DIDCompanyList().subscribe(
              (data:any) => {
                this.datas = data;
                this.DIDNum = " ";
                this.channelId = " ";
                this.userId = " ";
                this.companyList = " ";
              }
            ) 
          }
        )
      }
    )
  }

  EditDid = function(id) {
    this.submitted = true;
    this.user = this.loginservice.getUserdata(true);
    this.UserData = { 
      'name':this.DIDNum,
      'user': this.user.id,
      'autocall_enabled': this.autoCallEnabled,
      'channelId':this.channelId
    }
    let dataaaaa = this.datas.filter(item => item.id == this.user.id);
    
    // for(var val of this.datas){
    //   if(val.name.includes(this.DIDNum)) {
    //   Swal.fire('Error','This DID Already Exists')
    // }}
    this.didMgmtService.EditDid(id,this.UserData).subscribe(
      (res:any) => {      
        Swal.fire('Updated', 'DID Successfully Updated', 'success')
        this.updateDataTable();
        this.didMgmtService.DIDCompanyList().subscribe(
          (data:any) => {
            this.datas = data;
          }
        ) 
      }
    )
  }

  OndeleteDID(id) {
    this.old_DID_id = id
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure to delete this DID?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {

        this.didMgmtService.VsipCount(id).subscribe(
        (res:any) => {
          if (res.status === '2') {
            Swal.fire({
              title: 'Warning',
              text: res.virtual_sip_count + " virtual sip account uses this DID. Do you want to replace this DID or delete without replacing?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Replace this DID',
              cancelButtonText: 'Delete Anyway',
              cancelButtonColor: 'red'
            }).then(
              (delResult) => {
                if (delResult.value) {

                  this.didMgmtService.DidList(res.user_id).subscribe(
                    (res:any) => {
                      this.DIDList = res
                      const numberOfDIDs: number = this.DIDList.length;
                      if (numberOfDIDs === 1) {
                        Swal.fire({
                          title: 'Warning',
                          text: 'This account has only 1 DID, do you still want to delete this DID?',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Yes',
                          cancelButtonText: 'No'
                        }).then((result) => {
                          if (result.value) {

                            const body = {
                              'old_did_id': this.old_DID_id,
                            }
                            this.didMgmtService.ReplaceVsipDID(body).subscribe(
                              (res:any) => {
                                if (res.status === '1') {
                                  this.deletingDID(this.old_DID_id)
                                }
                              }
                            )
                          }
                        })
                      } 
                      else {
                        this.DIDPopUp = true
                      }
                    }
                  )
                }
                else{
                  const body = {
                    'old_did_id': this.old_DID_id,
                  }
                  this.didMgmtService.ReplaceVsipDID(body).subscribe(
                    (res:any) => {
                      if (res.status === '1') {
                        this.deletingDID(this.old_DID_id)
                      }
                    }
                  )
                }
              }
            )
          }
          else if (res.status === '3') {
            Swal.fire({
              title: 'Warning',
              text: 'There are routes connected with this DID. Please delete these routes manually before proceeding.',
              type: 'warning',
              confirmButtonText: 'Okay'
            });
          } 
          else {
            this.deletingDID(this.old_DID_id)
          }
        })
      }
    })
  }


  OnEditDID(id){
    this.usermanagementservice.getDid(id).subscribe(
      (data:any) => {
        this.DIDNum = data[0].name
        this.channelId = data[0].channel_id__channel_number
        this.companyList = data[0].user__company__name
        this.autoCallEnabled = data[0].autocall_enabled
      });
    this.router.navigateByUrl('/edit-did/'+id);	
  }

  closeModal(){
    this.DIDPopUp = false
  }

  replaceDID(){
    const body = {
      'old_did_id': this.old_DID_id,
      'new_did': this.selectedDID
    }
    this.didMgmtService.ReplaceVsipDID(body).subscribe(
      (res:any) => {
        if (res.status === '1') {
          this.DIDPopUp = false
          this.deletingDID(this.old_DID_id)
        }
      }
    )
  }

  deletingDID(did: any){
    this.didMgmtService.DidProcess(did).subscribe(
      (res:any) => {
        Swal.fire('Deleted', 'DID Successfully Deleted', 'success')
        this.didMgmtService.DIDCompanyList().subscribe(
          (data:any) => {
            this.datas = data;
          }
        )       
      }
    )
  }


}